import React from 'react';
import { Icon } from 'react-native-elements';
import { fs } from '../shared/Dimensions';
import { toSize } from '../utils/Utils';
import { fontPixel, pixelSizeVertical } from '../utils/normalizeSize';
import { Colors } from './Colors';
import { DIM } from './Dims';
import { FONT } from './font';

const defaultFont = {
  fontWeight: 'normal',
  fontFamily: 'fontRegular',
  color: Colors.defaultColor,
  fontSize: 14,
};

const headerStyle = {
  backgroundColor: Colors.white,
  borderBottomWidth: 0,
  borderBottomColor: '#96969633',
  fontSize: fs(18),
};
const Theme = {
  Avatar: {
    avatarStyle: {
      borderRadius: 8,
    },
  },
  Colors: {
    ...Colors,
    platform: {
      ios: {},
      android: {},
    },
  },
  Divider: {
    marginVertical: 8,
    borderColor: '#adb9c9',
  },
  Button: {
    buttonStyle: [
      {
        height: DIM.buttonHeight,
        borderRadius: 6,
        backgroundColor: Colors.primary,
        paddingHorizontal: 20,
      },
    ],
    titleStyle: [{ fontFamily: FONT.w500, fontSize: fs(18) }],
    disabledStyle: [
      {
        backgroundColor: Colors.primary,
        opacity: 0.6,
      },
    ],
  },
  Card: {
    containerStyle: [
      {
        marginHorizontal: 0,
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 15,
        elevation: 0,
        shadowOpacity: 0,
        borderWidth: 0,
        backgroundColor: Colors.cardBgColor,
      },
    ],
  },
  CheckBox: {
    textStyle: {
      ...defaultFont,
    },
    containerStyle: {
      borderWidth: 0,
      backgroundColor: 'transparent',
    },
  },
  Header: {
    backgroundColor: headerStyle.backgroundColor,
    containerStyle: {
      height: DIM.headerHeight,
      borderBottomWidth: 0,
      paddingHorizontal: pixelSizeVertical(12),
      justifyContent: 'center',
      alignItems: 'center',
    },
    leftContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightContainerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    centerContainerStyle: {
      fontFamily: 'fontHeavy',
      justifyContent: 'center',
    },
  },
  Input: {
    inputContainerStyle: [
      {
        borderColor: 'transparent',
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: Colors.inputBackgroundColor,
        minHeight: DIM.inputHeight,
      },
    ],
    contentContainerStyle: {
      marginHorizontal: 0,
      // outlineWidth: 0,
    },
    placeholderStyle: {
      color: Colors.inputPlaceholderColor,
      fontSize: fontPixel(9),
    },
    inputStyle: [
      {
        ...defaultFont,
        fontSize: 16,
        fontFamily: 'fontMedium',
        paddingHorizontal: 10,
        // outlineWidth: 0,
      },
    ],
    labelStyle: [
      {
        ...defaultFont,
        fontFamily: 'fontSemibold',
        fontSize: 16,
        color: Colors.inputLabelColor,
        lineHeight: 20,
        marginBottom: 8,
      },
    ],
    errorStyle: [
      {
        ...defaultFont,
        color: Colors.danger,
        fontSize: 12,
        lineHeight: 16,
      },
    ],
    leftIcon: [
      {
        padding: 10,
      },
    ],
    rightIconContainerStyle: {
      paddingRight: 10,
      // opacity: 0.5,
    },
    containerStyle: [
      {
        paddingHorizontal: 0,
        marginVertical: 5,
      },
    ],
  },
  ListItem: {
    containerStyle: {
      backgroundColor: Colors.cardBgColor,
    },
  },
  ListItemTitle: {
    style: {
      fontSize: toSize(9),
      fontFamily: 'fontMedium',
      marginBottom: 3,
    },
  },
  ListItemSubtitle: {
    style: {
      fontSize: 14,
      color: '#95a2b5',
      fontFamily: 'fontMedium',
      marginTop: 2,
    },
  },

  Icon: {
    color: Colors.defaultColor,
  },
  SearchBar: {
    inputContainerStyle: {
      color: Colors.secondary,
      backgroundColor: Colors.white,
      height: DIM.inputHeight,
      // outlineWidth: 0,
    },
    inputStyle: {
      fontSize: DIM.defaultFontSize,
    },
    containerStyle: {
      backgroundColor: '#eff1f2',
      border: '#eff1f2',
      padding: 16,
    },
  },
  Text: {
    style: [
      {
        ...defaultFont,
      },
    ],
    h1Style: [
      {
        fontWeight: 'normal',
        fontSize: fontPixel(15),
        fontFamily: 'fontHeavy',
      },
    ],
    h2Style: [
      {
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: 20,
        fontFamily: 'fontHeavy',
      },
    ],
    h3Style: [
      {
        fontWeight: 'normal',
        fontSize: toSize(9),
        lineHeight: 18,
        fontFamily: 'fontMedium',
      },
    ],
    h4Style: [
      {
        ...defaultFont,
        fontSize: 14,
        fontFamily: 'fontSemibold',
        lineHeight: 16,
      },
    ],
  },
  Tab: {},
  TabItem: {
    buttonStyle: {
      backgroundColor: Colors.white,
      borderRadius: toSize(9),
    },

    titleStyle: {
      fontSize: toSize(8),
      color: Colors.defaultColor,
      fontFamily: 'fontSemibold',
      textTransform: 'capitalize',
    },
  },
  customClasses: {
    fontFamily: {
      regular: {
        fontFamily: 'fontRegular',
      },
      bold: {
        fontFamily: 'fontBold',
      },
      medium: {
        fontFamily: 'fontMedium',
      },
      semibold: {
        fontFamily: 'fontSemibold',
      },
      heavy: {
        fontFamily: 'fontHeavy',
      },
      boldItalic: {
        fontFamily: 'fontBoldItalic',
      },
      mediumItalic: {
        fontFamily: 'fontMediumItalic',
      },
      light: {
        fontFamily: 'fontLight',
      },
      lightItalic: {
        fontFamily: 'fontLightItalic',
      },
      regularItalic: {
        fontFamily: 'fontItalic',
      },
      thin: {
        fontFamily: 'fontThin',
      },
    },
    text: {
      light: {
        opacity: 0.7,
      },
    },
    page: {
      flex: 1,
      padding: 25,
    },
    pageCenter: {
      flex: 1,
      padding: 25,
      alignItems: 'center',
    },
    screenOptions: {
      // navigator options
      headerTintColor: Colors.black,
      animationEnabled: true,
      headerStyle: {
        height: DIM.headerHeight,
        backgroundColor: Colors.white,
        borderBottomWidth: 1,
        borderBottomColor: Colors.screenBgColor,
        elevation: 5,
      },
      headerBackImage: () => (
        <Icon
          containerStyle={[{ paddingLeft: 5 }]}
          iconStyle={[{ color: Colors.black }]}
          type="material"
          size={30}
          name="keyboard-backspace"
        />
      ),
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontWeight: 'normal',
        fontFamily: 'fontBold',
        letterSpacing: 0.9,
        fontSize: headerStyle.fontSize,
      },
    },
    // options for consumer app
    webScreenOptions: {
      headerTintColor: Colors.black,
      animationEnabled: true,
      headerStyle: {
        height: DIM.headerHeight,
        backgroundColor: Colors.clear,
        borderBottomWidth: 1,
        borderBottomColor: Colors.screenBgColor,
        elevation: 5,
      },
      headerBackImage: () => (
        <Icon
          containerStyle={[{ paddingLeft: 8 }]}
          iconStyle={[{ color: Colors.black }]}
          type="material"
          size={30}
          name="keyboard-backspace"
        />
      ),
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontFamily: FONT.w400,
        fontSize: 14,
        color: '#787796',
        lineHeight: 21,
      },
    },
  },
};

export default Theme;
