import { isEmpty } from 'lodash';
import React from 'react';
import {
  Dimensions,
  FlatList,
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import WelcomeText1 from '../../assets/svg/WelcomeText1';
import WelcomeText2 from '../../assets/svg/WelcomeText2';
import WelcomeText3 from '../../assets/svg/WelcomeText3';
import { CROUTES } from '../../constants/Routes';
import CanShow from '../../shared/components/CanShow';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import GlobalStyles from '../../theme/GlobalStyles';
import { FONT } from '../../theme/font';
import ConsumerLogin from '../ConsumerLogin/ConsumerLogin';
import AboutProgram from '../Locations/AboutProgram';
import useConsumer from '../api/consumer.hook';
import ConsumerHeader from '../components/ConsumerHeader';
import ConsumersCredits from '../components/ConsumersCredits';
import CreditItem from '../components/CreditItem';
import RedeemItem from '../components/RedeemItem';
import SelectFuel from '../components/SelectFuel';
import { fontPixel } from '../../utils/normalizeSize';
// import HowItWorksIOCL from '../../assets/svg/HowItWorksIOCL';

const CREDIT_BG = require('../../assets/svg/Consumer/credit_bg_img.svg');
const LOGIN_BG = require('../../assets/svg/Consumer/login-bg.svg');

const { height, width } = Dimensions.get('window');

const ConsumerHome = ({ navigation, route }) => {
  const { userDetails, token } = useConsumer();

  const { balancePoints, creditsEarned, redemptions } = userDetails;
  const isUserLoggedIn = !!token;

  const onPressCreditItem = () => {};

  const onPressRedeemItem = (item) => {
    navigation.navigate(CROUTES.SHOW_RECEIPT, { billDetails: item, isViewReceipt: true });
  };

  const handleLoginSuccess = (params) => {
    if (route?.params?.toscreen) {
      navigation.navigate(CROUTES.SCAN_TO_PAY, { id: route?.params?.id });
    }
  };

  return (
    <View style={{ width: '100%' }}>
      <ConsumerHeader />
      <ScrollView
        style={{
          maxHeight: height,
        }}>
        <View>
          <View style={[{ padding: DIM.screenPadding }]}>
            <View style={{ alignItems: 'center', marginTop: 30 }}>
              <WelcomeText1 />
              <Spacing size={20} />
              <WelcomeText2 />
              <Spacing size={8} />
              <WelcomeText3 />
              <Spacing size={12} />
              <CanShow show={isUserLoggedIn}>
                <View style={{ width: '90%' }}>
                  <SelectFuel />
                </View>
              </CanShow>
            </View>
          </View>
          <View
            style={{
              width,
              backgroundColor: Colors.white,
            }}>
            <Image source={CREDIT_BG} style={{ height: width / 1.8, width }} />
            <CanShow
              show={isUserLoggedIn}
              fallbackComponent={
                <View
                  style={{
                    width: '100%',
                    padding: 0,
                    margin: 0,
                  }}>
                  <ImageBackground
                    source={LOGIN_BG}
                    resizeMode="cover"
                    imageStyle={{ height: 120, width }}
                    style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <ConsumerLogin onSuccess={handleLoginSuccess} />
                  </ImageBackground>
                </View>
              }>
              <View
                style={{
                  width: '100%',
                  paddingHorizontal: 20,
                  paddingBottom: 20,
                  background: 'linear-gradient(101.92deg, #FFF5D6 41.28%, #E6F7E9 99.71%)',
                }}>
                <ConsumersCredits data={balancePoints} />
              </View>
            </CanShow>
            <View
              style={{
                width: '100%',
                padding: 28,

                background: 'linear-gradient(101.92deg, #FFF5D6 41.28%, #E6F7E9 99.71%)',
              }}>
              <MyText
                font={FONT.w600}
                style={{ color: '#787796', letterSpacing: 0.7, fontSize: fontPixel(10) }}>
                You can earn Fuel credits by depositing waste at Indian Oil Petrol Pump{' '}
              </MyText>
              <View style={{ alignItems: 'flex-start', paddingTop: 10, flex: 0 }}>
                <TouchableOpacity onPress={() => navigation.navigate(CROUTES.REDEMPTION_VALUES)}>
                  <MyText size={15} color="#049869" underline>
                    Know more about Waste’s Fuel Value
                  </MyText>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <CanShow
            show={isUserLoggedIn && (!isEmpty(creditsEarned) || !isEmpty(redemptions))}
            fallbackComponent={
              // How It Works & FAQ
              <View style={{ paddingTop: DIM.screenPadding }}>
                <AboutProgram />
              </View>
            }>
            <View>
              {/* Earned Credits */}
              <CanShow show={!isEmpty(creditsEarned)}>
                <View style={[{ padding: DIM.screenPadding }]}>
                  <View style={[GlobalStyles.flexRow, { flex: 1, marginBottom: 14 }]}>
                    <View style={{ alignItems: 'flex-start', flex: 0.5 }}>
                      <MyText font={FONT.w500}>Earned Credits</MyText>
                    </View>
                    <View style={{ alignItems: 'flex-end', flex: 0.5 }}>
                      <TouchableOpacity onPress={() => navigation.navigate(CROUTES.CREDIT_HISTORY)}>
                        <MyText size={12} color="#049869" underline>
                          View all
                        </MyText>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <FlatList
                    data={creditsEarned}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
                    renderItem={({ item }) => (
                      <CreditItem onPress={onPressCreditItem} item={item} />
                    )}
                  />
                </View>
              </CanShow>
              {/* Redemption History */}
              <CanShow show={!isEmpty(redemptions)}>
                <View style={[{ padding: DIM.screenPadding }]}>
                  <View style={[GlobalStyles.flexRow, { flex: 1, marginBottom: 14 }]}>
                    <View style={{ alignItems: 'flex-start', flex: 0.5 }}>
                      <MyText font={FONT.w500}>Redemption History</MyText>
                    </View>
                    <View style={{ alignItems: 'flex-end', flex: 0.5 }}>
                      <TouchableOpacity
                        onPress={() => navigation.navigate(CROUTES.REDEMPTION_HISTORY)}>
                        <MyText size={12} color="#049869" underline>
                          View all
                        </MyText>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <FlatList
                    data={redemptions}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
                    renderItem={({ item }) => (
                      <RedeemItem onPress={() => onPressRedeemItem(item)} item={item} />
                    )}
                  />
                </View>
              </CanShow>
              {/* FOOTERS */}
              <View style={{ width: '100%', padding: 20 }}>
                <MyText font={FONT.w500} color="#18171E">
                  Want to know more?
                </MyText>
                <Spacing size={8} />
                <TouchableOpacity
                  onPress={() => navigation.navigate(CROUTES.LEARN_PROGRAM)}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#049869',
                    borderBottom: '2px solid #054631',
                    borderRadius: 8,
                    flexDirection: 'row',
                    padding: 12,
                  }}>
                  <MyText color={Colors.white} center>
                    Learn about Program
                  </MyText>
                </TouchableOpacity>
              </View>
            </View>
          </CanShow>
        </View>
        <Spacing size={60} />
      </ScrollView>
    </View>
  );
};

export default ConsumerHome;
