import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import React from 'react';
import LocationPinFilledIcon from '../../assets/svg/LocationPinFilledIcon';
import MyText from '../../shared/components/MyText';

const ViewLocationsButton = ({ onPress }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        background: '#FEFEFE',
        border: '1px solid #F0F0F0',
        borderRadius: 8,
        paddingHorizontal: 16,
        paddingVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 6,
      }}>
      <LocationPinFilledIcon />
      <MyText>{'  '}Find Indian Oil Petrol pumps Nearby</MyText>
    </TouchableOpacity>
  );
};

ViewLocationsButton.propTypes = {
  onPress: PropTypes.func,
};

export default ViewLocationsButton;
