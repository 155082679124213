import PropsType from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Icon, Input } from 'react-native-elements';
import { LocalStrings } from '../../../constants/Localization';
import { Colors } from '../../../theme/Colors';
import { DIM } from '../../../theme/Dims';
import { fontPixel } from '../../../utils/normalizeSize';
import AppModal from '../AppModal';
import AppBottomSheet from '../BottomSheet';
import EmptyView from '../EmptyView';
import Footer from '../Footer';
import Spacing from '../Spacing';

const SelectDropdown = (props) => {
  const {
    value,
    placeholder = LocalStrings.JOB_FLOW.LIST.SELECT_ONE,
    errorMessage,
    modalTitle,
    disabled,
    listData,
    loading,
    bottomSheet,
    handleSubmit,
    inputProps,
    ...restProps
  } = props;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [value]);

  const handleOk = () => {
    handleSubmit();
    setVisible(false);
  };

  const ModalComponent = bottomSheet ? AppBottomSheet : AppModal;
  return (
    <View>
      <TouchableOpacity disabled={disabled} onPress={() => setVisible(true)}>
        <Input
          value={value}
          editable={false}
          disabled={disabled}
          errorMessage={errorMessage}
          rightIcon={<Icon size={18} style={styles.rightIcon} name="chevron-down" type="feather" />}
          placeholder={placeholder}
          placeholderTextColor={Colors.defaultColor}
          {...inputProps}
        />
      </TouchableOpacity>
      <ModalComponent
        visible={visible}
        isVisible={visible}
        setVisible={setVisible}
        setIsVisible={setVisible}
        style={[{ padding: DIM.screenPadding }]}
        title={modalTitle}>
        <View style={[{ flex: 1 }]}>
          <Spacing size={-10} />
          <FlatList
            data={listData}
            showsVerticalScrollIndicator={false}
            ListEmptyComponent={() => <EmptyView title={LocalStrings.SEARCH_ITEM.no_results} />}
            {...restProps}
          />
          <Spacing size={15} />
          <Footer style={[{ flexDirection: 'row' }]}>
            <Button
              title="Cancel"
              onPress={() => setVisible(false)}
              containerStyle={[{ flex: 1 }]}
              buttonStyle={[{ width: '100%', backgroundColor: Colors.dark, alignSelf: 'center' }]}
              titleStyle={[{ fontFamily: 'fontBold', fontSize: fontPixel(12) }]}
            />
            {handleSubmit && (
              <>
                <Spacing hr size={10} />
                <Button
                  title="Ok"
                  loading={loading}
                  onPress={() => handleOk()}
                  containerStyle={[{ flex: 1.5 }]}
                  buttonStyle={[
                    { width: '100%', backgroundColor: Colors.primary, alignSelf: 'center' },
                  ]}
                  titleStyle={[{ fontFamily: 'fontBold', fontSize: fontPixel(12) }]}
                />
              </>
            )}
          </Footer>
        </View>
      </ModalComponent>
    </View>
  );
};

SelectDropdown.propTypes = {
  listItem: PropsType.array,
  value: PropsType.any,
  modalTitle: PropsType.string,
  renderItem: PropsType.func,
};

const styles = StyleSheet.create({
  rightIcon: {
    marginRight: 5,
  },
  section: {
    flex: 1,
    padding: DIM.screenPadding,
    backgroundColor: Colors.white,
  },
});

export default SelectDropdown;
