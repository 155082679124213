import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { ListItem } from 'react-native-elements';
import { toLower } from 'lodash';
import GlobalStyles from '../../theme/GlobalStyles';
import MyText from '../../shared/components/MyText';
import { Colors } from '../../theme/Colors';

import PetrolIcon from '../../assets/svg/PetrolIcon';
import Spacing from '../../shared/components/Spacing';
import { FONT } from '../../theme/font';
import { toLocalDate, toQuantity } from '../../utils/Utils';

const RedeemItem = ({ onPress, item }) => {
  const {
    conversionPoints,
    locationName,
    payableAmount,
    redeemedFor,
    redeemedDate,
    totalBillAmount,
    uom,
  } = item;
  return (
    <TouchableOpacity onPress={onPress}>
      <ListItem
        containerStyle={{
          background: 'linear-gradient(89.99deg, #FEF8F3 0%, #F2E9DC 64.05%, #FFEAE3 99.97%)',
          borderRadius: '8px',
          elevation: 4,
          marginVertical: 6,
          marginHorizontal: 0,
        }}>
        <View style={{ flex: 1 }}>
          <View style={[GlobalStyles.flexRow]}>
            <ListItem.Content style={[styles.leftContent]}>
              <MyText size={12} color="#787796">
                {toLocalDate(redeemedDate, 'DD MMM YYYY hh:mma')}
              </MyText>
              <Spacing size={8} />
              <MyText size={14} numberOfLines={1} color={Colors.black}>
                {locationName || '-'}
              </MyText>
              <MyText size={12} color="#787796">
                Total Bill: {totalBillAmount} | Paid: {payableAmount}
              </MyText>
            </ListItem.Content>
            <ListItem.Content style={[styles.rightContent]}>
              <MyText size={12} color="#787796">
                {redeemedFor}
              </MyText>
              <Spacing size={8} />
              <View style={[GlobalStyles.flexRow]}>
                <View style={{ padding: 4 }}>
                  <PetrolIcon />
                </View>
                <MyText size={18} font={FONT.w500} color={Colors.black}>
                  {toQuantity(conversionPoints, 2)}
                  <MyText size={14} color={Colors.black}>
                    {toLower(uom)}
                  </MyText>
                </MyText>
              </View>
            </ListItem.Content>
          </View>
        </View>
        {/* {onPress && <ListItem.Chevron />} */}
      </ListItem>
    </TouchableOpacity>
  );
};

export default RedeemItem;

const styles = StyleSheet.create({
  leftContent: { flex: 0.7 },
  rightContent: {
    flex: 0.3,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    marginRight: 12,
  },
});
