export const Colors = {
  defaultColor: '#27353C',
  primary: '#328E69',
  info: '#7044ff',
  secondary: '#969ba6',
  // secondary: '#5b7583', whether this should be the icon needed over there.
  danger: '#f04141',
  red: '#FF0000',
  success: '#10dc60',
  warning: '#ffc107',
  black: '#000000',
  darkBlue: '#2F6BA2',
  dark: '#222428',
  info2: '#976dff',
  darkBlack: '#000000',
  danger2: '#E53935',
  white: '#FFFFFF',
  lightBlack: '#6d7a89',
  grey: '#f0f5f7',
  mediumDarkGrey: '#9eaabc',
  grey27: '#27353C',
  cardBgColor: '#F2F7F8',
  darkGrey: '#5B7583',
  blue: '#55C0EB',
  lightGrey: '#f2f4f5',
  eyeBlack: '#2e374d',
  iceBlue: '#f7faff',
  greya7: '#a7a7a7',
  navigatorBgColor: '#ffffff',
  clear: 'transparent',
  screenBgColor: '#ffffff',
  sectionTitleColor: '#8797ad',
  eyeGrey: '#b0bac9',
  darkOrange: '#e0b500',
  /** Input form field specific stylings */
  inputLabelColor: '#7A7E81',
  inputBorderColor: '#DBDBDB',
  inputBackgroundColor: '#f2f7f8',
  inputPlaceholderColor: '#BAC4C9',
};
