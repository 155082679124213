/* eslint-disable max-len  */
/* eslint-disable react/react-in-jsx-scope  */
/**
 * @format
 */

import { AppRegistry } from 'react-native';
import featherFont from 'react-native-vector-icons/Fonts/Feather.ttf';
import fontAwsomeFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import FontAwesome5 from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import materialFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';

import './assets/font.css';
// import 'react-native-gesture-handler';
import App from './App';
import { name as appName } from './app.json';
import './index.css';
import { AppConfig } from './shared/AppConfig';

const AppWeb = () => {
  console.log(' AppConfig:', AppConfig);

  return (
    <>
      <style type="text/css">{`
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${materialFont}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome';
          src: url(${fontAwsomeFont}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5Regular';
          src: url(${FontAwesome5}) format('truetype');
        }
        @font-face {
          font-family: 'Feather';
          src: url(${featherFont}) format('truetype');
        }
        @font-face {
          font-family: 'Ionicons';
          src: url(${Ionicons}) format('truetype');
        }
      `}</style>
      <App />
    </>
  );
};
AppRegistry.registerComponent(appName, () => AppWeb);
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./service-worker.js')
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
      // PushNotification.init();
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}
