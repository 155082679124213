/* eslint-disable max-len */
import React from 'react';
import Svg, { Defs, Path, ClipPath } from 'react-native-svg';

const FAQIcon = () => {
  return (
    <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_273_10971)">
        <Path
          d="M22.3801 22.3801C18.8869 25.8733 6.11291 25.8733 2.61968 22.3801C-0.873551 18.8869 -0.873551 6.11291 2.61968 2.61968C6.11291 -0.873551 18.8869 -0.873551 22.3801 2.61968C25.8733 6.11291 25.8733 18.8869 22.3801 22.3801Z"
          fill="#049869"
        />
        <Path
          d="M9.67084 6.76982V13.2703C9.67084 13.7662 10.0677 14.1631 10.5635 14.1631H15.6854L19.126 16.9719V14.1631H19.4865C19.9771 14.1631 20.374 13.7662 20.374 13.2703V6.76982C20.374 6.2792 19.9771 5.88232 19.4865 5.88232H10.5635C10.0677 5.88232 9.67084 6.2792 9.67084 6.76982Z"
          fill="#FFD54F"
        />
        <Path
          d="M11.0661 7.2146H18.9792V7.7771H11.0661V7.2146ZM11.0661 8.89897H18.9792V9.46147H11.0661V8.89897ZM11.0661 10.5839H18.9792V11.1464H11.0661V10.5839ZM11.0661 12.2682H18.9792V12.8307H11.0661V12.2682Z"
          fill="#EA3946"
        />
        <Path
          d="M15.0229 9.57866V16.0792C15.0229 16.575 14.626 16.9719 14.1302 16.9719H9.00833L5.56771 19.7807V16.9719H5.20677C5.0898 16.9718 4.974 16.9486 4.86601 16.9037C4.75803 16.8587 4.65999 16.7929 4.57752 16.71C4.49505 16.627 4.42979 16.5286 4.38547 16.4203C4.34115 16.3121 4.31865 16.1961 4.31927 16.0792V9.57866C4.31927 9.08804 4.71615 8.69116 5.20677 8.69116H14.1297C14.626 8.69116 15.0229 9.08804 15.0229 9.57866Z"
          fill="white"
        />
        <Path
          d="M10.0609 13.7811H9.28074V13.3598C9.28074 13.1759 9.30261 13.0384 9.34636 12.9488C9.39011 12.8587 9.48595 12.7405 9.63282 12.5931L10.137 12.0889C10.2434 11.9722 10.3004 11.8187 10.2958 11.6608C10.2958 11.4905 10.2406 11.3488 10.1302 11.2363C10.0193 11.1233 9.87657 11.0671 9.70209 11.0671C9.52709 11.0671 9.38074 11.1212 9.26355 11.2291C9.14636 11.3374 9.07865 11.4811 9.0599 11.6608H8.22449C8.27032 11.2509 8.43022 10.9301 8.70417 10.6978C8.97761 10.4655 9.3198 10.3494 9.72917 10.3494C10.1391 10.3494 10.4724 10.4608 10.7307 10.6843C10.9885 10.9077 11.1172 11.2192 11.1172 11.6197C11.1172 11.8957 11.0412 12.1259 10.8891 12.3103C10.8263 12.392 10.7594 12.4704 10.6885 12.5452C10.6422 12.591 10.5813 12.6499 10.5057 12.7212C10.4297 12.7926 10.3651 12.8546 10.3125 12.9077C10.2687 12.9512 10.2261 12.996 10.1849 13.0421C10.1021 13.1436 10.0609 13.2863 10.0609 13.4702V13.7811ZM9.6849 15.3139C9.53959 15.3139 9.41459 15.2645 9.30834 15.1655C9.25775 15.1205 9.21738 15.0652 9.18998 15.0033C9.16258 14.9414 9.14877 14.8744 9.14949 14.8067C9.14949 14.666 9.20105 14.5452 9.3047 14.4442C9.35348 14.3951 9.41165 14.3564 9.47572 14.3304C9.53979 14.3044 9.60846 14.2915 9.67761 14.2926C9.8224 14.2926 9.94792 14.3421 10.0537 14.441C10.1042 14.486 10.1446 14.5413 10.172 14.6032C10.1994 14.6651 10.2132 14.7322 10.2125 14.7999C10.2125 14.9405 10.1604 15.0613 10.0573 15.1624C10.0086 15.2114 9.95054 15.2501 9.88655 15.2761C9.82256 15.3022 9.75398 15.315 9.6849 15.3139Z"
          fill="#434A5C"
        />
      </g>
      <Defs>
        <ClipPath id="clip0_273_10971">
          <rect width="25" height="25" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default FAQIcon;
