import React, { useState } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import { Colors } from '../../theme/Colors';
import { pixelSizeVertical } from '../../utils/normalizeSize';
import CountdownTimer from './CountdownTimer';

const ResendOTP = ({ onPressResend }) => {
  const [blockResend, setBlockResend] = useState(true);
  const _resendOtp = () => {
    setBlockResend(true);
    onPressResend();
  };

  if (blockResend) {
    return (
      <CountdownTimer
        onCounterStop={() => setBlockResend(false)}
        msg="You can request new OTP in"
      />
    );
  }
  return (
    <View>
      <Text
        h4
        h4Style={[
          {
            fontWeight: 'normal',
            color: Colors.info,
            flexDirection: 'row',
            marginTop: pixelSizeVertical(8.3),
          },
        ]}
        onPress={_resendOtp}>
        Resend OTP
      </Text>
    </View>
  );
};

export default ResendOTP;
