/* eslint-disable max-len */
import {
  View,
  Dimensions,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Linking,
  Image,
} from 'react-native';
import React, { useState, useMemo } from 'react';
import { ListItem } from 'react-native-elements';
import { filter, map, sortBy } from 'lodash';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import CanShow from '../../shared/components/CanShow';
import { FONT } from '../../theme/font';
import DirectionIcon from '../../assets/svg/DirectionIcon';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { getArialDistance, getCurrentLocation, toDistance } from '../../utils/Utils';
import useConsumer from '../api/consumer.hook';

const LOCATION_WITH_CAR = require('../../assets/svg/Consumer/location-with-car.svg');

const { height } = Dimensions.get('window');

const NearLocationsScreen = () => {
  const [userLocation, setUserLocation] = useState({});
  const { locations } = useConsumer();

  useAsyncEffect(async () => {
    const { coords } = await getCurrentLocation();
    setUserLocation(coords);
  }, []);

  const _locations = useMemo(() => {
    const p1 = { lat: userLocation?.latitude, lng: userLocation?.longitude };
    const list = map(locations, (_location) => {
      const p2 = {
        lat: _location?.addressInfo?.latLng?.lat,
        lng: _location?.addressInfo?.latLng?.lng,
      };
      const distance = getArialDistance(p1, p2);
      return { ..._location, distance };
    });
    return sortBy(
      filter(list, (loc) => loc?.distance < 100 && loc?.collectionCenter),
      'distance'
    );
  }, [locations, userLocation]);

  return (
    <View>
      <View
        style={{
          width: '100%',
          padding: 30,
          backgroundColor: '#F5F5F5',
        }}>
        <Image source={LOCATION_WITH_CAR} style={{ height: 140, width: '100%' }} />
        <Spacing size={10} />
        <CanShow
          show={_locations?.length > 0}
          fallbackComponent={<MyText center>Sorry, There are no locations nerby</MyText>}>
          <MyText center color="#142040">
            {_locations?.length} Stations Nearby
          </MyText>
        </CanShow>
      </View>
      <ScrollView
        style={{
          maxHeight: height - 250,
          paddingHorizontal: DIM.screenPadding,
        }}>
        <FlatList
          data={_locations}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item.id}
          ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
          renderItem={({ item }) => <NearLocationItem item={item} />}
        />
        <Spacing size={60} />
      </ScrollView>
    </View>
  );
};

const NearLocationItem = ({ item }) => {
  return (
    <ListItem
      topDivider
      containerStyle={{
        backgroundColor: Colors.white,
        paddingVertical: 12,
        paddingHorizontal: 24,
      }}>
      <ListItem.Content>
        <MyText font={FONT.w500} color="#142040" height={21} size={14} numberOfLines={1}>
          {item?.name || '-'}
        </MyText>
        <Spacing size={8} />
        <MyText font={FONT.w400} color="#787796" height={18} size={10} numberOfLines={3}>
          {item?.addressInfo?.street || '-'}
        </MyText>
        <Spacing size={8} />
        <View style={{ flexDirection: 'row' }}>
          <MyText color="#142040" size={12} height={21}>
            Open: <MyText color="#787796">{item?.startTime || '-'}</MyText>
            {'  '}
          </MyText>
          <MyText color="#142040" size={12} height={21}>
            Close: <MyText color="#787796">{item?.endTime || '-'}</MyText>
            {'   '}
          </MyText>
          <CanShow show={item?.distance}>
            <MyText color="#142040" size={14} font={FONT.w700} height={21}>
              {'   '} ~ {toDistance(item?.distance || 0)}
            </MyText>
          </CanShow>
        </View>
      </ListItem.Content>
      <TouchableOpacity
        onPress={() =>
          Linking.openURL(
            `https://www.google.com/maps/search/?api=1&query=${item?.addressInfo?.latLng?.lat},${item?.addressInfo?.latLng?.lng}`
          )
        }
        style={{ alignItems: 'center', justifyContent: 'center' }}>
        <DirectionIcon />
        <Spacing size={4} />
        <MyText font={FONT.w400} color="#787796" height={18} size={10} numberOfLines={1}>
          Direction
        </MyText>
      </TouchableOpacity>
    </ListItem>
  );
};

export default NearLocationsScreen;
