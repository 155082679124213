/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const ReceiptIcon = () => {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M2.75 17.875V4.8125C2.75 4.63016 2.82243 4.4553 2.95136 4.32636C3.0803 4.19743 3.25516 4.125 3.4375 4.125H18.5625C18.7448 4.125 18.9197 4.19743 19.0486 4.32636C19.1776 4.4553 19.25 4.63016 19.25 4.8125V17.875L16.5 16.5L13.75 17.875L11 16.5L8.25 17.875L5.5 16.5L2.75 17.875Z"
        fill="#049869"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M6.53125 11.6875H15.4688"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M6.53125 8.9375H15.4688"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default ReceiptIcon;
