import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import { pixelSizeVertical } from '../../utils/normalizeSize';
import MyText from '../../shared/components/MyText';

/**
 * The CountdownTimer component is a timer that counts down from a specified time and displays a
 * message, and calls a function when the timer reaches zero.
 * @returns The `CountdownTimer` component is being returned, which renders a `View` containing a
 * `Text` component that displays a countdown timer. The timer starts at the value of
 * `countDownTime`
 * (default 30 seconds) and counts down by 1 second until it reaches 0, at which point the
 * `onCounterStop` function is called. The `msg` prop is used
 */
const CountdownTimer = ({ onCounterStop, msg = 'Time', countDownTime = 30 }) => {
  const interval = useRef();
  const [time, setTime] = useState(countDownTime);

  useEffect(() => {
    interval.current = setInterval(() => setTime(time - 1), 1000);
    return () => clearInterval(interval.current);
  }, [time]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(interval.current);
      onCounterStop();
    }
  }, [time]);

  const _time = time < 10 ? `0${time}` : time;

  return (
    <View style={{ flexDirection: 'row', marginTop: pixelSizeVertical(8.3) }}>
      <MyText color="#9eaabc" size={12} center>{`${msg} 00:${_time}s`}</MyText>
    </View>
  );
};

export default CountdownTimer;
