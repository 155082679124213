/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const RecyclableMaterialIcon = () => {
  return (
    <Svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M48.7291 53.0337V14.0552C48.7291 10.6575 45.975 7.90576 42.5796 7.90576H22.363C21.2647 7.90576 20.2454 8.46567 19.6543 9.38929L16.5246 14.2897C15.2708 16.2542 14.3065 18.3742 13.6508 20.5851L11.7797 53.1366H31.1588L48.7291 53.0337Z"
        fill="#FF9D66"
      />
      <Path
        d="M31.1565 53.7324H11.7773C11.6122 53.7324 11.4567 53.6654 11.3442 53.5457C11.2317 53.4261 11.1719 53.2658 11.1815 53.1007L13.0526 20.5492C13.055 20.5038 13.0622 20.4583 13.0766 20.4129C13.7513 18.1373 14.742 15.9671 16.0197 13.9643L19.1471 9.06627C19.8505 7.96319 21.0517 7.30518 22.3606 7.30518H42.5772C46.298 7.30518 49.3249 10.332 49.3249 14.0528V53.0289C49.3249 53.3591 49.0569 53.6247 48.7291 53.6247C48.3989 53.6247 48.1333 53.3567 48.1333 53.0289V14.0552C48.1333 10.9925 45.6424 8.50157 42.5796 8.50157H22.363C21.4657 8.50157 20.6402 8.9538 20.1568 9.70992L17.0295 14.608C15.8235 16.4959 14.8879 18.5417 14.2443 20.6856L12.4138 52.536H31.1612C31.4914 52.536 31.757 52.804 31.757 53.1318C31.7547 53.4644 31.4867 53.7324 31.1565 53.7324Z"
        fill="#2F5A84"
      />
      <Path
        d="M31.1158 0.598145H22.5927C22.0854 0.598145 21.6739 1.0097 21.6739 1.51697V3.99111C21.6739 4.49838 22.0854 4.90994 22.5927 4.90994H31.1158C31.6231 4.90994 32.0346 4.49838 32.0346 3.99111V1.51458C32.0346 1.00731 31.6231 0.598145 31.1158 0.598145Z"
        fill="#FF9D66"
      />
      <Path
        d="M31.1158 5.5034H22.5927C21.7576 5.5034 21.078 4.82385 21.078 3.98877V1.51463C21.078 0.679551 21.7576 0 22.5927 0H31.1158C31.9508 0 32.6304 0.679551 32.6304 1.51463V3.98877C32.6304 4.82385 31.9508 5.5034 31.1158 5.5034ZM22.5927 1.194C22.4156 1.194 22.272 1.33757 22.272 1.51463V3.98877C22.272 4.16584 22.4156 4.3094 22.5927 4.3094H31.1158C31.2928 4.3094 31.4364 4.16584 31.4364 3.98877V1.51463C31.4364 1.33757 31.2928 1.194 31.1158 1.194H22.5927Z"
        fill="#2F5A84"
      />
      <Path d="M30.3908 4.90747H23.3153V7.90563H30.3908V4.90747Z" fill="#FFD983" />
      <Path
        d="M30.3932 8.50153H23.3177C22.9875 8.50153 22.7219 8.23354 22.7219 7.90573V4.90757C22.7219 4.57737 22.9899 4.31177 23.3177 4.31177H30.3932C30.7234 4.31177 30.989 4.57976 30.989 4.90757V7.90573C30.989 8.23593 30.721 8.50153 30.3932 8.50153ZM23.9135 7.30753H29.795V5.50337H23.9135V7.30753Z"
        fill="#2F5A84"
      />
      <Path
        d="M35.3151 17.9292H42.4073C43.4769 17.9292 44.3407 17.063 44.3407 15.9958V14.1199C44.3407 13.0503 43.4745 12.1865 42.4073 12.1865H35.3151C34.2455 12.1865 33.3817 13.0527 33.3817 14.1199V15.9958C33.3793 17.063 34.2455 17.9292 35.3151 17.9292Z"
        fill="white"
      />
      <Path
        d="M42.4073 18.5272H35.3151C33.9201 18.5272 32.7835 17.3906 32.7835 15.9956V14.1197C32.7835 12.7247 33.9201 11.5881 35.3151 11.5881H42.4073C43.8023 11.5881 44.9389 12.7247 44.9389 14.1197V15.9956C44.9389 17.3906 43.8023 18.5272 42.4073 18.5272ZM35.3151 12.7821C34.5781 12.7821 33.9775 13.3827 33.9775 14.1197V15.9956C33.9775 16.7326 34.5781 17.3332 35.3151 17.3332H42.4073C43.1443 17.3332 43.7449 16.7326 43.7449 15.9956V14.1197C43.7449 13.3827 43.1443 12.7821 42.4073 12.7821H35.3151Z"
        fill="#2F5A84"
      />
      <Path
        d="M48.7291 46.212V24.72H19.0035H14.0935L14.2323 45.9703H18.0081H27.7491L48.7291 46.212Z"
        fill="white"
      />
      <Path
        d="M48.7291 46.8076C48.3989 46.8076 48.1332 46.5396 48.1332 46.2118V25.318H14.6941L14.8257 45.3767H27.7491C28.0793 45.3767 28.3449 45.6447 28.3449 45.9725C28.3449 46.3003 28.0769 46.5683 27.7491 46.5683H14.2323C13.9045 46.5683 13.6365 46.3027 13.6365 45.9749L13.4977 24.7222C13.4977 24.5642 13.5599 24.4111 13.67 24.2986C13.7824 24.1862 13.9356 24.1216 14.0935 24.1216H48.7291C49.0593 24.1216 49.3249 24.3896 49.3249 24.7174V46.2094C49.3249 46.5396 49.0593 46.8076 48.7291 46.8076Z"
        fill="#2F5A84"
      />
      <Path
        d="M12.0309 19.9271C11.4806 19.7812 11.0882 19.2954 11.0882 18.726V16.7495H6.19492V18.726C6.19492 19.2954 5.8025 19.7812 5.25216 19.9271C2.50763 20.6521 0.591012 23.1335 0.591012 25.9761V28.0578C0.591012 28.5388 0.679547 29.0197 0.873362 29.46C1.0935 29.9577 1.3591 30.4482 1.67734 30.9339C2.83545 32.7022 3.98398 34.37 3.58917 36.5953C3.38818 37.7366 2.84262 38.6818 2.20136 39.6269C1.89987 40.0696 1.127 41.1104 1.58881 42.5892C2.00515 43.922 1.87594 44.8049 1.30406 45.585C0.813542 46.2549 0.588623 47.0828 0.588623 47.9131V51.2822C0.588623 52.3063 1.41892 53.1366 2.44303 53.1366H14.8305C15.8546 53.1366 16.6849 52.3063 16.6849 51.2822C17.4458 42.5916 17.0151 29.2422 16.6849 25.9761C16.3595 22.7099 14.7754 20.6521 12.0309 19.9271Z"
        fill="#6AC7EF"
      />
      <Path
        d="M14.8377 53.7325H2.45021C1.09829 53.7325 0 52.6318 0 51.2823V47.9132C0 46.8987 0.287133 45.9727 0.830295 45.2309C1.18203 44.75 1.45003 44.1207 1.02651 42.7664C0.497703 41.0699 1.32321 39.8615 1.67734 39.3447L1.71324 39.292C2.36168 38.3349 2.83066 37.4927 3.00773 36.4901C3.3499 34.5615 2.39039 33.0995 1.28253 31.4078L1.18682 31.2595C0.856619 30.757 0.571876 30.233 0.33499 29.6994C0.112461 29.1993 0 28.6465 0 28.0579V25.9762C0 22.868 2.09847 20.1426 5.10381 19.3506C5.39573 19.274 5.60151 19.018 5.60151 18.7261V16.7496C5.60151 16.4194 5.8695 16.1538 6.19731 16.1538H11.0906C11.4208 16.1538 11.6864 16.4218 11.6864 16.7496V18.7261C11.6864 19.0156 11.8897 19.2716 12.1841 19.3506C15.1655 20.1378 16.9313 22.4062 17.2855 25.9164C17.5918 28.9432 18.0679 42.3668 17.2879 51.3086C17.2735 52.6486 16.18 53.7325 14.8377 53.7325ZM6.79312 17.3478V18.7284C6.79312 19.5611 6.22364 20.2909 5.4053 20.5063C2.92399 21.1595 1.18921 23.4111 1.18921 25.9762V28.0579C1.18921 28.479 1.26818 28.8691 1.42132 29.2184C1.62949 29.6922 1.88552 30.1588 2.17983 30.6062L2.27554 30.7522C3.41929 32.4965 4.60372 34.3007 4.18019 36.6959C3.95288 37.9784 3.33315 39.0217 2.69906 39.9596L2.66078 40.017C2.36168 40.4525 1.80656 41.2661 2.16308 42.4098C2.6201 43.8718 2.50525 44.9605 1.7898 45.9368C1.39739 46.4728 1.19161 47.1547 1.19161 47.9132V51.2823C1.19161 51.9762 1.7563 52.5385 2.44782 52.5385H14.8353C15.5292 52.5385 16.0915 51.9738 16.0915 51.2823C16.0915 51.2655 16.0915 51.2464 16.0939 51.2296C16.8715 42.3596 16.3978 29.0366 16.0939 26.036C15.7876 23.0235 14.3687 21.1619 11.8754 20.5039C11.0595 20.2885 10.4876 19.5563 10.4876 18.7261V17.3454L6.79312 17.3478Z"
        fill="#2F5A84"
      />
      <Path
        d="M5.55125 16.7494H11.7318C12.074 16.7494 12.3492 16.4718 12.3492 16.132V12.6864C12.3492 12.3443 12.0716 12.0691 11.7318 12.0691H5.55125C5.20909 12.0691 4.93391 12.3467 4.93391 12.6864V16.132C4.93391 16.4718 5.21148 16.7494 5.55125 16.7494Z"
        fill="#FF9D66"
      />
      <Path
        d="M11.7318 17.3476H5.55126C4.88128 17.3476 4.33572 16.8021 4.33572 16.1321V12.6865C4.33572 12.0165 4.88128 11.4709 5.55126 11.4709H11.7318C12.4018 11.4709 12.9474 12.0165 12.9474 12.6865V16.1321C12.9474 16.8021 12.4018 17.3476 11.7318 17.3476ZM5.55126 12.6649C5.5393 12.6649 5.52973 12.6745 5.52973 12.6865V16.1321C5.52973 16.1441 5.5393 16.1536 5.55126 16.1536H11.7318C11.7438 16.1536 11.7534 16.1441 11.7534 16.1321V12.6865C11.7534 12.6745 11.7438 12.6649 11.7318 12.6649H5.55126Z"
        fill="#2F5A84"
      />
      <Path
        d="M0.607773 28.3712C5.2091 30.417 5.96761 27.7874 8.64992 27.5768C11.3322 27.3662 15.2396 28.8402 16.8332 27.84H16.8309C16.9936 30.6228 17.1371 35.3031 17.1156 40.2011C16.1824 39.0957 12.1003 39.0957 10.1023 40.718C8.10436 42.3403 4.05338 42.1991 1.61513 40.718C1.78741 40.2179 2.0554 39.847 2.20615 39.6245C2.84741 38.6793 3.39297 37.7318 3.59396 36.5928C3.98877 34.3675 2.84263 32.7021 1.68213 30.9315C1.36389 30.4458 1.09829 29.9552 0.878153 29.4575C0.725015 29.1154 0.636486 28.7469 0.607773 28.3712Z"
        fill="#FFD983"
      />
      <Path
        d="M6.13032 42.4816C6.09203 42.4816 6.05375 42.4816 6.01547 42.4816C4.29745 42.4625 2.57943 42.0054 1.30407 41.2302C1.07197 41.089 0.9643 40.8066 1.04326 40.5458C1.04565 40.5387 1.04805 40.5315 1.05044 40.5219C1.23708 39.9859 1.51225 39.5839 1.67495 39.3423L1.71085 39.2872C2.35929 38.3301 2.82828 37.4879 3.00534 36.4853C3.34751 34.5567 2.388 33.0947 1.28015 31.403L1.18443 31.2547C0.854229 30.7522 0.569492 30.2281 0.332606 29.6946C0.157933 29.2997 0.0502561 28.869 0.0143644 28.4144C-0.0119563 28.0866 0.232109 27.7971 0.55992 27.7708C0.660417 27.7636 0.760913 27.7803 0.851839 27.821C3.63465 29.0581 4.77361 28.4814 6.09204 27.8114C6.82184 27.4406 7.57796 27.0577 8.60446 26.9764C9.7841 26.883 11.1384 27.0912 12.4497 27.2898C14.0624 27.5363 15.7278 27.7899 16.4863 27.3448C16.5772 27.2802 16.6849 27.2443 16.795 27.2372C17.0677 27.2204 17.3166 27.3927 17.3979 27.6511C17.4171 27.7133 17.4267 27.7779 17.4267 27.8425C17.5774 30.4746 17.7305 35.0855 17.709 40.1965C17.709 40.4477 17.5511 40.6703 17.3166 40.754C17.0821 40.8401 16.8189 40.7684 16.6562 40.5769C16.3834 40.2539 15.3952 39.962 14.0337 40.0242C12.61 40.0912 11.2796 40.5195 10.4756 41.1727C9.44193 42.0222 7.90337 42.4816 6.13032 42.4816ZM2.38083 40.4597C3.41929 40.9741 4.71858 41.2708 6.02743 41.2852C7.54924 41.302 8.89638 40.9263 9.72429 40.2539C10.734 39.4332 12.2846 38.9187 13.9787 38.8398C14.9717 38.7943 15.8474 38.9044 16.5174 39.1461C16.5174 34.9467 16.4001 31.1685 16.2757 28.6848C15.187 28.9193 13.7585 28.7039 12.2678 28.4742C11.0236 28.2852 9.73864 28.089 8.69539 28.1704C7.90338 28.2326 7.28604 28.546 6.63041 28.8786C5.4077 29.4983 4.04142 30.1923 1.48353 29.35C1.67974 29.7759 1.91424 30.197 2.17983 30.6038L2.27555 30.7498C3.4193 32.4941 4.60372 34.2983 4.1802 36.6934C3.95288 37.976 3.33316 39.0192 2.69907 39.9572L2.66078 40.0146C2.57703 40.1391 2.47654 40.285 2.38083 40.4597Z"
        fill="#2F5A84"
      />
      <Path
        d="M38.1984 53.1364H32.6472C29.6227 53.1364 27.7563 49.8678 29.3092 47.298L34.8366 38.1456L36.3464 35.3556L39.6293 30.1991C41.1822 27.7585 44.7906 27.8135 46.2693 30.2973L51.8947 39.3109L53.7994 42.6871L56.6372 47.2525C58.2332 49.8224 56.3692 53.1316 53.3256 53.1316H42.8069L38.1984 53.1364Z"
        fill="#FFD983"
      />
      <Path
        d="M51.933 39.9068C51.8875 39.9068 51.8421 39.902 51.7966 39.89L46.3554 38.617C46.0348 38.5429 45.8362 38.2198 45.9104 37.8992C45.9845 37.5786 46.3076 37.38 46.6282 37.4542L51.4903 38.5931L52.5838 34.0038C52.6604 33.6831 52.9834 33.4845 53.3041 33.5611C53.6247 33.6377 53.8233 33.9583 53.7467 34.2813L52.5144 39.4497C52.4474 39.7225 52.2034 39.9068 51.933 39.9068ZM36.064 43.6898C35.7961 43.6898 35.552 43.5079 35.485 43.2375L34.3987 38.8563L29.7591 39.9857C29.4384 40.0647 29.1154 39.8685 29.0388 39.5478C28.9599 39.2272 29.1561 38.9042 29.4767 38.8276L34.693 37.557C35.0112 37.4781 35.3342 37.6743 35.4132 37.9925L36.6431 42.9504C36.7221 43.271 36.5282 43.594 36.2076 43.673C36.1598 43.6826 36.1119 43.6898 36.064 43.6898ZM46.5325 57.4172C46.3817 57.4172 46.231 57.3597 46.1137 57.2449L42.381 53.5767C42.2685 53.4643 42.2039 53.3135 42.2015 53.1532C42.2015 52.9929 42.2637 52.8422 42.3762 52.7297L46.286 48.8223C46.5181 48.5902 46.8962 48.5902 47.1307 48.8223C47.3628 49.0544 47.3628 49.4324 47.1307 49.6669L43.6492 53.1484L46.9512 56.393C47.1857 56.6251 47.1905 57.0032 46.9584 57.2377C46.8411 57.3573 46.688 57.4172 46.5325 57.4172Z"
        fill="#2F5A84"
      />
      <Path
        d="M51.8971 39.9115C51.6985 39.9115 51.5023 39.811 51.3898 39.6316L45.7644 30.618C45.762 30.6132 45.7596 30.6108 45.7572 30.606C45.1686 29.6178 44.1277 29.0196 42.9696 29.0004C41.8115 28.9837 40.7515 29.5508 40.1318 30.5223L36.8489 35.6787C36.6718 35.9563 36.3033 36.0376 36.0258 35.8606C35.7482 35.6835 35.6669 35.315 35.8439 35.0374L39.1268 29.881C39.9691 28.5578 41.4095 27.7825 42.9888 27.8064C44.5632 27.8304 45.9798 28.6463 46.7813 29.9887L52.4044 38.9975C52.5791 39.2775 52.4929 39.6459 52.213 39.8206C52.1149 39.8804 52.0048 39.9115 51.8971 39.9115ZM53.328 53.7323H42.8093C42.4791 53.7323 42.2135 53.4643 42.2135 53.1365C42.2135 52.8086 42.4815 52.5407 42.8093 52.5407H53.328C54.5483 52.5407 55.6251 51.909 56.2137 50.8514C56.7928 49.8105 56.7617 48.5854 56.1323 47.5732L53.2945 43.0078C53.1198 42.7278 53.206 42.3594 53.4859 42.1847C53.7659 42.01 54.1344 42.0962 54.309 42.3761L57.1469 46.9415C58.0083 48.327 58.049 50.0043 57.257 51.4304C56.4578 52.8733 54.9886 53.7323 53.328 53.7323ZM38.1984 53.7323H32.6472C30.9985 53.7323 29.5342 52.8804 28.7302 51.4543C27.9358 50.045 27.9597 48.3748 28.7972 46.9894L34.3245 37.837C34.4944 37.5546 34.8629 37.4637 35.1452 37.6336C35.4276 37.8035 35.5185 38.172 35.3486 38.4543L29.8213 47.6067C29.2087 48.6189 29.1896 49.8368 29.771 50.8681C30.3621 51.9137 31.4364 52.5383 32.6472 52.5383H38.1984C38.5286 52.5383 38.7942 52.8063 38.7942 53.1341C38.7942 53.4643 38.5262 53.7323 38.1984 53.7323Z"
        fill="#2F5A84"
      />
    </Svg>
  );
};
