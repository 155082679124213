export const ROUTES = {
  NAVIGATORS: {
    AUTH: 'AuthNavigator',
    HOME: 'MainTabNavigator',
    CONSUMER: 'ConsumerNavigator',
    INDEX: 'IndexStackNavigator',
    MRR: 'MRRStackNavigator',
    SALES: 'SalesStackNavigator',
    JOBS: 'JOBSStackNavigator',
    STOCK: 'STOCKStackNavigator',
    PROFILE: 'ProfileStackNavigator',
    BAG: 'BagNavigator',
    SUPER: 'SupervisorNavigator',
  },
  INDEX: 'Index',
  COMMON: 'Common',
  LOGIN: 'Login',
  OTP: 'VerifyOTP',
  HOME: 'Home',
  QR_SCREEN: 'Scan',
  /**
   * MRR ROUTES
   */
  MRR: 'Mrr',
  SELECT_CUSTOMER: 'Select Customer',
  ADD_CUSTOMER: 'Add Customer',

  CREATE_MRR: 'Create MRR',
  MRR_LIST: 'MRR List',
  REVIEW_MRR: 'Review MRR',
  MRR_SUCCESS_PAGE: 'mrr-success-page',
  MRR_DETAILS: 'MRR Details',
  MRR_PAYMENT: 'MRR Payment',
  SETTLE_DUE: 'Settle Due',
  /**
   * SO ROUTES
   */
  CREATE_SO: 'Create Sale Order',
  SO_LIST: 'Sale Orders',
  SO_DETAILS: 'SO Details',
  SO_SUCCESS_PAGE: 'so-success-page',
  /**
   * Jobs ROUTES
   */
  JOBS_LIST: 'Jobs',
  JOBS_SELECT_PROCESS: 'Select Process',
  JOBS_DETAILS: 'Job Details',
  JOBS_SUCCESS_PAGE: 'jobs-success-page',
  /**
   * Stocks transfer ROUTES
   */
  STOCKS_TRFR_LISTING: 'stocks-transfer-listing',
  STOCKS_TRFR_DETAILS: 'stocks-transfer-details',
  STOCKS_TRFR_INITIATE: 'stocks-transfer-initiate',
  STOCKS_TRFR_INITIATE_REVIEW: 'stocks-transfer-initiate-review',
  /**
   * Stocks adjustment ROUTES
   */
  STOCKS_ADJMT_LISTING: 'stocks-adjustment-listing',
  STOCKS_ADJMT_DETAILS: 'stocks-adjustment-details',
  STOCKS_ADJMT_INITIATE: 'stocks-adjustment-initiate',
  /**
   * Profile ROUTES
   */
  PROFILE: 'Others',
  PROFILE_CENTER_INFO: 'centre information',
  PROFILE_USER_INFO: 'user information',
  PROFILE_CHANGE_PWD: 'change password',
  PROFILE_CHANGE_LANG: 'Change Language/भाषा चुनो',

  /**
   * BAG ROUTE
   */
  BAG_TXNS: 'BagTxns',
  BAG_TXN_DETAILS: 'Bag Transaction Details',
  BAG_SCAN: 'Scan Bag',
  BAG_TXNS_SUCCESS: 'Bag Transaction Success',

  /**
   * Other screen
   */
  CAMERA: 'Camera',

  /**
   * Cash Management screen
   */
  LEDGER_LIST: 'Cash Ledger',
  LEDGER_DETAILS: 'Transaction Details',
  ADVANCE_PAY_LIST: 'Advance & Payables',
  CREATE_EXPENSE: 'Create Expense',
  CREATE_ADVANCE: 'Pay Advance',
  ADVANCE_FULL_TRANSACTIONS: 'Advance Payment',
  CASH_MRR_DETAILS: 'MRR Details',

  /**
   *  Supervisor route
   */
  ADJUSTMENT_LIST: 'Adjustment Screen',
  PRICE_APPROVAL: 'Price Approval',
  STOCK_APPROVAL: 'Stock Approval',
  CASH_APPROVAL_LIST: 'Cash Approval List',
  CASH_APPROVAL_DETAILS: 'Cash Approval Details',
  ADD_CASH: 'Add Cash',

  /**
   * Attendance
   */
  ATTENDANCE_LIST: 'Attendance Dashboard',
  REVIEW_ATTENDANCE: 'Review Attendance',
  REVIEW_AI_ATTENDANCE: 'Review AI Attendance',
  MANUAL_ATTENDANCE: 'Manual Attendance',
  MARK_MANUAL_ATTENDANCE: 'Mark Attendance',
  /**
   * Staff
   */
  STAFF_LIST: 'Employees',
  ADD_STAFF: 'Add Employee Details',
  STAFF_DETAILS: 'Employee Details',

  TXN_HISTORY: 'Transaction History',
  CREATE_TXN: 'Create Transaction',
  TXN_DETAIL: 'Order Transaction Details',
};

export const CROUTES = {
  HOME: 'Consumer Home',
  HOME_RVM: 'RVM Home',
  CONFIRM_REDEMPTION: 'Confirm Redemption',
  SHOW_RECEIPT: 'Show Receipt',
  INSERT_CONTAINERS: 'Insert Bottle',
  LOCATIONS_LIST: 'Nearby Indian Oil Petrol Pumps',
  SCAN_TO_PAY: 'Scan To Pay',
  SCAN_BIN: 'Bin QR',
  CREDIT_HISTORY: 'Earned Credits History',
  REDEMPTION_HISTORY: 'Redemption History',
  ENTER_BILL: 'Enter Bill',
  CREDIT_RECEIPT: 'Credit Receipt',
  LEARN_PROGRAM: 'Learn about Program',
  REDEMPTION_VALUES: 'Waste Redemption Value ',
};
