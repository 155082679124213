import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Divider, Input } from 'react-native-elements';
import LoginIcon from '../../assets/svg/LoginIcon';
import AppBottomSheet from '../../shared/components/BottomSheet';
import CanShow from '../../shared/components/CanShow';
import Loader from '../../shared/components/Loader';
import MyText from '../../shared/components/MyText';
import { Colors } from '../../theme/Colors';
import { FONT } from '../../theme/font';
import { useRequestOTPMutation } from '../api/consumer-app/consumer.api';
import VerifyOTP from './VerifyOTP';

const ConsumerLogin = ({ onSuccess = () => undefined }) => {
  const [mobile, setMobile] = useState('');
  const [requestOTP, { isLoading }] = useRequestOTPMutation();
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);

  const onPressLogin = async () => {
    try {
      await requestOTP({ mobile }).unwrap();
      setShowVerifyOtp(true);
    } catch (error) {
      console.log('🚀 ~ file: ConsumerLogin.js:25 ~ onPressLogin ~ error:', error);
    }
  };

  const onSuccessHandler = () => {
    setShowVerifyOtp(false);
    onSuccess && onSuccess();
  };

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingHorizontal: 30,
          width: Dimensions.get('window').width,
        }}>
        <View style={{ flex: 1, marginRight: 12, width: '100%' }}>
          <MyText font={FONT.w500} color={Colors.white}>
            Want to view your credits?{' '}
          </MyText>
          <Input
            maxLength={10}
            keyboardType="number-pad"
            textContentType="telephoneNumber"
            type="number"
            placeholder="Enter Mobile No"
            onChangeText={(value) => setMobile(value.replace(/[^0-9]/g, ''))}
            value={mobile}
            leftIcon={() => (
              <View style={{ flexDirection: 'row' }}>
                <MyText font={FONT.w400} size={14} color="#484848" style={{ marginLeft: 15 }}>
                  +91
                </MyText>
                <Divider orientation="vertical" style={{ marginLeft: 8 }} />
              </View>
            )}
          />
        </View>
        <TouchableOpacity
          onPress={onPressLogin}
          disabled={!mobile || mobile.length !== 10 || isLoading}
          style={{
            background: 'rgba(13, 13, 13, 0.7)',
            borderRadius: 10,
            flex: 0.2,
            paddingVertical: 13,
            marginTop: 7,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {isLoading ? <Loader /> : <LoginIcon />}
        </TouchableOpacity>
      </View>
      <CanShow show={showVerifyOtp}>
        <AppBottomSheet isVisible={showVerifyOtp} setIsVisible={setShowVerifyOtp} closeOnSubmit>
          <View style={{ marginVertical: 12 }}>
            <VerifyOTP mobile={mobile} resendOTP={onPressLogin} onSuccess={onSuccessHandler} />
          </View>
        </AppBottomSheet>
      </CanShow>
    </View>
  );
};

ConsumerLogin.propTypes = {
  onSuccess: PropTypes.func,
};

export default ConsumerLogin;

const styles = StyleSheet.create({});
