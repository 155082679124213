import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { CROUTES } from '../../constants/Routes';
import { useConsumerAuthCheck } from '../../consumer/ConsumerLogin/useConsumerAuthCheck.hook';
import CreditHistoryScreen from '../../consumer/History/CreditHistory.screen';
import RedemptionHistoryScreen from '../../consumer/History/RedemptionHistory.screen';
import ConsumerHome from '../../consumer/Home/ConsumerHome';
import RVMHome from '../../consumer/Home/RvmHome.screen';
import LearnAboutProgramScreen from '../../consumer/Locations/LearnAboutProgram.screen';
import NearLocationsScreen from '../../consumer/Locations/NearLocations.screen';
import ConfirmRedemptionScreen from '../../consumer/Redemption/ConfirmRedemption.screen';
import EnterTotalBillScreen from '../../consumer/Redemption/EnterTotalBill.screen';
import ShowReceptScreen from '../../consumer/Redemption/ShowRecept.screen';
import ScanBinQrScreen from '../../consumer/ScanBinQr/ScanBinQr.screen';
import CreditReceiptScreen from '../../consumer/Transactions/CreditReceipt.screen';
import InsertContainersScreen from '../../consumer/Transactions/InsertContainers.screen';
import { Colors } from '../../theme/Colors';
import Theme from '../../theme/Theme';
import { navigationRef } from '../navigationRef';
import LoaderOverlay from './LoaderOverlay';
import RedemptionValuesScreen from '../../consumer/Redemption/RedemptionValues.screen';

// import ConsumerHomeScreen from '../../ConsumerFlow/Home/Home.screen';
const Stack = createStackNavigator();
const { headerStyle } = Theme.customClasses.screenOptions;

const AppNavigationContainer = ({ navigator }) => {
  // if (!netInfo) return <NoInterNetConnection onTryAgain={getNetInfo} />;
  const { loading } = useConsumerAuthCheck();

  const linking = {
    prefixes: ['https://iocl.rcyl.in', 'rcyl://'],
    config: {
      screens: {
        // the event is closed, so we are redirecting to event close screen.
        // [CROUTES.EVENT_CLOSED]: '/qr/:id',
        [CROUTES.SCAN_TO_PAY]: '/pay/:id',
        [CROUTES.SCAN_BIN]: '/scan-bin-qr',
        [CROUTES.HOME_RVM]: '/rvm',
        [CROUTES.INSERT_CONTAINERS]: '/rvm/:id',
        [CROUTES.HOME]: '/',
      },
    },
  };
  return (
    <>
      <LoaderOverlay loading={loading} />
      <NavigationContainer
        linking={linking}
        ref={navigationRef}
        theme={{ colors: { background: Colors.navigatorBgColor } }}>
        <Stack.Navigator
          screenOptions={{ ...Theme.customClasses.webScreenOptions }}
          initialRouteName={CROUTES.BIN_HOME}>
          <Stack.Screen
            name={CROUTES.HOME}
            component={ConsumerHome}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={CROUTES.REDEMPTION_VALUES}
            component={RedemptionValuesScreen}
            options={{ headerShown: true }}
          />
          <Stack.Screen
            name={CROUTES.HOME_RVM}
            component={RVMHome}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={CROUTES.CONFIRM_REDEMPTION}
            component={ConfirmRedemptionScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={CROUTES.SHOW_RECEIPT}
            component={ShowReceptScreen}
            options={{ headerShown: true }}
          />
          <Stack.Screen
            name={CROUTES.LOCATIONS_LIST}
            component={NearLocationsScreen}
            options={{ headerShown: true }}
          />
          <Stack.Screen
            name={CROUTES.SCAN_BIN}
            component={ScanBinQrScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={CROUTES.CREDIT_HISTORY}
            component={CreditHistoryScreen}
            options={{ headerShown: true }}
          />
          <Stack.Screen
            name={CROUTES.REDEMPTION_HISTORY}
            component={RedemptionHistoryScreen}
            options={{ headerShown: true }}
          />
          <Stack.Screen
            name={CROUTES.INSERT_CONTAINERS}
            component={InsertContainersScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={CROUTES.CREDIT_RECEIPT}
            component={CreditReceiptScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={CROUTES.SCAN_TO_PAY}
            component={EnterTotalBillScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen name={CROUTES.LEARN_PROGRAM} component={LearnAboutProgramScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};

export default AppNavigationContainer;
