/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const AndroidLine = () => {
  return (
    <Svg width="304" height="6" viewBox="0 0 304 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM298.333 3C298.333 4.47276 299.527 5.66667 301 5.66667C302.473 5.66667 303.667 4.47276 303.667 3C303.667 1.52724 302.473 0.333333 301 0.333333C299.527 0.333333 298.333 1.52724 298.333 3ZM3 3.5H301V2.5H3V3.5Z"
        fill="#3BC297"
      />
    </Svg>
  );
};

export default AndroidLine;
