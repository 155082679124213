import React from 'react';
import { Text, View } from 'react-native';
import { Divider } from 'react-native-elements';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { FONT } from '../../theme/font';

const BillingSummary = ({ receiptNo, date, totalBill, redeemedCredit, amountToBePaid }) => {
  return (
    <View>
      <MyText size={16} color="#142040" center font={FONT.w400}>
        Billing Summary
      </MyText>
      {receiptNo && (
        <MyText size={12} color="#787796" center font={FONT.w300}>
          Receipt no. : {receiptNo}
        </MyText>
      )}

      <Spacing size={10} />
      <MyText size={12} color="#484848" center font={FONT.w400}>
        Date : {date}
      </MyText>
      <Spacing size={10} />
      <Divider
        orientation="vertical"
        style={{ borderStyle: 'dashed', marginBottom: 10 }}
        color="#6E6D7A"
      />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 8 }}>
        <MyText>Total Bill</MyText>
        <Text
          style={{
            textAlign: 'center',
          }}>
          ₹ <MyText>{totalBill}</MyText>
        </Text>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
        <MyText>Redeemed Credit</MyText>
        <Text
          style={{
            textAlign: 'center',
          }}>
          - ₹ <MyText>{redeemedCredit}</MyText>
        </Text>
      </View>
      <Divider
        orientation="vertical"
        style={{ borderStyle: 'dashed', marginTop: 10 }}
        color="#6E6D7A"
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 14,
          paddingHorizontal: 10,
          backgroundColor: '#F8F8F8',
          opacity: 1,
        }}>
        <MyText size={14} font={FONT.w600}>
          Amount Paid
        </MyText>
        <Text
          style={{
            textAlign: 'center',
          }}>
          ₹{' '}
          <MyText size={14} font={FONT.w600}>
            {amountToBePaid}
          </MyText>
        </Text>
      </View>
      <Divider orientation="vertical" style={{ borderStyle: 'dashed' }} color="#6E6D7A" />
    </View>
  );
};
export default BillingSummary;
