import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { Text } from 'react-native-elements';

import CanShow from '../../shared/components/CanShow';
import { Colors } from '../../theme/Colors';
import { fontPixel, heightPixel, pixelSizeVertical, widthPixel } from '../../utils/normalizeSize';
import ResendOTP from './ResendOTP';

const CELL_COUNT = 4;

const OtpInput = ({
  value,
  setValue,
  error,
  onPressResend,
  containerStyle = { marginTop: pixelSizeVertical(20) },
}) => {
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  return (
    <>
      <CodeField
        ref={ref}
        {...props}
        value={value}
        autoFocus
        onChangeText={setValue}
        cellCount={CELL_COUNT}
        rootStyle={[styles.codeFiledRoot, containerStyle]}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({ index, symbol, isFocused }) => (
          <View
            // Make sure that you pass onLayout={getCellOnLayoutHandler(index)}
            // prop to root component of "Cell"
            onLayout={getCellOnLayoutHandler(index)}
            key={index}
            style={[
              styles.cellRoot,
              isFocused && styles.focusCell,
              { borderBottomColor: error && error?.status === 400 ? Colors.danger : '#707070' },
            ]}>
            <Text style={styles.cellText}>{symbol || (isFocused ? <Cursor /> : null)}</Text>
          </View>
        )}
      />
      <CanShow show={error && error?.status === 400}>
        <Text style={{ color: Colors.danger, marginTop: pixelSizeVertical(8), fontSize: 12 }}>
          Invalid OTP
        </Text>
      </CanShow>
      <ResendOTP onPressResend={onPressResend} />
    </>
  );
};

export const styles = StyleSheet.create({
  codeFiledRoot: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cellRoot: {
    width: widthPixel(29),
    height: heightPixel(29),
    justifyContent: 'center',
    alignItems: 'center',

    borderBottomWidth: 1,
  },
  cellText: {
    color: '#000',
    fontSize: fontPixel(15),
    textAlign: 'center',
    fontFamily: 'fontMedium',
  },
  focusCell: {
    borderBottomColor: '#007AFF',
    borderBottomWidth: 2,
  },
});

export default OtpInput;
