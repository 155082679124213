import { createSlice } from '@reduxjs/toolkit';
import { consumerApi } from './consumer.api';

export const consumerSlice = createSlice({
  name: 'consumer',
  initialState: {
    token: null,
    cart: null,
    locations: null,
    activeFuelType: 'petrol',
    config: {},
    userDetails: {},
    langKey: 'en',
    partnerDetails: null,
    binQr: null,
    binDetails: null,
  },
  extraReducers: (builder) => {
    builder.addMatcher(consumerApi.endpoints.collectAtRvm.matchFulfilled, (state, { payload }) => {
      state.cart = null;
      state.binDetails = null;
      state.binQr = null;
    });

    builder.addMatcher(consumerApi.endpoints.getLocations.matchFulfilled, (state, { payload }) => {
      state.locations = payload;
    });
    builder.addMatcher(
      consumerApi.endpoints.getConsumerDetails.matchFulfilled,
      (state, { payload }) => {
        state.userDetails = payload;
      }
    );
    builder.addMatcher(
      consumerApi.endpoints.getRedeemTypes.matchFulfilled,
      (state, { payload }) => {
        state.config = payload;
      }
    );
    builder.addMatcher(consumerApi.endpoints.verifyOTP.matchFulfilled, (state, { payload }) => {
      if (payload.id_token) {
        state.token = payload.id_token;
      }
    });
    builder.addMatcher(
      consumerApi.endpoints.getLocationById.matchFulfilled,
      (state, { payload }) => {
        state.binDetails = payload;
        state.binQr = payload?.displayId;
      }
    );
    builder.addMatcher(
      consumerApi.endpoints.redeemAtLocation.matchFulfilled,
      (state, { payload }) => {
        state.cart = null;
        state.binDetails = null;
        state.binQr = null;
      }
    );
  },
  reducers: {
    setConsumerToken: (state, { payload }) => {
      state.token = payload;
    },
    setConsumerDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    setLangKey: (state, action) => {
      state.langKey = action.payload;
    },
    bottleInserted: (state, action) => {
      let _dropItems = state.cart.dropRequestItems;
      const bottleIn = action.payload;
      _dropItems = _dropItems?.map((item, index) => {
        if (index === action.payload.totalCount - 1) {
          return { ...item, rvmPayload: bottleIn };
        }
        return item;
      });
      state.cart.dropRequestItems = _dropItems;
    },
    setActiveFuelType: (state, action) => {
      state.activeFuelType = action.payload;
    },
    setPartnerDetails: (state, action) => {
      state.partnerDetails = action.payload;
    },
    setLocationDetails: (state, action) => {
      state.binDetails = action.payload;
      state.binQr = action.payload?.displayId;
    },
  },
});

export const {
  setConsumerToken,
  setLangKey,
  bottleInserted,
  setTenantDetails,
  setLocationDetails,
  setActiveFuelType,
  setPartnerDetails,
  setConsumerDetails,
} = consumerSlice.actions;

export const getConsumerToken = (state) => state.consumer.token;
