import React from 'react';
import { View } from 'react-native';
import { Button, Icon, Image, Text } from 'react-native-elements';
import cartIcon from '../../assets/images/empty-cart.png';
import { LocalStrings } from '../../constants/Localization';
import { Colors } from '../../theme/Colors';
import { fs } from '../Dimensions';
import Loader from './Loader';

// import cartArrowIcon from '../../assets/images/empty-cart-arrow.png';

const EmptyView = ({ title, message, isCart, style, handleOnPress, loading, icon }) => {
  if (isCart) {
    return (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          },
          style,
        ]}>
        <View style={[{ position: 'relative' }]}>
          <View style={[{ alignItems: 'center', marginBottom: 20 }]}>
            <Image source={cartIcon} style={[{ width: 220, height: 90, resizeMode: 'contain' }]} />
            <View>
              <Text
                h4
                h4Style={[
                  {
                    marginVertical: 15,
                    fontFamily: 'fontRegular',
                    fontSize: fs(14),
                    width: 220,
                    textAlign: 'center',
                  },
                ]}>
                {title}
              </Text>
            </View>
          </View>
        </View>
        <Button
          onPress={handleOnPress}
          containerStyle={[{ width: '100%' }]}
          icon={
            <Icon type="feather" name="plus" color={Colors.white} style={{ marginRight: 10 }} />
          }
          title={LocalStrings.MRR_FLOW.REVIEW_MRR.ADD_ITEM_BUTTON}
        />
      </View>
    );
  }

  return (
    <View
      style={[
        {
          backgroundColor: 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 100,
          marginHorizontal: 50,
          flex: 1,
          opacity: loading ? 1 : 0.4,
        },
        style,
      ]}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {icon || <Icon name="shopping-bag" color={Colors.eyeGrey} size={100} />}

          <Text h2 h2Style={[{ marginVertical: 10 }]}>
            {title}
          </Text>
          <Text h4 h4Style={[{ textAlign: 'center' }]}>
            {message}
          </Text>
        </>
      )}
    </View>
  );
};

export default React.memo(EmptyView);
