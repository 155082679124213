import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import PetrolIcon from '../../assets/svg/PetrolIcon';
import { CROUTES } from '../../constants/Routes';
import MyText from '../../shared/components/MyText';
import { Colors } from '../../theme/Colors';
import GlobalStyles from '../../theme/GlobalStyles';
import EarnedCreditLedger from './EarnedCreditLedger';
import useConsumer from '../api/consumer.hook';
import NoCreditsPopup from './NoCreditsPopup';

const ConsumersCredits = ({ data }) => {
  const { navigate } = useNavigation();
  const { getFuelConversionPoint } = useConsumer();
  const [showNoCreditPopup, setShowNoCreditPopup] = useState(false);

  const conversionPoint = getFuelConversionPoint(data);

  const toggleShowNoCreditPopup = () => {
    setShowNoCreditPopup(!showNoCreditPopup);
  };

  const goToScanBin = () => {
    navigate(CROUTES.SCAN_BIN);
  };

  const onClickRedeemCredit = () => {
    conversionPoint?.value && conversionPoint?.value > 0
      ? goToScanBin()
      : toggleShowNoCreditPopup();
  };

  return (
    <View style={[GlobalStyles.flexRow, { justifyContent: 'space-between' }]}>
      <View style={{ flex: 0.6, marginRight: 12 }}>
        <EarnedCreditLedger creditCount={conversionPoint?.value || 0} />
      </View>
      <View style={{ flex: 0.4 }}>
        <TouchableOpacity onPress={onClickRedeemCredit}>
          <View
            style={{
              background: '#049869',
              borderBottom: '2px solid #054631',
              borderRadius: 8,
              alignItems: 'center',
              flexDirection: 'row',
              padding: 6,
            }}>
            <View style={{ marginHorizontal: 8 }}>
              <PetrolIcon size="33" />
            </View>
            <MyText color={Colors.white}>{`Redeem \nCredits`}</MyText>
          </View>
        </TouchableOpacity>
      </View>
      <NoCreditsPopup visible={showNoCreditPopup} toggleOverlay={toggleShowNoCreditPopup} />
    </View>
  );
};

export default ConsumersCredits;
