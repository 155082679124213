import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Divider } from 'react-native-elements';
import InfoCircleIcon from '../../assets/svg/InfoCircleIcon';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { FONT } from '../../theme/font';
import { toLocalDate } from '../../utils/Utils';
import useConsumer from '../api/consumer.hook';

const ItemSummery = ({ data }) => {
  const { getFuelConversionPoint } = useConsumer();
  const totalObj = getFuelConversionPoint(JSON.parse(data?.conversion));

  return (
    <View>
      <View style={{ padding: 10 }}>
        <MyText size={16} color="#142040" center font={FONT.w400}>
          Credit Summary
        </MyText>
        <MyText size={12} color="#787796" center font={FONT.w300}>
          Receipt no. : #{data?.formattedId}
        </MyText>
        <Spacing size={10} />
        <MyText size={12} color="#484848" center font={FONT.w400}>
          Date : {toLocalDate(data?.orderDate, 'DD MMM YYYY hh:mma')}
        </MyText>
        <Spacing size={10} />
        <Divider
          orientation="vertical"
          style={{ borderStyle: 'dashed', marginBottom: 10 }}
          color="#6E6D7A"
        />
        {data?.orderItems?.map((item) => {
          const qty = item?.qty || 0;
          const name = item?.item?.name || '-';
          const conversionPoint = getFuelConversionPoint(JSON.parse(item?.conversionPoints));
          return (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 8,
                flex: 1,
              }}>
              <View style={{ flex: 0.2, alignItems: 'flex-start' }}>
                <MyText>{name}</MyText>
              </View>
              <View style={{ flex: 0.1, alignItems: 'flex-end' }}>
                <MyText>{qty}</MyText>
              </View>
              <View style={{ flex: 0.3, alignItems: 'flex-end' }}>
                <MyText>{`${conversionPoint?.value} ${conversionPoint?.units}`}</MyText>
              </View>
            </View>
          );
        })}
        <Divider
          orientation="vertical"
          style={{ borderStyle: 'dashed', marginTop: 10 }}
          color="#6E6D7A"
        />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 8,
            paddingVertical: 16,
            backgroundColor: '#F8F8F8',
            opacity: 1,
          }}>
          <MyText size={14} font={FONT.w600}>
            Total Fuel Credits
          </MyText>
          <Text
            style={{
              textAlign: 'center',
            }}>
            <MyText size={14} font={FONT.w600}>
              {`${totalObj?.value} ${totalObj?.units}`}
            </MyText>
          </Text>
        </View>
        <Divider orientation="vertical" style={{ borderStyle: 'dashed' }} color="#6E6D7A" />
        <View style={{ flexDirection: 'row', padding: 20 }}>
          <View style={{ marginRight: 10 }}>
            <InfoCircleIcon size="20" />
          </View>
          <MyText color="#787796" size={10} height={16}>
            Net worth of Fuel is calculated based on today’s Price. The credits may fluctuate if the
            fuel price changes.
          </MyText>
        </View>
      </View>
    </View>
  );
};

export default ItemSummery;

const styles = StyleSheet.create({});
