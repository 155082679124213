/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const LocationPinIcon = () => {
  return (
    <Svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M16 8.5C16 14.3333 8.5 19.3333 8.5 19.3333C8.5 19.3333 1 14.3333 1 8.5C1 6.51088 1.79018 4.60322 3.1967 3.1967C4.60322 1.79018 6.51088 1 8.5 1C10.4891 1 12.3968 1.79018 13.8033 3.1967C15.2098 4.60322 16 6.51088 16 8.5Z"
        stroke="#787796"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.49994 10.9993C9.88065 10.9993 10.9999 9.87998 10.9999 8.49927C10.9999 7.11856 9.88065 5.99927 8.49994 5.99927C7.11923 5.99927 5.99994 7.11856 5.99994 8.49927C5.99994 9.87998 7.11923 10.9993 8.49994 10.9993Z"
        stroke="#787796"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default LocationPinIcon;
