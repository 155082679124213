import { Dimensions, View, RefreshControl, FlatList } from 'react-native';
import React from 'react';
import CreditItem from '../components/CreditItem';
import { useLazyGetCreditHistoryQuery } from '../api/consumer-app/consumer.api';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import MyText from '../../shared/components/MyText';
import GlobalStyles from '../../theme/GlobalStyles';
import { DIM } from '../../theme/Dims';

const { height } = Dimensions.get('window');

const CreditHistoryScreen = () => {
  const [getCreditTxn, { isLoading, data: credits }] = useLazyGetCreditHistoryQuery();
  const onPressCreditItem = () => {};

  const reload = async () => {
    await getCreditTxn({ type: 'seller', sort: 'createdDate,desc', size: 50 });
  };

  useAsyncEffect(async () => {
    reload();
  }, []);

  return (
    <View style={[GlobalStyles.screenContainer]}>
      <FlatList
        style={{ height: height - DIM.headerHeight - DIM.screenPadding }}
        data={credits}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
        refreshControl={<RefreshControl refreshing={isLoading} onRefresh={reload} />}
        renderItem={({ item }) => <CreditItem onPress={onPressCreditItem} item={item} />}
      />
    </View>
  );
};

export default CreditHistoryScreen;
