/* eslint-disable max-len */
import { FlatList, View, TouchableOpacity } from 'react-native';
import React from 'react';
import { Divider, ListItem } from 'react-native-elements';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import MyText from '../../shared/components/MyText';
import { FONT } from '../../theme/font';
import { Colors } from '../../theme/Colors';

const faqList = [
  {
    id: 1,
    question: 'What type of waste can I bring?',
    answer:
      'Accepted waste materials: plastic, paper, cardboard, mobiles, laptops, cables, Wi-Fi equipment, and modems',
  },
  {
    id: 2,
    question: 'What types of waste are not accepted for disposal?',
    answer:
      'Items not accepted: mattresses, glass, batteries, large electronics, hazardous waste, and clothes',
  },
  {
    id: 3,
    question: 'Is this program running on all petrol pumps?',
    answer:
      'Refuel by Recykal is now available only at select Indian Oil Petrol Bunks. You can check the nearest petrol bunk in the app',
  },
  {
    id: 4,
    question: 'What is the redemption value of the waste?',
    answer:
      'Earn fuel credits based on the weight of your waste contribution. More waste, more credits!',
  },
];

/**
 * The FAQs function returns a FlatList component that displays a list of FAQ items using the
 * FaqAccordionItem component.
 * @returns The `FAQs` component is being returned, which contains a `FlatList` component that renders
 * a list of FAQ items using the `faqList` data. If the list is empty, a message is displayed using the
 * `ListEmptyComponent` prop. Each FAQ item is rendered using the `FaqAccordionItem` component.
 */
const FAQs = () => {
  return (
    <View>
      <FlatList
        data={faqList}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
        renderItem={({ item }) => <FaqAccordionItem item={item} />}
      />
    </View>
  );
};

/**
 * This is a React component for rendering a FAQ accordion item with expandable answer section.
 * @returns A functional component named `FaqAccordionItem` is being returned. It takes an object
 * `item` as a prop and renders a `TouchableOpacity` component that toggles the `expanded` state when
 * pressed. Inside the `TouchableOpacity`, a `ListItem.Accordion` component is rendered with some
 * custom styles and content. The content includes a `ListItem.Title` component that displays the
 * `item.question` and
 */
const FaqAccordionItem = ({ item }) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <TouchableOpacity onPress={() => setExpanded(!expanded)}>
      <ListItem.Accordion
        noRotation
        noIcon
        containerStyle={{
          border: '1px solid #EEEEEE',
          borderRadius: 8,
          marginVertical: 6,
          elevation: 4,
        }}
        content={
          <>
            <ListItem.Content>
              <ListItem.Title>
                <MyText font={FONT.w300} color={Colors.black}>
                  {item?.question || '-'}
                </MyText>
              </ListItem.Title>
              {expanded && (
                <View>
                  <Divider style={{ marginVertical: 6 }} />
                  <MyText font={FONT.w300} color={Colors.black} height={21}>
                    {item?.answer || '-'}
                  </MyText>
                </View>
              )}{' '}
            </ListItem.Content>
            {expanded ? <FaChevronUp /> : <FaChevronDown />}
          </>
        }
        isExpanded={expanded}
      />
    </TouchableOpacity>
  );
};

export default FAQs;
