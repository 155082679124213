import InvalidItem from '../../assets/svg/InvalidItem';
import PETItem from '../../assets/svg/PETItem';
import TinCanItem from '../../assets/svg/TinCanItem';

export const ITEMS = {
  PetBottle: {
    sku: 'SKUPE1010190',
    icon: PETItem,
  },
  Metal: {
    sku: 'SKUMAG30190',
    icon: TinCanItem,
  },
  Invalid: {
    sku: '',
    icon: InvalidItem,
  },
};

export const displayableItemIds = {
  SKUEWS401006: 4,
  SKUEWS401004: 4,
  SKUEWS401003: 4,
  SKUMIS501001: 4,
  SKUCO40101006: 4,
  SKUCO40101003: 4,
  SKUPEP201007: 4,
  SKUMP10108001: 4,
  SKUEWS401001: 4,
};
