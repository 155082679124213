import React from 'react';
import { View } from 'react-native';
import BottleFilledIcon from '../../assets/svg/BottleFilledIcon';
import CanFilledIcon from '../../assets/svg/CanFilledIcon';
import MyText from '../../shared/components/MyText';
import { FONT } from '../../theme/font';
import ItemCreditLedger from './ItemCreditLedger';

const EligibleItemComponent = ({
  titleColor = '#787796',
  title = 'Items Eligible for Fuel Credits',
}) => {
  // const { binDetails } = useConsumer();
  // const { data } = useGetCatalogItemsQuery({
  //   // locationId: 4720,
  //   locationId: binDetails?.id,
  //   // customerId: 16024
  // });

  return (
    <View>
      <MyText color={titleColor} height={21} font={FONT.w500} center>
        {title}
      </MyText>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginVertical: 20,
        }}>
        <ItemCreditLedger
          iconComponent={<CanFilledIcon size={50} />}
          iconText="Metal Can"
          itemCount="1 Item"
          itemValue="10ml"
        />
        <ItemCreditLedger
          iconComponent={<BottleFilledIcon />}
          iconText="Plastic Bottle"
          itemCount="1 Item"
          itemValue="7ml"
        />
      </View>
    </View>
  );
};

export default EligibleItemComponent;
