import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

const initialItemDetails = {
  items: [],
  catalogItems: [],
};

const ItemDetailSlice = createSlice({
  name: 'item',
  initialState: initialItemDetails,
  reducers: {
    setItemDetails: (state, action) => {
      state.items = keyBy(action.payload, 'id');
    },
    setCatalogItems: (state, action) => {
      state.catalogItems = action.payload;
    },
  },
});
export const { actions } = ItemDetailSlice;
export default ItemDetailSlice.reducer;
