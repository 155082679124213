export const CONSUMER_API = {
  REQUEST_OTP: '/stakeholders/request-otp',
  VERIFY_OTP: '/stakeholders/verify-otp',
  REDEEM_TYPES: '/core/service/redeem-types',
  LOCATIONS: '/core/service/locations',
  LOC_BY_DISPLAY_ID: '/core/service/location-display-id',
  CONSUMER_DETAILS: '/core/consumer-dashboard',
  GET_ORDER_PRICE: '/core/customers/orders-preview',
  CREATE_ORDER: '/core/customers/orders-lite',
  CUSTOMER: '/consumer',
  REDEEM_CREDIT: '/core/redeem',
  REDEEM_BALANCE: '/core/redeem',
  REDEEM_HISTORY: '/core/redeem/get-redeem-history',
  CREDIT_HISTORY: '/core/get-earned-txn',
  GET_ITEMS: '/catalog/items',
  GET_REDEMPTION_VALUES: '/core/iocl-item-prices',
};
