import { isUndefined } from 'lodash';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { ListItem } from 'react-native-elements';
import CanShow from '../shared/components/CanShow';
import { Colors } from '../theme/Colors';

const ListRowItem = (props) => {
  const { title, subtitle, leftIcon, checked, rightIcon, onSelect, ...listProps } = props;
  return (
    <TouchableOpacity onPress={() => onSelect(title)}>
      <ListItem containerStyle={{ paddingBottom: 20 }} {...listProps}>
        {leftIcon}
        <ListItem.Content>
          <ListItem.Title style={[{ color: Colors.black }]}>{title}</ListItem.Title>
          {subtitle && <ListItem.Subtitle>{subtitle}</ListItem.Subtitle>}
        </ListItem.Content>

        {!isUndefined(checked) ? (
          <CanShow show={checked}>{rightIcon}</CanShow>
        ) : (
          onSelect && (rightIcon || <ListItem.Chevron />)
        )}
      </ListItem>
    </TouchableOpacity>
  );
};

export default ListRowItem;
