import { useDispatch, useSelector } from 'react-redux';

import useAsyncEffect from '../../hooks/useAsyncEffect';
import { AppConfig } from '../../shared/AppConfig';
import {
  useGetLocationsQuery,
  useGetRedeemTypesQuery,
  useLazyGetConsumerDetailsQuery,
} from '../api/consumer-app/consumer.api';
import {
  setConsumerDetails,
  setConsumerToken,
  setPartnerDetails,
} from '../api/consumer-app/consumer.slice';

/**
 * This function checks if a consumer is authenticated and retrieves their details if they are.
 * @returns An object with two properties: "loading" and "reloadConsumerDetails".
 * The "loading" property is a boolean that indicates whether the queries for locations and
 * redeem types are currently loading or fetching.
 * The "reloadConsumerDetails" property is a function that can be called
 * to fetch the consumer details.
 */
export const useConsumerAuthCheck = () => {
  const dispatch = useDispatch();

  const partnerId = AppConfig.REACT_APP_REFUEL_PARTNER;

  const isUserLoggedIn = useSelector((state) => {
    const _token = state?.consumer?.token;
    return !!_token;
  });

  useGetLocationsQuery({ partnerId });
  useGetRedeemTypesQuery({ partnerId });
  const [getConsumerDetails, { isLoading, isFetching }] = useLazyGetConsumerDetailsQuery();

  useAsyncEffect(async () => {
    dispatch(setPartnerDetails({ partnerId: AppConfig.REACT_APP_REFUEL_PARTNER }));

    if (isUserLoggedIn) {
      try {
        await getConsumerDetails().unwrap();
      } catch (error) {
        if (error?.status === 500) {
          dispatch(setConsumerToken(null));
          dispatch(setConsumerDetails({}));
        }
      }
    }
  }, [isUserLoggedIn]);

  return {
    loading: isFetching || isLoading,
    reloadConsumerDetails: getConsumerDetails,
  };
};
