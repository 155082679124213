import { ScrollView, Dimensions } from 'react-native';
import React from 'react';
import AboutProgram from './AboutProgram';
import Spacing from '../../shared/components/Spacing';

const { height } = Dimensions.get('window');

const LearnAboutProgramScreen = () => {
  return (
    <ScrollView
      style={{
        maxHeight: height,
        marginBottom: 50,
      }}>
      <AboutProgram enableFooter />
      <Spacing size={50} />
    </ScrollView>
  );
};

export default LearnAboutProgramScreen;
