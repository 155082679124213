import { useNavigation } from '@react-navigation/native';
import React, { useRef, useState } from 'react';
import { View, Image } from 'react-native';
import { Header, Icon } from 'react-native-elements';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch } from 'react-redux';
// import { isEmpty } from 'lodash';
import LogoWhite from '../../assets/images/logo_white.png';
import { CROUTES } from '../../constants/Routes';
import MyText from '../../shared/components/MyText';
import QRScanner from '../../shared/components/QRScanner';
import { Colors } from '../../theme/Colors';
import { FONT } from '../../theme/font';
import { extractBinQr, getCurrentLocation } from '../../utils/Utils';
import { useGetLocationByIdMutation } from '../api/consumer-app/consumer.api';
// import { setLocationDetails } from '../api/consumer-app/consumer.slice';
import ViewLocationsButton from '../components/ViewLocationsButton';

const ScanBinQrScreen = ({ navigation }) => {
  const latLngRef = useRef({});
  const [binQrData, setBinQrData] = useState(null);
  const [getLocationDetails, { isLoading, data: rvmDetails }] = useGetLocationByIdMutation();
  const toast = useToast();
  const dispatch = useDispatch();

  const getLatLng = async () => {
    const { coords } = (await getCurrentLocation()) || {};
    latLngRef.current = { lat: coords.latitude, lng: coords.longitude };
  };

  // React.useEffect(() => {
  //   if (isEmpty(rvmDetails)) return;
  //   if (rvmDetails && rvmDetails?.activated) {
  //     dispatch(setLocationDetails(rvmDetails));
  //     navigation.navigate(CROUTES.ENTER_BILL, { id: rvmDetails?.displayId });
  //   }
  // }, [rvmDetails]);

  const handleScan = async (data) => {
    getLatLng();
    if (typeof data === 'string') {
      const binQr = extractBinQr(data);
      setBinQrData(data);
      navigation.navigate(CROUTES.SCAN_TO_PAY, { id: binQr });
      // try {
      //   await getLocationDetails(binQr);
      //   // check if bin valid, bin is not full, working.
      //   // if (rvmDetails && rvmDetails?.activated) {
      //   //   dispatch(setLocationDetails(rvmDetails));
      //   //   navigation.navigate(CROUTES.ENTER_BILL, { id: binQr });
      //   // }
      //   toast.show('Petrol Pump QR Scanned', { type: 'success' });
      // } catch (error) {
      //   toast.show('Problem Connecting to server', { type: 'error' });
      // }
    } else {
      toast.show(' Invalid Location QR', { type: 'error' });
    }
  };

  const handleBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: CROUTES.HOME }],
      });
    }
  };

  return (
    <View
      style={{
        flex: 1,
        background: 'linear-gradient(180deg, rgba(61, 78, 72, 0.8) 0%, rgba(12, 41, 32, 0.8) 100%)',
      }}>
      <Header
        backgroundColor={Colors.clear}
        placement="center"
        leftComponent={() => (
          <Icon
            onPress={handleBack}
            style={{ alignSelf: 'center' }}
            type="material"
            size={30}
            name="keyboard-backspace"
            color="#ffffff"
          />
        )}
        centerComponent={{
          text: 'Scan QR from Petrol Pump',
          style: { fontSize: 16, color: 'white' },
        }}
        containerStyle={{ height: 80 }}
      />
      <QRScanner
        onScan={handleScan}
        footerComponent={
          <View
            style={{
              height: 'auto',
              backgroundColor: 'transparent',
              flex: 1,
            }}>
            <ScanBinFooter />
          </View>
        }
      />
    </View>
  );
};

const ScanBinFooter = () => {
  const { navigate } = useNavigation();
  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
      }}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginVertical: 15,
        }}>
        <MyText size={16} color={Colors.white} center>
          <MyText size={16}>Scan &rdquo;</MyText>
          <MyText color="#E26613" size={16}>
            Refuel{' '}
          </MyText>{' '}
          with{' '}
          <Image
            source={LogoWhite}
            style={{
              height: 18,
              width: 80,
            }}
          />{' '}
          &rdquo;
        </MyText>
        <MyText size={16} color={Colors.white} center>
          QR code
        </MyText>
      </View>
      <View
        style={{
          backgroundColor: 'white',
          borderTopEndRadius: 23,
          borderTopStartRadius: 23,
          padding: 30,
        }}>
        <MyText font={FONT.w600} vpad={8}>
          Need help finding Petrol pumps?
        </MyText>
        <ViewLocationsButton onPress={() => navigate(CROUTES.LOCATIONS_LIST)} />
      </View>
    </View>
  );
};

export default ScanBinQrScreen;
