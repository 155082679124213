import { Dimensions } from 'react-native';
import React, { useState } from 'react';
import View from 'react-native-web/dist/exports/View';
import { capitalize, filter } from 'lodash';
import StyleSheet from 'react-native-web/dist/exports/StyleSheet';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import { DIM } from '../../theme/Dims';
import { FONT } from '../../theme/font';
import useConsumer from '../api/consumer.hook';
import SelectFuel from '../components/SelectFuel';
import MyText from '../../shared/components/MyText';
import PetrolIcon from '../../assets/svg/PetrolIcon';
import { getArialDistance, getCurrentLocation } from '../../utils/Utils';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import UnitItemValues from './ItemRedemptionValues/UnitItemValues';
import QuantumItemValues from './ItemRedemptionValues/QuantumItemValues';
import { useLazyGetRedemptionValuesQuery } from '../api/consumer-app/consumer.api';
import LoaderOverlay from '../../shared/components/LoaderOverlay';
import CanShow from '../../shared/components/CanShow';
import LocationPinFilledIcon from '../../assets/svg/LocationPinFilledIcon';
import { displayableItemIds } from '../constants/consumer.const';

const RedemptionValuesScreen = () => {
  const [currentLatLng, setCurrentLatLng] = useState({});
  const [fetching, setFetching] = useState(true);

  const { height } = Dimensions.get('window');
  const { activeFuelType } = useConsumer();

  const [getRedemptionValues, { data }] = useLazyGetRedemptionValuesQuery();

  useAsyncEffect(async () => {
    try {
      const { coords } = (await getCurrentLocation()) || {};
      await getRedemptionValues({ lat: coords?.latitude, lng: coords?.longitude }).unwrap();
      setCurrentLatLng({ lat: coords?.latitude, lng: coords?.longitude });
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  }, []);

  const unitsData = filter(data?.items, (item) => {
    return item?.units === 'PCS' && Object.keys(displayableItemIds)?.includes(item?.id);
  });

  const quantumsData = filter(data?.items, (item) => {
    return item?.units === 'KG' && Object.keys(displayableItemIds)?.includes(item?.id);
  });

  return (
    <View style={{ overflow: 'hidden' }}>
      <LoaderOverlay loading={fetching} />
      <CanShow show={data?.lat}>
        <View style={{ paddingHorizontal: DIM.screenPadding }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            }}>
            <View flex>
              <MyText font={FONT.w500} size={16} style={{ color: '#787796' }}>
                {data?.locationName}
              </MyText>
            </View>
            <View>
              <MyText font={FONT.w500} size={16} style={{ color: '#787796' }}>
                {getArialDistance(currentLatLng, { lat: data?.lat, lng: data?.lng }).toFixed(2)} km
                away
              </MyText>
            </View>
          </View>
          <SelectFuel />
        </View>
        <ScrollView
          style={{
            maxHeight: height - DIM.headerHeight,
            paddingHorizontal: DIM.screenPadding,
            paddingBottom: DIM.screenPadding,
          }}>
          <View style={{ paddingBottom: DIM.sectionPadding, paddingTop: 14 }}>
            <MyText font={FONT.w400} size={16} style={{ color: '#787796', paddingBottom: 10 }}>
              Value against 1 Unit of Waste{' '}
            </MyText>
            <View
              style={{
                flexDirection: 'row',
                height: 43,
              }}>
              <View style={[styles.initialHeaderElement, { flex: 3 }]}>
                <MyText style={{ paddingLeft: 20 }} size={16}>
                  Waste Type
                </MyText>
              </View>
              <View style={[styles.HeaderElementWithMargin, { flex: 2, flexDirection: 'row' }]}>
                <View style={{ marginHorizontal: 5 }}>
                  <PetrolIcon size="20" />
                </View>
                <MyText size={16}>{capitalize(activeFuelType)}/Unit</MyText>
              </View>
            </View>
            <FlatList
              data={unitsData}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item?.id}
              scrollEnabled={false}
              ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
              renderItem={({ item }) => (
                <UnitItemValues data={item} activeFuelType={activeFuelType} />
              )}
            />
          </View>
          <View>
            <MyText font={FONT.w400} size={16} style={{ color: '#787796', paddingBottom: 10 }}>
              Value against Quantum(kg) of Waste
            </MyText>
            <View
              style={{
                flexDirection: 'row',
                height: 43,
              }}>
              <View style={[styles?.initialHeaderElement, { flex: 3 }]}>
                <MyText style={{ paddingLeft: 20 }} size={16}>
                  Waste Type
                </MyText>
              </View>
              <View style={[styles?.HeaderElementWithMargin, { flex: 1, marginRight: 2 }]}>
                <MyText size={16}>Qty</MyText>
              </View>
              <View style={[styles?.HeaderElementWithMargin, { flex: 2, flexDirection: 'row' }]}>
                <View style={{ marginHorizontal: 5 }}>
                  <PetrolIcon size="20" />
                </View>
                <MyText size={16}>{capitalize(activeFuelType)}</MyText>
              </View>
            </View>
            <FlatList
              data={quantumsData}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item?.id}
              scrollEnabled={false}
              ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
              renderItem={({ item }) => (
                <QuantumItemValues data={item} activeFuelType={activeFuelType} />
              )}
            />
          </View>
        </ScrollView>
      </CanShow>
      <CanShow show={!data?.lat}>
        <View
          flex
          style={{
            height: height - DIM?.screenPadding - DIM?.inputHeight,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View>
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <LocationPinFilledIcon size={30} />
            </View>
            <MyText font={FONT?.w600} size={16} style={{ color: '#787796' }}>
              No Nearby Locations
            </MyText>
          </View>
        </View>{' '}
      </CanShow>
    </View>
  );
};

const styles = StyleSheet.create({
  initialHeaderElement: {
    backgroundColor: '#DAE6F2',
    justifyContent: 'center',
    marginRight: 2,
  },
  HeaderElementWithMargin: {
    backgroundColor: '#DAE6F2',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default RedemptionValuesScreen;
