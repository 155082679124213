import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Divider } from 'react-native-elements';
import MyText from '../../shared/components/MyText';
import { Colors } from '../../theme/Colors';
import { FONT } from '../../theme/font';
import { toQuantity } from '../../utils/Utils';

const EarnedCreditLedger = ({ isBeautify = false, creditCount = 0, unit = 'ml' }) => {
  return (
    <View
      style={{
        ...(isBeautify
          ? {
              background: 'linear-gradient(107.98deg, #B1FFED 14.34%, #DADCDC 91.06%)',
              boxShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.25)',
            }
          : { backgroundColor: '#FFFFFF' }),

        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        padding: 8,
        width: '100%',
      }}>
      <View>
        <MyText size={12}>Earned</MyText>
        <MyText font={FONT.w600} size={16}>
          Credits
        </MyText>
      </View>
      <Divider orientation="vertical" width={2} />
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ marginTop: 4 }}>
          <MyText size={20} font={FONT.w500} color={Colors.black} center>
            {toQuantity(creditCount, 2)}
            <MyText size={14} color={Colors.black}>
              {unit}
            </MyText>
          </MyText>
        </View>
      </View>
    </View>
  );
};

export default EarnedCreditLedger;

const styles = StyleSheet.create({});
