import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderId: 0,
  jobId: '',
};

const MRR = createSlice({
  name: 'mrr',
  initialState,
  reducers: {
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
  },
});

export const { actions } = MRR;
export default MRR.reducer;
