import React, { useCallback } from 'react';
import View from 'react-native-web/dist/exports/View';
import StyleSheet from 'react-native-web/dist/exports/StyleSheet';
import { find } from 'lodash';
import MyText from '../../../shared/components/MyText';
import { FONT } from '../../../theme/font';
import { ApproxValue } from '../../../utils/Utils';

const UnitItemValues = ({ data, activeFuelType }) => {
  const wasteType = useCallback(
    (item) => {
      return item.split(' - ');
    },
    [data]
  );

  const filteredData = find(data?.fuelValues, { type: activeFuelType });

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingBottom: 2,
        height: 58,
      }}>
      <View style={styles.firstElement}>
        <View style={{ paddingLeft: 20 }}>
          <MyText font={FONT.w600} size={14}>
            {wasteType(data?.name)?.[0]?.trim()}
          </MyText>
          {wasteType(data?.name)?.[1]?.trim() && (
            <MyText font={FONT.w600} size={12} style={{ color: '#787796' }}>
              ({wasteType(data?.name)?.[1]?.trim()})
            </MyText>
          )}
        </View>
      </View>
      <View
        style={[
          styles.secondElement,
          {
            background: 'linear-gradient(90deg, #FFF4DE 0%, #FFF9EF 48.98%, #ECFAFF 100%)',
          },
        ]}>
        <MyText size={18} font={FONT.w700} style={{ color: 'green' }}>
          {ApproxValue(filteredData?.value)} ml
        </MyText>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  firstElement: {
    flex: 3,
    backgroundColor: 'rgba(244, 244, 244, 1)',
    borderRadius: 4,
    marginRight: 2,
    justifyContent: 'center',
  },
  secondElement: {
    flex: 2,
    borderRadius: '4px',
    background: 'linear-gradient(90deg, #FFF4DE 0%, #FFF9EF 48.98%, #ECFAFF 100%)',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default UnitItemValues;
