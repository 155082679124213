/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const InfoCircleIcon = ({ color = '#FFD54F', size = '20' }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.99951C0 4.48051 4.48 -0.000488281 10 -0.000488281C15.53 -0.000488281 20 4.48051 20 9.99951C20 15.5205 15.53 19.9995 10 19.9995C4.48 19.9995 0 15.5205 0 9.99951ZM9.12 6.20951C9.12 5.73051 9.52 5.32951 10 5.32951C10.48 5.32951 10.87 5.73051 10.87 6.20951V10.6295C10.87 11.1105 10.48 11.4995 10 11.4995C9.52 11.4995 9.12 11.1105 9.12 10.6295V6.20951ZM10.01 14.6805C9.52 14.6805 9.13 14.2805 9.13 13.8005C9.13 13.3205 9.52 12.9305 10 12.9305C10.49 12.9305 10.88 13.3205 10.88 13.8005C10.88 14.2805 10.49 14.6805 10.01 14.6805Z"
        fill={color}
      />
    </Svg>
  );
};

export default InfoCircleIcon;
