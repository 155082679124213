import Geolocation from '@react-native-community/geolocation';
import { CommonActions } from '@react-navigation/native';
import { flatMap, isArray, isEmpty, map, pickBy, startCase, sumBy, toLower } from 'lodash';
import moment from 'moment';

export function changeNumberFormat(number, decimals, recursiveCall) {
  const decimalPoints = decimals || 2;
  const amount = number / 100;
  const noOfLakhs = amount / 100000;
  let displayStr;
  let isPlural;

  // Rounds off digits to decimalPoints decimal places
  function roundOf(integer) {
    return toRupees(integer * 100, decimalPoints);
  }

  if (noOfLakhs >= 1 && noOfLakhs <= 99) {
    const lakhs = roundOf(noOfLakhs);
    isPlural = lakhs > 1 && !recursiveCall;
    displayStr = `${lakhs} L`;
  } else if (noOfLakhs >= 100) {
    const crores = roundOf(noOfLakhs / 100);
    const crorePrefix = crores >= 100000 ? changeNumberFormat(crores, decimals, true) : crores;
    isPlural = crores > 1 && !recursiveCall;
    displayStr = `${crorePrefix} Cr`;
  } else if (amount > 0 && noOfLakhs < 1) {
    // thousand case
    const thousands = roundOf(noOfLakhs * 100);
    const crorePrefix =
      thousands >= 10000 ? changeNumberFormat(thousands, decimals, true) : thousands;
    isPlural = thousands > 1 && !recursiveCall;
    displayStr = `${crorePrefix} K`;
  } else {
    displayStr = roundOf(amount || 0);
  }
  return displayStr;
}

export function buildUrl(string = '', obj) {
  let s = string;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const prop in obj) {
    s = s.replace(new RegExp(`{${prop}}`, 'g'), obj[prop]);
  }
  return s;
}

export function transformParams(params, filterMap) {
  const newParams = {};
  map(params, (value, k) => {
    if (isArray(filterMap[k]) && isArray(value)) {
      value.forEach((paramValue, index) => (newParams[filterMap[k][index]] = paramValue));
      return true;
    }
    newParams[filterMap[k] || k] = value;
    return true;
  });
  return pickBy(newParams, (v) => v !== undefined && v !== '' && v !== null);
}

export function canonicalPhoneNumber(s) {
  s = s.replace(/[^\d+]/, '');
  console.log(s);
  s = s.replace(/^0/, '91');
  /* if (!s.startsWith('+')) {
        s = `+91${s}`;
    } */
  return s;
}

export function scrollHandler(e, navigation, route) {
  let offset = 0;
  let direction = null;
  const currentOffsetValue = e.nativeEvent.contentOffset.y;
  direction = currentOffsetValue > offset ? 'down' : 'up';
  offset = currentOffsetValue;
  if (direction === 'down') {
    navigation.dispatch({
      ...CommonActions.setParams({ tabBarVisible: false }),
      source: route.key,
    });
  } else {
    navigation.dispatch({
      ...CommonActions.setParams({ tabBarVisible: true }),
      source: route.key,
    });
  }
}
export function toAddress(addr) {
  if (isEmpty(addr)) {
    return '';
  }
  return [addr?.flatBuilding, addr?.landmark, addr?.street].filter(Boolean)?.join(', ');
}

export function percentage(percent, total) {
  return Math.round(Number((percent / 100) * total) || 0);
}
export function calcPercentage(value, total) {
  if (!value || !total) return 0;
  return parseFloat((value / total) * 100);
}
export function toRupees(value, isShowSymbol, decimal = 2) {
  const rupee = value
    ? value
        .toFixed(decimal)
        .toString()
        .replace(/,/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0;
  return isShowSymbol ? `₹${rupee}` : rupee;
}

export function toQuantity(value = 0, decimal = 0) {
  return numberWithCommas(value, decimal);
}

export function getOrderItems(orderItems, items) {
  return map(orderItems, (orderItem, k) => {
    // TODO remove it once api added these field
    // orderItem.qualityRiskFactor = 0.5;
    // orderItem.recykalMarginPercentage = 0.86;
    const orderQty = sumBy(flatMap(orderItems), (it) => orderItem.qty);
    const item = items ? items[k] : {};
    const itemQty = orderItem.qty;
    const lineTotal = orderItem.price * itemQty;
    const itemGSTPercentage = item?.sgstPercentage + item?.cgstPercentage || 0;
    const itemTax = percentage(itemGSTPercentage, lineTotal);
    const itemTotal = lineTotal + itemTax;

    return {
      ...orderItem,
      item,
      itemTotal,
      lineTotal,
      itemTax,
      itemQty,
      itemGSTPercentage,
    };
  });
}

export function numberWithCommas(x, decimal = 0) {
  if (!x) return x;
  if (decimal) {
    const [val, dec] = x.toString().split('.');
    const postDecimal = dec ? `${dec}`.substring(0, 2) : undefined;
    return [val.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ','), postDecimal]
      ?.filter(Boolean)
      ?.join('.');
  }
  return Math.round(x)
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function iff(condition, then, otherwise) {
  return condition ? then : otherwise;
}

export function toSize(size) {
  return size * 2 - size / 4;
}

export function toPaisa(value) {
  return value.toString().replace(',', '') * 100;
}

export function toLocalDate(dateString, format = 'DD-MMM-YYYY') {
  if (!dateString) return '';
  return moment(dateString).format(format);
}

export function toServerDate(date = moment()) {
  if (!date) return '';
  return moment(date).toISOString();
}
export const isSuccess = (res) => res.status >= 200 && res.status < 300;
export function extractError(res) {
  if (res?.data?.message && res?.data?.errorCode && res?.data?.errorType) {
    return res?.data;
  }
  if (res?.headers['x-recykalapp-error']) {
    return {
      message: res.headers['x-recykalapp-error'],
      key: res.headers['x-recykalapp-error-key'],
    };
  }
  if (res?.data?.detail) {
    return { message: res?.data?.detail };
  }
  if (res?.data?.message) {
    return res?.data;
  }
  return { message: 'Error while processing your request.' };
}

export function getTodaysRange() {
  return {
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
  };
}
export const getWeekRange = (date) => {
  const dateFormat = 'DD/MM/YYYY';
  const startOfWeek = moment(date).startOf('isoWeek');
  const endOfWeek = moment(date).endOf('isoWeek');
  const month = moment(date).month();
  const startDate =
    startOfWeek.month() !== month
      ? moment(date).startOf('month').format(dateFormat)
      : startOfWeek.format(dateFormat);
  const endDate =
    endOfWeek.month() !== month
      ? moment(date).endOf('month').format(dateFormat)
      : endOfWeek.format(dateFormat);
  return { startDate, endDate };
};

/**
 * return array of dates in @format given inclusive
 * of @startDate and @endDate
 * @returns String of date in @format
 */
export const getDatesBetween = (startDate, endDate, format = 'DD/MM/YYYY') => {
  const dates = [];
  const currDate = moment(startDate).subtract(1, 'day').startOf('day');
  const lastDate = moment(endDate).startOf('day');
  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    dates.push(currDate.format(format));
  }
  return dates;
};

export const toTitleCase = (str) => startCase(toLower(str));
export const getNoOfDaysBetween = (from, to, diffIn = 'days') => moment(to).diff(from, diffIn) + 1;
export const searchIn = (obj, query, keys) => {
  const q = query.toLowerCase();
  return keys.some((key) => obj[key]?.toLowerCase()?.includes(q));
};

export const extractBinQr = (str) => {
  return str ? str.split('/').pop() : null;
};

export function isOldQr(str) {
  return str.includes('?id=');
}

export const extractBottleQr = (str) => {
  // TODO: for old QR lots https://url.com?id={qrcode}
  if (isOldQr(str)) {
    return str.split('?id=').pop();
  }
  const code = str ? str.split('/').pop() : null;
  return code;
};

export function getCurrentLocation(options) {
  return new Promise((resolve, reject) => {
    Geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 1000,
    });
  });
}

/**
 * It takes two points (latitude and longitude) and returns the distance between them in kilometers
 * @param p1 - The first point
 * @param p2 - The destination point
 * @returns The distance between two points in km.
 */
export const getArialDistance = (p1, p2) => {
  const R = 6378137; // Earth’s mean radius in meter
  const rad = (x) => {
    return (x * Math.PI) / 180;
  };
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  const dInKm = d / 1000;
  return dInKm + percentage(18, dInKm);
};

/**
 * It takes a distance in kilometers and returns a string with the distance in meters
 * if the distance is less than 1 kilometer,
 * otherwise it returns a string with the distance in kilometers
 * @param distance - The distance between the two points in meters.
 * @returns A string with the distance in meters(m) or kilometers(km).
 */
export const toDistance = (distance) => {
  if (distance < 1) {
    const _dist = distance * 1000;
    return `${_dist.toFixed(2)} m`;
  }
  return `${distance.toFixed(2)} km`;
};

export const ApproxValue = (value) => {
  if (value > 100) {
    return value - (value % 10);
  }
  return Math?.floor(value);
};
