/* eslint-disable max-len */
import React from 'react';
import { Path, Svg } from 'react-native-svg';

const TxnInfoCircle = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00012 11.9993C2.00012 6.48027 6.48012 1.99927 12.0001 1.99927C17.5301 1.99927 22.0001 6.48027 22.0001 11.9993C22.0001 17.5203 17.5301 21.9993 12.0001 21.9993C6.48012 21.9993 2.00012 17.5203 2.00012 11.9993ZM11.1201 8.20927C11.1201 7.73027 11.5201 7.32927 12.0001 7.32927C12.4801 7.32927 12.8701 7.73027 12.8701 8.20927V12.6293C12.8701 13.1103 12.4801 13.4993 12.0001 13.4993C11.5201 13.4993 11.1201 13.1103 11.1201 12.6293V8.20927ZM12.0101 16.6803C11.5201 16.6803 11.1301 16.2803 11.1301 15.8003C11.1301 15.3203 11.5201 14.9303 12.0001 14.9303C12.4901 14.9303 12.8801 15.3203 12.8801 15.8003C12.8801 16.2803 12.4901 16.6803 12.0101 16.6803Z"
        fill="#FFD54F"
      />
    </Svg>
  );
};

export default TxnInfoCircle;
