import { Platform } from 'react-native';
import { hp } from '../shared/Dimensions';
import { fontPixel, heightPixel, widthPixel } from '../utils/normalizeSize';

console.log('🚀 ~ file: Dims.js:2 ~ Platform:', Platform);
export const DIM = {
  defaultFontSize: fontPixel(14),
  screenPadding: 18,
  sectionPadding: widthPixel(18),
  inputHeight: heightPixel(30),
  mainTabHeight: hp(93),
  cornerRadius: 8,
  buttonHeight: 55,
  headerHeight: Platform.OS === 'web' ? heightPixel(40) : heightPixel(48),
};
