import { Dimensions, FlatList, RefreshControl, View } from 'react-native';
import React from 'react';
import RedeemItem from '../components/RedeemItem';
import { useLazyGetRedeemHistoryQuery } from '../api/consumer-app/consumer.api';
import MyText from '../../shared/components/MyText';
import GlobalStyles from '../../theme/GlobalStyles';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { CROUTES } from '../../constants/Routes';
import { DIM } from '../../theme/Dims';

const { height } = Dimensions.get('window');

const RedemptionHistoryScreen = ({ navigation }) => {
  const [getRedeemTxns, { isLoading, data: redemptions }] = useLazyGetRedeemHistoryQuery();

  const onPressRedeemItem = (item) => {
    navigation.navigate(CROUTES.SHOW_RECEIPT, { billDetails: item, isViewReceipt: true });
  };

  const reload = async () => {
    await getRedeemTxns({ sort: 'redeemedDate,desc', size: 50 });
  };

  useAsyncEffect(async () => {
    reload();
  }, []);

  return (
    <View style={[GlobalStyles.screenContainer]}>
      <FlatList
        style={{ height: height - DIM.headerHeight - DIM.screenPadding }}
        data={redemptions}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={() => <MyText center>There is nothing to show.</MyText>}
        refreshControl={<RefreshControl refreshing={isLoading} onRefresh={reload} />}
        renderItem={({ item }) => (
          <RedeemItem onPress={() => onPressRedeemItem(item)} item={item} />
        )}
      />
    </View>
  );
};

export default RedemptionHistoryScreen;
