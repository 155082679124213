/* eslint-disable max-len */
import React from 'react';
import Svg, { Defs } from 'react-native-svg';

const DirectionIcon = () => {
  return (
    <Svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17.5" cy="17.5" r="17" fill="white" stroke="#FFD54F" />
      <g clipPath="url(#clip0_193_3664)">
        <path
          d="M27.631 17.1365L17.8636 7.36902C17.4403 6.94576 16.7566 6.94576 16.3334 7.36902L6.56592 17.1365C6.14266 17.5597 6.14266 18.2434 6.56592 18.6667L16.3334 28.4341C16.7566 28.8574 17.4403 28.8574 17.8636 28.4341L27.631 18.6667C28.0543 18.2543 28.0543 17.5706 27.631 17.1365ZM19.2636 20.6202V17.907H14.9225V20.0775C14.9225 20.6744 14.4341 21.1628 13.8372 21.1628C13.2403 21.1628 12.752 20.6744 12.752 20.0775V16.8217C12.752 16.2248 13.2403 15.7365 13.8372 15.7365H19.2636V13.0233L22.6822 16.4419C22.8992 16.6589 22.8992 16.9954 22.6822 17.2124L19.2636 20.6202Z"
          fill="#142040"
        />
      </g>
      <Defs>
        <clipPath id="clip0_193_3664">
          <rect
            width="26.0465"
            height="26.0465"
            fill="white"
            transform="translate(4.06976 4.88373)"
          />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default DirectionIcon;
