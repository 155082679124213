/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const WelcomeText1 = () => {
  return (
    <Svg width="94" height="14" viewBox="0 0 94 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M6.14133 0.720007H7.19733L8.79733 4.75201L10.4133 0.720007H11.4853L9.50133 5.56801L11.6773 10.704L15.7893 0.640007H17.0053L12.1893 12H11.1973L8.81333 6.38401L6.41333 12H5.43733L0.637329 0.640007H1.83733L5.96533 10.704L8.12533 5.56801L6.14133 0.720007Z"
        fill="#18171E"
      />
      <Path
        d="M20.9485 12.16C20.3405 12.16 19.7805 12.048 19.2685 11.824C18.7671 11.5893 18.3298 11.2747 17.9565 10.88C17.5831 10.4747 17.2898 10.0107 17.0765 9.48801C16.8738 8.96534 16.7725 8.40534 16.7725 7.80801C16.7725 7.22134 16.8738 6.67201 17.0765 6.16001C17.2898 5.63734 17.5778 5.18401 17.9405 4.80001C18.3138 4.40534 18.7565 4.09601 19.2685 3.87201C19.7805 3.63734 20.3351 3.52001 20.9325 3.52001C21.5405 3.52001 22.0951 3.63734 22.5965 3.87201C23.1085 4.09601 23.5458 4.40534 23.9085 4.80001C24.2711 5.19467 24.5538 5.64801 24.7565 6.16001C24.9591 6.67201 25.0605 7.21601 25.0605 7.79201C25.0605 7.87734 25.0605 7.96267 25.0605 8.04801C25.0605 8.13334 25.0551 8.19201 25.0445 8.22401H17.9085C17.9405 8.67201 18.0418 9.08801 18.2125 9.47201C18.3938 9.84534 18.6231 10.1707 18.9005 10.448C19.1778 10.7253 19.4925 10.944 19.8445 11.104C20.2071 11.2533 20.5911 11.328 20.9965 11.328C21.2631 11.328 21.5298 11.2907 21.7965 11.216C22.0631 11.1413 22.3085 11.04 22.5325 10.912C22.7565 10.784 22.9591 10.6293 23.1405 10.448C23.3218 10.256 23.4605 10.048 23.5565 9.82401L24.5005 10.08C24.3725 10.3893 24.1911 10.672 23.9565 10.928C23.7218 11.1733 23.4498 11.392 23.1405 11.584C22.8311 11.7653 22.4898 11.904 22.1165 12C21.7431 12.1067 21.3538 12.16 20.9485 12.16ZM24.0205 7.39201C23.9885 6.94401 23.8818 6.53867 23.7005 6.17601C23.5298 5.80267 23.3058 5.48267 23.0285 5.21601C22.7618 4.94934 22.4471 4.74134 22.0845 4.59201C21.7325 4.44267 21.3538 4.36801 20.9485 4.36801C20.5431 4.36801 20.1591 4.44267 19.7965 4.59201C19.4338 4.74134 19.1138 4.94934 18.8365 5.21601C18.5698 5.48267 18.3511 5.80267 18.1805 6.17601C18.0205 6.54934 17.9245 6.95467 17.8925 7.39201H24.0205Z"
        fill="#18171E"
      />
      <Path
        d="M26.7871 0.320007H27.8751V10.112C27.8751 10.4213 27.9604 10.6667 28.1311 10.848C28.3017 11.0187 28.5417 11.104 28.8511 11.104C28.9684 11.104 29.1071 11.088 29.2671 11.056C29.4271 11.024 29.5764 10.9813 29.7151 10.928L29.9071 11.808C29.7044 11.8933 29.4537 11.9627 29.1551 12.016C28.8671 12.0693 28.6164 12.096 28.4031 12.096C27.9017 12.096 27.5071 11.9573 27.2191 11.68C26.9311 11.392 26.7871 10.992 26.7871 10.48V0.320007Z"
        fill="#18171E"
      />
      <Path
        d="M30.5225 7.80801C30.5225 7.22134 30.6238 6.66667 30.8265 6.14401C31.0291 5.62134 31.3118 5.16801 31.6745 4.78401C32.0478 4.38934 32.4905 4.08001 33.0025 3.85601C33.5145 3.63201 34.0798 3.52001 34.6985 3.52001C35.4878 3.52001 36.1758 3.70134 36.7625 4.06401C37.3491 4.41601 37.7918 4.89601 38.0905 5.50401L37.0345 5.84001C36.7998 5.41334 36.4691 5.08267 36.0425 4.84801C35.6265 4.60267 35.1625 4.48001 34.6505 4.48001C34.2238 4.48001 33.8238 4.56534 33.4505 4.73601C33.0878 4.90667 32.7678 5.14134 32.4905 5.44001C32.2238 5.72801 32.0105 6.07467 31.8505 6.48001C31.7011 6.88534 31.6265 7.32801 31.6265 7.80801C31.6265 8.27734 31.7065 8.72001 31.8665 9.13601C32.0265 9.55201 32.2451 9.91467 32.5225 10.224C32.7998 10.5227 33.1198 10.7627 33.4825 10.944C33.8558 11.1147 34.2505 11.2 34.6665 11.2C34.9331 11.2 35.1945 11.1627 35.4505 11.088C35.7171 11.0133 35.9625 10.912 36.1865 10.784C36.4105 10.6453 36.6025 10.4907 36.7625 10.32C36.9225 10.1493 37.0345 9.96801 37.0985 9.77601L38.1705 10.096C38.0531 10.3947 37.8825 10.672 37.6585 10.928C37.4345 11.1733 37.1678 11.392 36.8585 11.584C36.5598 11.7653 36.2238 11.904 35.8505 12C35.4878 12.1067 35.1091 12.16 34.7145 12.16C34.1065 12.16 33.5465 12.048 33.0345 11.824C32.5225 11.5893 32.0798 11.2747 31.7065 10.88C31.3331 10.4747 31.0398 10.0107 30.8265 9.48801C30.6238 8.96534 30.5225 8.40534 30.5225 7.80801Z"
        fill="#18171E"
      />
      <Path
        d="M43.3536 12.16C42.7456 12.16 42.1909 12.048 41.6896 11.824C41.1882 11.5893 40.7509 11.2747 40.3776 10.88C40.0149 10.4853 39.7322 10.0267 39.5296 9.50401C39.3269 8.98134 39.2256 8.43201 39.2256 7.85601C39.2256 7.26934 39.3269 6.71467 39.5296 6.19201C39.7429 5.66934 40.0309 5.21067 40.3936 4.81601C40.7669 4.42134 41.2042 4.10667 41.7056 3.87201C42.2069 3.63734 42.7562 3.52001 43.3536 3.52001C43.9509 3.52001 44.5002 3.63734 45.0016 3.87201C45.5136 4.10667 45.9509 4.42134 46.3136 4.81601C46.6869 5.21067 46.9749 5.66934 47.1776 6.19201C47.3909 6.71467 47.4976 7.26934 47.4976 7.85601C47.4976 8.43201 47.3962 8.98134 47.1936 9.50401C46.9909 10.0267 46.7029 10.4853 46.3296 10.88C45.9669 11.2747 45.5296 11.5893 45.0176 11.824C44.5162 12.048 43.9616 12.16 43.3536 12.16ZM40.3296 7.87201C40.3296 8.34134 40.4096 8.77867 40.5696 9.18401C40.7296 9.57867 40.9429 9.93067 41.2096 10.24C41.4869 10.5387 41.8069 10.7733 42.1696 10.944C42.5429 11.1147 42.9376 11.2 43.3536 11.2C43.7696 11.2 44.1589 11.1147 44.5216 10.944C44.8949 10.7627 45.2202 10.5227 45.4976 10.224C45.7749 9.91467 45.9936 9.55734 46.1536 9.15201C46.3136 8.74667 46.3936 8.30934 46.3936 7.84001C46.3936 7.38134 46.3136 6.94934 46.1536 6.54401C45.9936 6.12801 45.7749 5.77067 45.4976 5.47201C45.2202 5.16267 44.8949 4.92267 44.5216 4.75201C44.1589 4.57067 43.7696 4.48001 43.3536 4.48001C42.9376 4.48001 42.5482 4.57067 42.1856 4.75201C41.8229 4.92267 41.5029 5.16267 41.2256 5.47201C40.9482 5.78134 40.7296 6.14401 40.5696 6.56001C40.4096 6.96534 40.3296 7.40267 40.3296 7.87201Z"
        fill="#18171E"
      />
      <Path
        d="M61.7172 12H60.6292V7.34401C60.6292 6.37334 60.4745 5.65867 60.1652 5.20001C59.8559 4.74134 59.3972 4.51201 58.7892 4.51201C58.4692 4.51201 58.1652 4.57067 57.8772 4.68801C57.5999 4.80534 57.3385 4.97067 57.0932 5.18401C56.8585 5.39734 56.6505 5.65334 56.4692 5.95201C56.2879 6.24001 56.1492 6.56001 56.0532 6.91201V12H54.9652V7.34401C54.9652 6.35201 54.8159 5.63201 54.5172 5.18401C54.2185 4.73601 53.7599 4.51201 53.1412 4.51201C52.5119 4.51201 51.9519 4.73067 51.4612 5.16801C50.9705 5.60534 50.6185 6.18134 50.4052 6.89601V12H49.3172V3.66401H50.3092V5.55201C50.6505 4.91201 51.0985 4.41601 51.6532 4.06401C52.2079 3.70134 52.8212 3.52001 53.4932 3.52001C54.1972 3.52001 54.7572 3.71734 55.1732 4.11201C55.5999 4.50667 55.8612 5.02401 55.9572 5.66401C56.7359 4.23467 57.8079 3.52001 59.1732 3.52001C59.6425 3.52001 60.0372 3.60534 60.3572 3.77601C60.6879 3.94667 60.9492 4.19201 61.1412 4.51201C61.3439 4.82134 61.4879 5.20001 61.5732 5.64801C61.6692 6.08534 61.7172 6.57601 61.7172 7.12001V12Z"
        fill="#18171E"
      />
      <Path
        d="M67.6203 12.16C67.0123 12.16 66.4523 12.048 65.9403 11.824C65.439 11.5893 65.0017 11.2747 64.6283 10.88C64.255 10.4747 63.9617 10.0107 63.7483 9.48801C63.5457 8.96534 63.4443 8.40534 63.4443 7.80801C63.4443 7.22134 63.5457 6.67201 63.7483 6.16001C63.9617 5.63734 64.2497 5.18401 64.6123 4.80001C64.9857 4.40534 65.4283 4.09601 65.9403 3.87201C66.4523 3.63734 67.007 3.52001 67.6043 3.52001C68.2123 3.52001 68.767 3.63734 69.2683 3.87201C69.7803 4.09601 70.2177 4.40534 70.5803 4.80001C70.943 5.19467 71.2257 5.64801 71.4283 6.16001C71.631 6.67201 71.7323 7.21601 71.7323 7.79201C71.7323 7.87734 71.7323 7.96267 71.7323 8.04801C71.7323 8.13334 71.727 8.19201 71.7163 8.22401H64.5803C64.6123 8.67201 64.7137 9.08801 64.8843 9.47201C65.0657 9.84534 65.295 10.1707 65.5723 10.448C65.8497 10.7253 66.1643 10.944 66.5163 11.104C66.879 11.2533 67.263 11.328 67.6683 11.328C67.935 11.328 68.2017 11.2907 68.4683 11.216C68.735 11.1413 68.9803 11.04 69.2043 10.912C69.4283 10.784 69.631 10.6293 69.8123 10.448C69.9937 10.256 70.1323 10.048 70.2283 9.82401L71.1723 10.08C71.0443 10.3893 70.863 10.672 70.6283 10.928C70.3937 11.1733 70.1217 11.392 69.8123 11.584C69.503 11.7653 69.1617 11.904 68.7883 12C68.415 12.1067 68.0257 12.16 67.6203 12.16ZM70.6923 7.39201C70.6603 6.94401 70.5537 6.53867 70.3723 6.17601C70.2017 5.80267 69.9777 5.48267 69.7003 5.21601C69.4337 4.94934 69.119 4.74134 68.7563 4.59201C68.4043 4.44267 68.0257 4.36801 67.6203 4.36801C67.215 4.36801 66.831 4.44267 66.4683 4.59201C66.1057 4.74134 65.7857 4.94934 65.5083 5.21601C65.2417 5.48267 65.023 5.80267 64.8523 6.17601C64.6923 6.54934 64.5963 6.95467 64.5643 7.39201H70.6923Z"
        fill="#18171E"
      />
      <Path
        d="M81.4891 11.6C81.4251 11.632 81.3291 11.6747 81.2011 11.728C81.0837 11.7813 80.9451 11.8347 80.7851 11.888C80.6251 11.9413 80.4437 11.9893 80.2411 12.032C80.0384 12.0747 79.8251 12.096 79.6011 12.096C79.3664 12.096 79.1424 12.064 78.9291 12C78.7157 11.936 78.5291 11.84 78.3691 11.712C78.2091 11.5733 78.0811 11.4027 77.9851 11.2C77.8891 10.9973 77.8411 10.7627 77.8411 10.496V4.52801H76.6891V3.66401H77.8411V0.848007H78.9291V3.66401H80.8491V4.52801H78.9291V10.192C78.9504 10.5013 79.0571 10.7307 79.2491 10.88C79.4517 11.0187 79.6811 11.088 79.9371 11.088C80.2464 11.088 80.5184 11.04 80.7531 10.944C80.9877 10.8373 81.1371 10.7627 81.2011 10.72L81.4891 11.6Z"
        fill="#18171E"
      />
      <Path
        d="M86.1973 12.16C85.5893 12.16 85.0347 12.048 84.5333 11.824C84.032 11.5893 83.5947 11.2747 83.2213 10.88C82.8587 10.4853 82.576 10.0267 82.3733 9.50401C82.1707 8.98134 82.0693 8.43201 82.0693 7.85601C82.0693 7.26934 82.1707 6.71467 82.3733 6.19201C82.5867 5.66934 82.8747 5.21067 83.2373 4.81601C83.6107 4.42134 84.048 4.10667 84.5493 3.87201C85.0507 3.63734 85.6 3.52001 86.1973 3.52001C86.7947 3.52001 87.344 3.63734 87.8453 3.87201C88.3573 4.10667 88.7947 4.42134 89.1573 4.81601C89.5307 5.21067 89.8187 5.66934 90.0213 6.19201C90.2347 6.71467 90.3413 7.26934 90.3413 7.85601C90.3413 8.43201 90.24 8.98134 90.0373 9.50401C89.8347 10.0267 89.5467 10.4853 89.1733 10.88C88.8107 11.2747 88.3733 11.5893 87.8613 11.824C87.36 12.048 86.8053 12.16 86.1973 12.16ZM83.1733 7.87201C83.1733 8.34134 83.2533 8.77867 83.4133 9.18401C83.5733 9.57867 83.7867 9.93067 84.0533 10.24C84.3307 10.5387 84.6507 10.7733 85.0133 10.944C85.3867 11.1147 85.7813 11.2 86.1973 11.2C86.6133 11.2 87.0027 11.1147 87.3653 10.944C87.7387 10.7627 88.064 10.5227 88.3413 10.224C88.6187 9.91467 88.8373 9.55734 88.9973 9.15201C89.1573 8.74667 89.2373 8.30934 89.2373 7.84001C89.2373 7.38134 89.1573 6.94934 88.9973 6.54401C88.8373 6.12801 88.6187 5.77067 88.3413 5.47201C88.064 5.16267 87.7387 4.92267 87.3653 4.75201C87.0027 4.57067 86.6133 4.48001 86.1973 4.48001C85.7813 4.48001 85.392 4.57067 85.0293 4.75201C84.6667 4.92267 84.3467 5.16267 84.0693 5.47201C83.792 5.78134 83.5733 6.14401 83.4133 6.56001C83.2533 6.96534 83.1733 7.40267 83.1733 7.87201Z"
        fill="#18171E"
      />
      <Path
        d="M92.337 13.296L92.609 11.952H92.241V10.432H93.297V11.952L92.8969 13.296H92.337Z"
        fill="#18171E"
      />
    </Svg>
  );
};

export default WelcomeText1;
