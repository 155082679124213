/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const LocationPinFilledIcon = () => {
  return (
    <Svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M12 18.9412C10.414 18.9412 8.89307 18.3112 7.77165 17.1898C6.65023 16.0683 6.02022 14.5474 6.02022 12.9614C6.02022 11.3755 6.65023 9.85454 7.77165 8.73312C8.89307 7.6117 10.414 6.98169 12 6.98169C13.5859 6.98169 15.1069 7.6117 16.2283 8.73312C17.3497 9.85454 17.9797 11.3755 17.9797 12.9614C17.9797 14.5474 17.3497 16.0683 16.2283 17.1898C15.1069 18.3112 13.5859 18.9412 12 18.9412Z"
        fill="#FFD54F"
      />
      <Path
        d="M20.4852 4.46564C18.2348 2.21521 15.1826 0.950928 12 0.950928C8.81739 0.950928 5.76514 2.21521 3.51471 4.46564C1.26428 6.71607 0 9.76831 0 12.9509C0 16.1335 1.26428 19.1857 3.51471 21.4362L11.0014 28.9228C11.1321 29.0546 11.2877 29.1592 11.4591 29.2306C11.6305 29.302 11.8143 29.3388 12 29.3388C12.1856 29.3388 12.3695 29.302 12.5409 29.2306C12.7123 29.1592 12.8678 29.0546 12.9986 28.9228L20.4852 21.4362C21.5996 20.3219 22.4835 18.999 23.0865 17.5431C23.6896 16.0872 24 14.5268 24 12.9509C24 11.375 23.6896 9.81461 23.0865 8.3587C22.4835 6.9028 21.5996 5.57993 20.4852 4.46564ZM12 18.9411C10.414 18.9411 8.89307 18.3111 7.77165 17.1897C6.65023 16.0683 6.02023 14.5473 6.02023 12.9614C6.02023 11.3754 6.65023 9.85447 7.77165 8.73305C8.89307 7.61163 10.414 6.98162 12 6.98162C13.5859 6.98162 15.1069 7.61163 16.2283 8.73305C17.3497 9.85447 17.9797 11.3754 17.9797 12.9614C17.9797 14.5473 17.3497 16.0683 16.2283 17.1897C15.1069 18.3111 13.5859 18.9411 12 18.9411Z"
        fill="#142040"
      />
    </Svg>
  );
};

export default LocationPinFilledIcon;
