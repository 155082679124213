import AsyncStorage from '@react-native-community/async-storage';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Platform } from 'react-native';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { Config } from '../constants/Config';
import { backendApi } from '../consumer/api/base.config';
import rootReducer from './Reducers';
// import { apiMiddleware } from 'redux-api-middleware';
const middlewares = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
});

const persistConfig = {
  key: Config.PERSIST_ROOT_STORAGE,
  storage: AsyncStorage,
  whitelist: Config.PERSIST_DATA,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

if ((Platform.OS === 'ios' || Platform.OS === 'android') && __DEV__) {
  const createDebugger = require('redux-flipper').default;
  middlewares.push(createDebugger());
}
middlewares.push(backendApi.middleware);

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
});
export const persistor = persistStore(store);

export default store;
