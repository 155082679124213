/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const WelcomeText2 = () => {
  return (
    <Svg
      width="185"
      height="20"
      viewBox="0 0 185 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M0.953491 15V0.799996H7.13349C7.77349 0.799996 8.36016 0.93333 8.89349 1.2C9.42682 1.46666 9.88682 1.82 10.2735 2.26C10.6735 2.7 10.9802 3.19333 11.1935 3.74C11.4202 4.28666 11.5335 4.84666 11.5335 5.42C11.5335 6.04666 11.4202 6.64666 11.1935 7.22C10.9668 7.79333 10.6402 8.28666 10.2135 8.7C9.80016 9.11333 9.31349 9.41333 8.75349 9.6L12.0935 15H9.57349L6.49349 10.06H3.19349V15H0.953491ZM3.19349 8.08H7.11349C7.52682 8.08 7.89349 7.96 8.21349 7.72C8.53349 7.48 8.78682 7.16 8.97349 6.76C9.16016 6.34666 9.25349 5.9 9.25349 5.42C9.25349 4.92666 9.14682 4.48666 8.93349 4.1C8.72016 3.7 8.44016 3.38 8.09349 3.14C7.76016 2.9 7.39349 2.78 6.99349 2.78H3.19349V8.08Z"
        fill="black"
      />
      <Path
        d="M18.3041 15.2C17.4908 15.2 16.7508 15.06 16.0841 14.78C15.4308 14.4867 14.8641 14.0933 14.3841 13.6C13.9041 13.0933 13.5308 12.52 13.2641 11.88C13.0108 11.2267 12.8841 10.5333 12.8841 9.8C12.8841 8.81333 13.1108 7.91333 13.5641 7.1C14.0174 6.27333 14.6508 5.61333 15.4641 5.12C16.2775 4.61333 17.2308 4.36 18.3241 4.36C19.4174 4.36 20.3574 4.61333 21.1441 5.12C21.9441 5.61333 22.5641 6.26666 23.0041 7.08C23.4441 7.89333 23.6641 8.76666 23.6641 9.7C23.6641 9.86 23.6574 10.0133 23.6441 10.16C23.6308 10.2933 23.6174 10.4067 23.6041 10.5H15.2241C15.2641 11.1133 15.4308 11.6533 15.7241 12.12C16.0308 12.5733 16.4174 12.9333 16.8841 13.2C17.3508 13.4533 17.8508 13.58 18.3841 13.58C18.9708 13.58 19.5241 13.4333 20.0441 13.14C20.5774 12.8467 20.9375 12.46 21.1241 11.98L23.0041 12.52C22.7774 13.0267 22.4308 13.4867 21.9641 13.9C21.5108 14.3 20.9708 14.62 20.3441 14.86C19.7174 15.0867 19.0374 15.2 18.3041 15.2ZM15.1641 9.04H21.4841C21.4441 8.44 21.2708 7.91333 20.9641 7.46C20.6708 6.99333 20.2908 6.63333 19.8241 6.38C19.3708 6.11333 18.8641 5.98 18.3041 5.98C17.7574 5.98 17.2508 6.11333 16.7841 6.38C16.3308 6.63333 15.9574 6.99333 15.6641 7.46C15.3708 7.91333 15.2041 8.44 15.1641 9.04Z"
        fill="black"
      />
      <Path
        d="M25.961 15V6.5H24.561V4.8H25.961V4.38C25.961 3.52666 26.0943 2.78666 26.361 2.16C26.641 1.53333 27.0277 1.05333 27.521 0.719996C28.0277 0.37333 28.621 0.199997 29.301 0.199997C29.7277 0.199997 30.1477 0.259997 30.561 0.379997C30.9743 0.486663 31.3677 0.639997 31.741 0.839997L31.261 2.48C31.061 2.34666 30.821 2.24666 30.541 2.18C30.2743 2.1 30.0143 2.06 29.761 2.06C29.2543 2.06 28.861 2.25333 28.581 2.64C28.301 3.02666 28.161 3.58666 28.161 4.32V4.8H30.841V6.5H28.161V15H25.961Z"
        fill="black"
      />
      <Path
        d="M32.3899 10.94V4.54H34.5899V10.5C34.5899 11.4333 34.7566 12.14 35.0899 12.62C35.4366 13.0867 35.9366 13.32 36.5899 13.32C37.0032 13.32 37.4032 13.2333 37.7899 13.06C38.1899 12.8867 38.5499 12.6333 38.8699 12.3C39.2032 11.9533 39.4699 11.54 39.6699 11.06V4.54H41.8699V12.52C41.8699 12.7733 41.9166 12.9533 42.0099 13.06C42.1032 13.1667 42.2566 13.2267 42.4699 13.24V15C42.2432 15.04 42.0566 15.0667 41.9099 15.08C41.7632 15.0933 41.6232 15.1 41.4899 15.1C41.0899 15.1 40.7566 14.9933 40.4899 14.78C40.2232 14.5533 40.0699 14.2667 40.0299 13.92L39.9899 12.88C39.5232 13.64 38.9166 14.22 38.1699 14.62C37.4232 15.0067 36.6032 15.2 35.7099 15.2C34.6166 15.2 33.7899 14.84 33.2299 14.12C32.6699 13.4 32.3899 12.34 32.3899 10.94Z"
        fill="black"
      />
      <Path
        d="M49.4174 15.2C48.6041 15.2 47.8641 15.06 47.1974 14.78C46.5441 14.4867 45.9774 14.0933 45.4974 13.6C45.0174 13.0933 44.6441 12.52 44.3774 11.88C44.1241 11.2267 43.9974 10.5333 43.9974 9.8C43.9974 8.81333 44.2241 7.91333 44.6774 7.1C45.1307 6.27333 45.7641 5.61333 46.5774 5.12C47.3907 4.61333 48.3441 4.36 49.4374 4.36C50.5307 4.36 51.4707 4.61333 52.2574 5.12C53.0574 5.61333 53.6774 6.26666 54.1174 7.08C54.5574 7.89333 54.7774 8.76666 54.7774 9.7C54.7774 9.86 54.7707 10.0133 54.7574 10.16C54.7441 10.2933 54.7307 10.4067 54.7174 10.5H46.3374C46.3774 11.1133 46.5441 11.6533 46.8374 12.12C47.1441 12.5733 47.5307 12.9333 47.9974 13.2C48.4641 13.4533 48.9641 13.58 49.4974 13.58C50.0841 13.58 50.6374 13.4333 51.1574 13.14C51.6907 12.8467 52.0507 12.46 52.2374 11.98L54.1174 12.52C53.8907 13.0267 53.5441 13.4867 53.0774 13.9C52.6241 14.3 52.0841 14.62 51.4574 14.86C50.8307 15.0867 50.1507 15.2 49.4174 15.2ZM46.2774 9.04H52.5974C52.5574 8.44 52.3841 7.91333 52.0774 7.46C51.7841 6.99333 51.4041 6.63333 50.9374 6.38C50.4841 6.11333 49.9774 5.98 49.4174 5.98C48.8707 5.98 48.3641 6.11333 47.8974 6.38C47.4441 6.63333 47.0707 6.99333 46.7774 7.46C46.4841 7.91333 46.3174 8.44 46.2774 9.04Z"
        fill="black"
      />
      <Path
        d="M56.5919 0.399997H58.7919V11.98C58.7919 12.5133 58.8719 12.8533 59.0319 13C59.1919 13.1467 59.3919 13.22 59.6319 13.22C59.8986 13.22 60.1453 13.1933 60.3719 13.14C60.6119 13.0867 60.8186 13.02 60.9919 12.94L61.3119 14.68C60.9919 14.8133 60.6119 14.9267 60.1719 15.02C59.7319 15.1133 59.3386 15.16 58.9919 15.16C58.2453 15.16 57.6586 14.9533 57.2319 14.54C56.8053 14.1133 56.5919 13.5267 56.5919 12.78V0.399997Z"
        fill="black"
      />
      <Path
        d="M80.2626 4.58H81.6026L77.1226 15H75.9426L73.7626 9.82L71.6026 15H70.4226L65.9426 4.58H67.2626L71.0626 13.6L72.9826 8.9L71.2226 4.6H72.4426L73.7626 7.98L75.1026 4.6H76.3026L74.5626 8.9L76.4826 13.6L80.2626 4.58Z"
        fill="black"
      />
      <Path
        d="M83.4121 15V4.58H84.7721V15H83.4121ZM83.4121 2.4V0.399997H84.7721V2.4H83.4121Z"
        fill="black"
      />
      <Path
        d="M92.7676 14.5C92.6609 14.54 92.4942 14.6133 92.2676 14.72C92.0409 14.8267 91.7676 14.92 91.4476 15C91.1276 15.08 90.7809 15.12 90.4076 15.12C90.0209 15.12 89.6542 15.0467 89.3076 14.9C88.9742 14.7533 88.7076 14.5333 88.5076 14.24C88.3076 13.9333 88.2076 13.56 88.2076 13.12V5.66H86.7676V4.58H88.2076V1.06H89.5676V4.58H91.9676V5.66H89.5676V12.74C89.5942 13.1133 89.7276 13.3933 89.9676 13.58C90.2209 13.7667 90.5076 13.86 90.8276 13.86C91.2009 13.86 91.5409 13.8 91.8476 13.68C92.1542 13.5467 92.3409 13.4533 92.4076 13.4L92.7676 14.5Z"
        fill="black"
      />
      <Path
        d="M103.227 15H101.867V9.18C101.867 8.00666 101.66 7.12666 101.247 6.54C100.833 5.94 100.24 5.64 99.4668 5.64C98.9601 5.64 98.4534 5.77333 97.9468 6.04C97.4534 6.29333 97.0134 6.64666 96.6268 7.1C96.2534 7.54 95.9868 8.04666 95.8268 8.62V15H94.4668V0.399997H95.8268V6.94C96.2401 6.16666 96.8134 5.55333 97.5468 5.1C98.2934 4.63333 99.0868 4.4 99.9268 4.4C100.527 4.4 101.033 4.50666 101.447 4.72C101.873 4.93333 102.213 5.24666 102.467 5.66C102.733 6.06 102.927 6.53333 103.047 7.08C103.167 7.61333 103.227 8.22 103.227 8.9V15Z"
        fill="black"
      />
      <Path
        d="M111.325 15V0.799996H117.505C118.145 0.799996 118.731 0.93333 119.265 1.2C119.798 1.46666 120.258 1.82 120.645 2.26C121.045 2.7 121.351 3.19333 121.565 3.74C121.791 4.28666 121.905 4.84666 121.905 5.42C121.905 6.04666 121.791 6.64666 121.565 7.22C121.338 7.79333 121.011 8.28666 120.585 8.7C120.171 9.11333 119.685 9.41333 119.125 9.6L122.465 15H119.945L116.865 10.06H113.565V15H111.325ZM113.565 8.08H117.485C117.898 8.08 118.265 7.96 118.585 7.72C118.905 7.48 119.158 7.16 119.345 6.76C119.531 6.34666 119.625 5.9 119.625 5.42C119.625 4.92666 119.518 4.48666 119.305 4.1C119.091 3.7 118.811 3.38 118.465 3.14C118.131 2.9 117.765 2.78 117.365 2.78H113.565V8.08Z"
        fill="black"
      />
      <Path
        d="M128.675 15.2C127.862 15.2 127.122 15.06 126.455 14.78C125.802 14.4867 125.235 14.0933 124.755 13.6C124.275 13.0933 123.902 12.52 123.635 11.88C123.382 11.2267 123.255 10.5333 123.255 9.8C123.255 8.81333 123.482 7.91333 123.935 7.1C124.389 6.27333 125.022 5.61333 125.835 5.12C126.649 4.61333 127.602 4.36 128.695 4.36C129.789 4.36 130.729 4.61333 131.515 5.12C132.315 5.61333 132.935 6.26666 133.375 7.08C133.815 7.89333 134.035 8.76666 134.035 9.7C134.035 9.86 134.029 10.0133 134.015 10.16C134.002 10.2933 133.989 10.4067 133.975 10.5H125.595C125.635 11.1133 125.802 11.6533 126.095 12.12C126.402 12.5733 126.789 12.9333 127.255 13.2C127.722 13.4533 128.222 13.58 128.755 13.58C129.342 13.58 129.895 13.4333 130.415 13.14C130.949 12.8467 131.309 12.46 131.495 11.98L133.375 12.52C133.149 13.0267 132.802 13.4867 132.335 13.9C131.882 14.3 131.342 14.62 130.715 14.86C130.089 15.0867 129.409 15.2 128.675 15.2ZM125.535 9.04H131.855C131.815 8.44 131.642 7.91333 131.335 7.46C131.042 6.99333 130.662 6.63333 130.195 6.38C129.742 6.11333 129.235 5.98 128.675 5.98C128.129 5.98 127.622 6.11333 127.155 6.38C126.702 6.63333 126.329 6.99333 126.035 7.46C125.742 7.91333 125.575 8.44 125.535 9.04Z"
        fill="black"
      />
      <Path
        d="M140.57 15.2C139.756 15.2 139.016 15.06 138.35 14.78C137.696 14.4867 137.13 14.0867 136.65 13.58C136.17 13.0733 135.796 12.4933 135.53 11.84C135.276 11.1867 135.15 10.4933 135.15 9.76C135.15 8.77333 135.37 7.87333 135.81 7.06C136.263 6.24666 136.89 5.59333 137.69 5.1C138.503 4.60666 139.463 4.36 140.57 4.36C141.636 4.36 142.563 4.6 143.35 5.08C144.15 5.54666 144.736 6.17333 145.11 6.96L142.97 7.64C142.73 7.2 142.39 6.86 141.95 6.62C141.523 6.36666 141.05 6.24 140.53 6.24C139.943 6.24 139.41 6.39333 138.93 6.7C138.463 6.99333 138.09 7.40666 137.81 7.94C137.543 8.46 137.41 9.06666 137.41 9.76C137.41 10.4267 137.55 11.0333 137.83 11.58C138.11 12.1133 138.483 12.54 138.95 12.86C139.43 13.1667 139.963 13.32 140.55 13.32C140.91 13.32 141.256 13.26 141.59 13.14C141.936 13.0067 142.236 12.8333 142.49 12.62C142.743 12.3933 142.923 12.1467 143.03 11.88L145.19 12.52C144.963 13.04 144.623 13.5 144.17 13.9C143.73 14.3 143.203 14.62 142.59 14.86C141.99 15.0867 141.316 15.2 140.57 15.2Z"
        fill="black"
      />
      <Path
        d="M147.166 17.46C147.366 17.4867 147.56 17.5133 147.746 17.54C147.946 17.58 148.106 17.6 148.226 17.6C148.453 17.6 148.646 17.5267 148.806 17.38C148.98 17.2467 149.153 16.9933 149.326 16.62C149.5 16.2467 149.713 15.7067 149.966 15L145.666 4.54H147.966L151.166 12.76L154.006 4.54H156.126L151.386 17.42C151.24 17.8067 151.026 18.16 150.746 18.48C150.466 18.8133 150.12 19.0733 149.706 19.26C149.293 19.4467 148.806 19.54 148.246 19.54C148.086 19.54 147.92 19.5267 147.746 19.5C147.573 19.4733 147.38 19.4333 147.166 19.38V17.46Z"
        fill="black"
      />
      <Path
        d="M165.024 15L161.664 10.18L159.944 11.82V15H157.744V0.399997H159.944V9.6L164.784 4.56H167.144L163.084 8.94L167.364 15H165.024Z"
        fill="black"
      />
      <Path
        d="M167.902 11.94C167.902 11.2733 168.088 10.6933 168.462 10.2C168.848 9.69333 169.375 9.30666 170.042 9.04C170.708 8.76 171.482 8.62 172.362 8.62C172.828 8.62 173.302 8.65333 173.782 8.72C174.275 8.78666 174.708 8.89333 175.082 9.04V8.38C175.082 7.64666 174.862 7.07333 174.422 6.66C173.982 6.24666 173.348 6.04 172.522 6.04C171.935 6.04 171.382 6.14666 170.862 6.36C170.342 6.56 169.788 6.84666 169.202 7.22L168.462 5.74C169.155 5.27333 169.848 4.92666 170.542 4.7C171.248 4.47333 171.988 4.36 172.762 4.36C174.162 4.36 175.262 4.73333 176.062 5.48C176.875 6.21333 177.282 7.26 177.282 8.62V12.52C177.282 12.7733 177.322 12.9533 177.402 13.06C177.495 13.1667 177.648 13.2267 177.862 13.24V15C177.662 15.04 177.482 15.0667 177.322 15.08C177.162 15.0933 177.028 15.1 176.922 15.1C176.455 15.1 176.102 14.9867 175.862 14.76C175.622 14.5333 175.482 14.2667 175.442 13.96L175.382 13.36C174.928 13.9467 174.348 14.4 173.642 14.72C172.935 15.04 172.222 15.2 171.502 15.2C170.808 15.2 170.188 15.06 169.642 14.78C169.095 14.4867 168.668 14.0933 168.362 13.6C168.055 13.1067 167.902 12.5533 167.902 11.94ZM174.562 12.58C174.722 12.4067 174.848 12.2333 174.942 12.06C175.035 11.8867 175.082 11.7333 175.082 11.6V10.4C174.708 10.2533 174.315 10.1467 173.902 10.08C173.488 10 173.082 9.96 172.682 9.96C171.882 9.96 171.228 10.12 170.722 10.44C170.228 10.76 169.982 11.2 169.982 11.76C169.982 12.0667 170.062 12.36 170.222 12.64C170.395 12.92 170.635 13.1467 170.942 13.32C171.262 13.4933 171.655 13.58 172.122 13.58C172.602 13.58 173.062 13.4867 173.502 13.3C173.942 13.1133 174.295 12.8733 174.562 12.58Z"
        fill="black"
      />
      <Path
        d="M179.971 0.399997H182.171V11.98C182.171 12.5133 182.251 12.8533 182.411 13C182.571 13.1467 182.771 13.22 183.011 13.22C183.277 13.22 183.524 13.1933 183.751 13.14C183.991 13.0867 184.197 13.02 184.371 12.94L184.691 14.68C184.371 14.8133 183.991 14.9267 183.551 15.02C183.111 15.1133 182.717 15.16 182.371 15.16C181.624 15.16 181.037 14.9533 180.611 14.54C180.184 14.1133 179.971 13.5267 179.971 12.78V0.399997Z"
        fill="black"
      />
    </Svg>
  );
};

export default WelcomeText2;
