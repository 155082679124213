import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { BottomSheet, Button, Icon } from 'react-native-elements';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import { fontPixel } from '../../utils/normalizeSize';
import Section from './Section';

const AppBottomSheet = (props) => {
  const {
    isVisible,
    setIsVisible,
    header,
    title,
    children,
    cancelTitle,
    submitTitle,
    onCancel,
    onSubmit,
    canClose,
    headerClose,
    loading,
    closeOnSubmit,
    disabled,
  } = props;

  return (
    <BottomSheet
      isVisible={isVisible}
      containerStyle={styles.root}
      onRequestClose={() => {
        setIsVisible(!isVisible);
      }}>
      <View>
        <Section
          title={header || title}
          containerStyle={{ backgroundColor: Colors.white }}
          rightComponent={
            canClose ? (
              <View style={[styles.iconWrapper]}>
                <TouchableOpacity onPress={() => setIsVisible(false)}>
                  <Icon type="material" size={20} name="cancel" />
                </TouchableOpacity>
              </View>
            ) : null
          }>
          {children}
          <View style={styles.footer}>
            {cancelTitle && (
              <Button
                title={cancelTitle}
                buttonStyle={[
                  {
                    backgroundColor: '#dcdce3',
                    borderWidth: 1,
                    borderColor: '#707070',
                  },
                ]}
                titleStyle={[{ color: Colors.dark }]}
                containerStyle={[styles.leftButton]}
                onPress={() => onCancel(false)}
              />
            )}
            {submitTitle && (
              <Button
                title={submitTitle}
                loading={loading}
                disabled={disabled}
                titleStyle={[styles.confirmButtonStyles]}
                containerStyle={styles.rightButton}
                onPress={() => {
                  closeOnSubmit && setIsVisible(false);
                  onSubmit(false);
                }}
              />
            )}
          </View>
        </Section>
      </View>
    </BottomSheet>
  );
};

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftButton: {
    paddingRight: 10,
    flex: 1,
  },
  rightButton: {
    flex: 1,
  },
  confirmButtonStyles: {
    fontFamily: 'fontBold',
    fontSize: fontPixel(12),
  },
  container: {
    marginBottom: 0,
  },
  root: {
    backgroundColor: 'rgba(87, 87, 111, 0.4)',
    padding: DIM.screenPadding,
    paddingBottom: 0,
  },
});

export default AppBottomSheet;
