import LocalizedStrings from 'react-localization';

const en = require('../assets/i18n/en.json');
const hi = require('../assets/i18n/hi.json');
const te = require('../assets/i18n/te.json');
const mr = require('../assets/i18n/mr.json');
const kn = require('../assets/i18n/ka.json');

export const LocalStrings = new LocalizedStrings({
  en,
  hi,
  te,
  mr,
  kn,
});
