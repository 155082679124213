import { createSlice } from '@reduxjs/toolkit';
import { AUTHORITY } from '../../constants/Enums';

const initialUser = {
  profileDetails: null,
  accountDetails: null,
  recyclerDetails: null,
  deviceInfo: {},
  userPolicy: {},
  centerSupervisors: [],
  attendanceSupervisors: [],
  users: [],
};

const UserSlice = createSlice({
  name: 'user',
  initialState: initialUser,
  reducers: {
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setRecyclerDetails: (state, action) => {
      state.recyclerDetails = action.payload;
    },
    setDeviceInfo: (state, action) => {
      state.deviceInfo = action.payload;
    },
    setUserPolicy: (state, action) => {
      state.userPolicy = action.payload;
    },
    setUsers: (state, action) => {
      const checkCenterRole = ({ authorities }) =>
        authorities.findIndex(({ name }) => name === AUTHORITY.CENTER_SUPERVISOR) >= 0;
      const checkAttendanceRole = ({ authorities }) =>
        authorities.findIndex(({ name }) => name === AUTHORITY.ATTENDANCE_SUPERVISOR) >= 0;
      const users = action.payload;
      state.centerSupervisors = users?.filter(checkCenterRole) || [];
      state.attendanceSupervisors = users?.filter(checkAttendanceRole) || [];
      state.users = action.payload;
    },
  },
});
export const { actions } = UserSlice;
export default UserSlice.reducer;
