import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import React from 'react';
import MyText from '../../shared/components/MyText';
import { FONT } from '../../theme/font';

const ItemCreditLedger = ({ iconComponent, iconText, itemCount = '', itemValue = '' }) => {
  return (
    <View
      style={{
        background: 'linear-gradient(305.69deg, #02CAFD -83.13%, #83F8A6 122.29%)',
        borderRadius: 8,
        flexDirection: 'row',
        height: 100,
        padding: 8,
      }}>
      <View
        style={{
          paddingHorizontal: 10,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {iconComponent}
        <MyText color="#325E51" size={10} height={18} font={FONT.w500} center>
          {iconText}
        </MyText>
      </View>
      <View
        style={{
          background: '#325E51',
          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
          paddingVertical: 24,
          paddingHorizontal: 16,
          borderTopEndRadius: 8,
          borderBottomEndRadius: 8,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <MyText font={FONT.w600} size={12} color="#FFFFFF" height={20}>
          {itemCount}
        </MyText>
        <MyText font={FONT.w600} size={12} color="#FFD54F" height={20}>
          <MyText font={FONT.w600} size={12} color="#FFFFFF" height={20}>
            =
          </MyText>{' '}
          {itemValue}
        </MyText>
      </View>
    </View>
  );
};

ItemCreditLedger.propTypes = {
  iconComponent: PropTypes.any,
  iconText: PropTypes.any,
  itemCount: PropTypes.string,
  itemValue: PropTypes.string,
};

export default ItemCreditLedger;

const styles = StyleSheet.create({});
