import { useFormik } from 'formik';
import { isUndefined } from 'lodash';
import React, { useState } from 'react';
import { Image, View } from 'react-native';
import { Button, Divider, Header, Icon, Input } from 'react-native-elements';
import * as Yup from 'yup';
import { CROUTES } from '../../constants/Routes';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import Footer from '../../shared/components/Footer';
import LoaderOverlay from '../../shared/components/LoaderOverlay';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { Colors } from '../../theme/Colors';
import { FONT } from '../../theme/font';
import {
  useGetLocationByIdMutation,
  useGetRedeemBalanceMutation,
} from '../api/consumer-app/consumer.api';
import useConsumer from '../api/consumer.hook';
import SelectFuel from '../components/SelectFuel';

import NoCreditsPopup from '../components/NoCreditsPopup';
import { toQuantity } from '../../utils/Utils';

const companyLogo = require('../../assets/svg/Consumer/consumer_header_img.svg');

const EnterTotalBillScreen = ({ navigation, route }) => {
  const { activeFuelType, userDetails, token, getFuelConversionPoint } = useConsumer();
  const { balancePoints } = userDetails;
  const [getLocationDetails, { data, isLoading }] = useGetLocationByIdMutation();
  const [getRedeemBalance, { isLoading: gettingBalance }] = useGetRedeemBalanceMutation();

  const [showNoCreditPopup, setShowNoCreditPopup] = useState(false);

  const conversionPoint = getFuelConversionPoint(balancePoints);

  const form = useFormik({
    initialValues: {
      billAmount: '',
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      billAmount: Yup.string()
        // .matches(/^[0-9\b]+$/, 'Please Enter Numeric Character')
        .required(''),
    }),
    onSubmit: (values) => {
      saveDetails(values);
    },
  });

  useAsyncEffect(async () => {
    const { params } = route;
    const binQr = params?.id;
    const _isUndefined = binQr === 'undefined' || isUndefined(binQr);
    if (_isUndefined) return;
    try {
      const _rvmDetails = await getLocationDetails(binQr).unwrap();
      console.log(
        '🚀 ~ file: EnterTotalBill.screen.js:57 ~ useAsyncEffect ~ _rvmDetails:',
        _rvmDetails
      );
    } catch (error) {
      console.log('🚀 ~ file: BottleRvmHome.screen.js:69 ~ useAsyncEffect ~ error:', error);
      //
    }
  }, [route.params]);

  const saveDetails = async (_data) => {
    const payload = {
      amount: _data?.billAmount,
      redeemedFor: activeFuelType,
      locationId: _data?.id,
      displayId: route.params?.id,
    };
    try {
      const result = await getRedeemBalance(payload).unwrap();
      navigation.navigate(CROUTES.CONFIRM_REDEMPTION, { billDetails: result, payload });
    } catch (error) {
      // toast.show('Problem connecting server', { type: 'error' });
    }
  };

  if (!token) {
    navigation.reset({
      index: 0,
      routes: [
        { name: CROUTES.HOME, params: { id: route.params?.id, toscreen: CROUTES.SCAN_TO_PAY } },
      ],
    });
    return null;
  }

  const handleBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: CROUTES.HOME, params: { id: route.params?.id } }],
      });
    }
  };

  const toggleShowNoCreditPopup = () => {
    setShowNoCreditPopup(!showNoCreditPopup);
  };

  const onClickRedeemCredit = () => {
    conversionPoint?.value && conversionPoint?.value > 0
      ? form.handleSubmit()
      : toggleShowNoCreditPopup();
  };

  return (
    <View>
      <Header
        backgroundColor={Colors.clear}
        placement="center"
        leftComponent={() => (
          <Icon
            onPress={handleBack}
            style={{ alignSelf: 'center' }}
            type="material"
            size={30}
            name="keyboard-backspace"
            color="#000"
          />
        )}
        centerComponent={{
          text: data?.name,
          style: { fontSize: 16 },
        }}
        containerStyle={{ height: 80 }}
      />
      <LoaderOverlay loading={isLoading} />
      <View style={{ paddingHorizontal: 40 }}>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 30,
            }}>
            <Image style={{ width: 190, height: 60 }} source={companyLogo} />
          </View>
          <SelectFuel />
          <Spacing size={40} />
        </View>
        <View>
          <View style={{ paddingVertical: 10 }}>
            <MyText size={16} color="#484848" font={FONT.w600} center>
              Enter Total Bill Amount
            </MyText>
            <MyText size={10} color="#484848" font={FONT.w400} center>
              The value of the Petrol you want to fill at the station
            </MyText>
          </View>
          <Input
            keyboardType="numeric"
            autoFocus
            type="number"
            placeholder="Enter Amount"
            onChangeText={form.handleChange('billAmount')}
            value={form?.values?.billAmount}
            errorMessage={form.errors.billAmount}
            inputStyle={[{ fontSize: 22, fontFamily: FONT.w700 }]}
            inputContainerStyle={[
              {
                backgroundColor: '#F8F8F8',
                borderRadius: 15,
                borderColor: '#A1CCBF',
                borderWidth: 1,
                height: 70,
              },
            ]}
            leftIcon={() => (
              <View style={{ flexDirection: 'row', marginLeft: 15 }}>
                <MyText font={FONT.w700} size={22} hpad={10} color="#484848">
                  ₹
                </MyText>
                <Divider orientation="vertical" style={{ marginLeft: 8 }} />
              </View>
            )}
          />
          <MyText size={12} color={Colors.eyeGrey} height={0} center>
            Available Credits: {toQuantity(conversionPoint?.value, 2)}
            {conversionPoint?.units || 'ml'}
          </MyText>
        </View>
        <Spacing size={40} />
        <Footer>
          <Button
            title="Redeem Credits"
            isLoading={gettingBalance}
            disabled={!form.isValid}
            onPress={onClickRedeemCredit}
          />
        </Footer>
      </View>
      <NoCreditsPopup visible={showNoCreditPopup} toggleOverlay={toggleShowNoCreditPopup} />
    </View>
  );
};
export default EnterTotalBillScreen;
