import React from 'react';
import { Image, View } from 'react-native';
import LocationPinIcon from '../../assets/svg/LocationPinIcon';
import CanShow from '../../shared/components/CanShow';
import MyText from '../../shared/components/MyText';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import { FONT } from '../../theme/font';
import useConsumer from '../api/consumer.hook';

const companyLogo = require('../../assets/svg/Consumer/consumer_header_img.svg');

const ConsumerHeader = ({ background = Colors.white }) => {
  const { userDetails } = useConsumer();

  return (
    <View
      style={{
        backgroundColor: background,
        height: DIM.headerHeight,
        justifyContent: 'center',
      }}>
      <View
        style={{
          flexDirection: 'row',
          display: 'flex',
          marginVertical: 0,
        }}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginLeft: 22,
          }}>
          <Image style={{ width: 97, height: 30 }} source={companyLogo} />
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'flex-end',
            padding: 7.5,
            marginRight: 22,
          }}>
          <CanShow
            show={userDetails?.mobileNumber}
            fallbackComponent={
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <View style={{ marginRight: 5 }}>
                  <MyText font={FONT.w500} color="#57576F" height={18}>
                    Hyderabad
                  </MyText>
                </View>
                <LocationPinIcon />
              </View>
            }>
            <View style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
              <MyText size={8} color="#57576F">
                Mobile No
              </MyText>
              <MyText size={16} color="#57576F">
                {userDetails?.mobileNumber || ''}
              </MyText>
            </View>
          </CanShow>
        </View>
      </View>
    </View>
  );
};

export default ConsumerHeader;
