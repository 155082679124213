/* eslint-disable max-len */
import React from 'react';
import Svg, { Circle, Defs, LinearGradient, Path } from 'react-native-svg';

function InvalidItem(props) {
  return (
    <Svg width="71" height="53" viewBox="0 0 71 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="44.5" cy="26.5" r="26.5" fill="#FFF2DE" />
      <Circle cx="45.5" cy="28.5" r="17.5" fill="#FFC3B0" />
      <Path
        d="M61 12L39 35"
        stroke="#FF6256"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M53.5312 34.5312L39.4688 20.4688"
        stroke="#FF6256"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect y="15" width="31.7368" height="36" rx="8" fill="url(#paint0_linear_485_11300)" />
      <Path
        d="M16.1054 39.1575C19.2447 39.1575 21.7896 36.6126 21.7896 33.4733C21.7896 30.334 19.2447 27.7891 16.1054 27.7891C12.966 27.7891 10.4211 30.334 10.4211 33.4733C10.4211 36.6126 12.966 39.1575 16.1054 39.1575Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.1053 37.2628C16.4977 37.2628 16.8158 36.9447 16.8158 36.5523C16.8158 36.1599 16.4977 35.8418 16.1053 35.8418C15.7129 35.8418 15.3948 36.1599 15.3948 36.5523C15.3948 36.9447 15.7129 37.2628 16.1053 37.2628Z"
        fill="white"
      />
      <Path
        d="M16.1054 34.4208V33.9471C16.4333 33.9471 16.7538 33.8499 17.0265 33.6677C17.2991 33.4856 17.5116 33.2266 17.6371 32.9237C17.7626 32.6208 17.7954 32.2874 17.7314 31.9658C17.6675 31.6442 17.5096 31.3488 17.2777 31.1169C17.0459 30.8851 16.7504 30.7272 16.4288 30.6632C16.1072 30.5992 15.7739 30.6321 15.471 30.7575C15.168 30.883 14.9091 31.0955 14.7269 31.3682C14.5447 31.6408 14.4475 31.9613 14.4475 32.2892"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_485_11300"
          x1="3.47837"
          y1="55.2167"
          x2="41.2833"
          y2="34.0867"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#160A38" />
          <stop offset="1" stopColor="#67766B" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default InvalidItem;
