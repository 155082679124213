import React from 'react';
import { Platform, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { useDispatch } from 'react-redux';
import ListRowItem from '../../features/ListRowItem';
import MyText from '../../shared/components/MyText';
import SelectDropdown from '../../shared/components/SelectDropdown/SelectDropdown';
import { Colors } from '../../theme/Colors';
import GlobalStyles from '../../theme/GlobalStyles';
import { FONT } from '../../theme/font';
import { setActiveFuelType } from '../api/consumer-app/consumer.slice';
import useConsumer from '../api/consumer.hook';
import useAppConfig from '../../hooks/useAppConfig';

/**
 * This is a React component that renders a dropdown menu to select a fuel type.
 * @returns A React component that renders a view containing a text and a dropdown list to select a
 * fuel type. The dropdown list is implemented using the `SelectDropdown` component and contains two
 * options: "Petrol" and "Diesel". The selected fuel type is stored in the `fuel` state variable and
 * updated using the `setFuel` function passed as a prop. The view has some styling applied to
 */
const SelectFuel = ({ onChange = () => undefined }) => {
  const dispatch = useDispatch();
  const { activeFuelType, config = {} } = useConsumer();
  const { appConfig } = useAppConfig();
  console.log('🚀 ~ file: SelectFuel.js:26 ~ SelectFuel ~ appConfig:', appConfig);

  const handleChange = (fuelType) => {
    onChange(fuelType?.id);
    dispatch(setActiveFuelType(fuelType.id));
  };

  const redeemTypes = config?.redeemTypes || appConfig?.fuelTypes;

  return (
    <View
      style={[
        GlobalStyles.flexRow,
        {
          ...(Platform.OS === 'android'
            ? { backgroundColor: '#160A38' }
            : { background: 'linear-gradient(57.63deg, #433F5F 2.16%, #67766B 97.84%)' }),
          borderRadius: 8,
          paddingHorizontal: 8,
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          alignSelf: 'center',
        },
      ]}>
      <View style={{ flex: 0.55, paddingLeft: 15 }}>
        <MyText font={FONT.w300} color={Colors.white} size={14}>
          Select Fuel Type
        </MyText>
      </View>
      <View style={{ flex: 0.45 }}>
        <SelectDropdown
          value={redeemTypes?.find(({ id }) => activeFuelType === id)?.label}
          bottomSheet
          modalTitle="Fuel Type"
          listData={redeemTypes}
          inputProps={{
            containerStyle: [{ height: Platform.OS === 'web' ? 30 : 26 }],
            inputStyle: [{ color: 'white', fontSize: 14 }],
            inputContainerStyle: [
              {
                backgroundColor: '#049869',
                ...(Platform.OS === 'web'
                  ? {
                      height: 'inherit',
                      minHeight: 'none',
                    }
                  : {
                      height: 26,
                      minHeight: 26,
                      borderRadius: 6,
                    }),
              },
            ],
          }}
          renderItem={({ item }) => (
            <ListRowItem
              onSelect={() => handleChange(item)}
              title={item?.label}
              rightIcon={<Icon name="check-circle" color={Colors.primary} />}
              checked={activeFuelType === item?.id}
              containerStyle={[GlobalStyles.card, { marginBottom: 10 }]}
            />
          )}
        />
      </View>
    </View>
  );
};

export default SelectFuel;
