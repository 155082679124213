import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  staffs: null,
};

const StaffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setStaff: (state, action) => {
      state.staffs = action.payload
        ?.map((s) => ({
          ...s,
          uid: s.staffId || s.id,
          profileUrl: s?.documents?.length > 0 ? s?.documents[0]?.url : '',
        }))
        .reverse();
    },
  },
});
export const { actions } = StaffSlice;
export default StaffSlice.reducer;
