import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { ListItem } from 'react-native-elements';
import { toLower } from 'lodash';
import GlobalStyles from '../../theme/GlobalStyles';
import MyText from '../../shared/components/MyText';
import { Colors } from '../../theme/Colors';

import PetrolIcon from '../../assets/svg/PetrolIcon';
import Spacing from '../../shared/components/Spacing';
import CanShow from '../../shared/components/CanShow';
import { FONT } from '../../theme/font';
import useConsumer from '../api/consumer.hook';
import { toLocalDate, toQuantity } from '../../utils/Utils';
import { Quantity } from '../../shared/components/GenericComponent';

const CreditItem = ({ onPress, item }) => {
  const [viewMore, setViewMore] = React.useState(false);
  const { getFuelConversionPoint } = useConsumer();
  const totalConversionPoint = getFuelConversionPoint(item?.totalConversionPoints);

  const toggleViewMoreHandler = () => {
    setViewMore(!viewMore);
  };

  const ItemText = ({ text, center = false }) => {
    return (
      <View style={{ flex: 1 }}>
        <MyText color="#787796" size={12} center={center} numberOfLines={1}>
          {text}
        </MyText>
      </View>
    );
  };

  const MoreDetails = ({ details = [] }) => {
    return details.map((_item) => {
      const conversionPoint = getFuelConversionPoint(_item?.conversionPoints);
      return (
        <View style={[GlobalStyles.flexRow, { marginLeft: 20 }]}>
          <ItemText text={_item?.itemName} />
          <ItemText text={`${toQuantity(_item?.quantity, 2)} ${toLower(_item?.units)}`} center />
          <ItemText
            text={`${toQuantity(conversionPoint?.value, 2)} ${toLower(conversionPoint?.units)}`}
            center
          />
        </View>
      );
    });
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <ListItem
        containerStyle={{
          background: 'linear-gradient(89.99deg, #F3FCFE 0%, #DCF2E5 64.05%, #E3FFEC 99.97%)',
          borderRadius: '8px',
          elevation: 5,
          marginVertical: 6,
        }}>
        <View style={{ flex: 1 }}>
          <View style={[GlobalStyles.flexRow]}>
            <ListItem.Content style={[styles.leftContent]}>
              <MyText size={12} color="#787796">
                {toLocalDate(item?.createdDate, 'DD MMM YYYY hh:mma')}
              </MyText>
              <Spacing size={8} />
              <MyText size={16} color={Colors.black}>
                Total waste |{' '}
                <Quantity
                  color={Colors.black}
                  value={item?.totalQty}
                  postfix={toLower(item?.units)}
                  decimal={2}
                />
              </MyText>
            </ListItem.Content>
            <ListItem.Content style={[styles.rightContent]}>
              <MyText size={12} color="#787796">
                Credits
              </MyText>
              <Spacing size={8} />
              <View style={[GlobalStyles.flexRow]}>
                <View style={{ padding: 4 }}>
                  <PetrolIcon />
                </View>
                <MyText size={18} font={FONT.w500} color={Colors.black}>
                  {toQuantity(totalConversionPoint?.value, 2)}
                  <MyText size={14} color={Colors.black}>
                    {toLower(totalConversionPoint?.units)}
                  </MyText>
                </MyText>
              </View>
            </ListItem.Content>
          </View>
          <CanShow show={viewMore}>
            <View style={[styles.moreDetailsContainer]}>
              <MoreDetails details={item?.orderItems} />
            </View>
          </CanShow>
          <Spacing size={8} />
          <TouchableOpacity onPress={toggleViewMoreHandler}>
            <MyText font={FONT.w500} size={12} color="#049869">
              {viewMore ? 'View Less' : 'View More'}
            </MyText>
          </TouchableOpacity>
        </View>
      </ListItem>
    </TouchableOpacity>
  );
};

export default CreditItem;

const styles = StyleSheet.create({
  moreDetailsContainer: {
    backgroundColor: Colors.white,
    padding: 12,
    margin: 6,
    borderBottomColor: '#787796',
    borderTopColor: '#787796',
    borderStyle: 'dashed',
    borderBottomWidth: '1px',
    borderTopWidth: '1px',
  },
  leftContent: { flex: 0.6 },
  rightContent: {
    flex: 0.4,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    marginRight: 12,
  },
});
