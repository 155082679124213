/**
 * @format
 * @flow strict-local
 */
import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';
import React from 'react';
import { Platform, StatusBar, useColorScheme } from 'react-native';
import Config from 'react-native-config';
import { ThemeProvider } from 'react-native-elements';
import FlashMessage from 'react-native-flash-message';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import SplashScreen from 'react-native-splash-screen';
import Toast, { ToastProvider } from 'react-native-toast-notifications';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import withCodePush from './features/Codepush/codepush';
import useAsyncEffect from './hooks/useAsyncEffect';
import AppNavigationContainer from './shared/components/NavigationContainer';
import store, { persistor } from './store/Store';
import { Colors } from './theme/Colors';
import Theme from './theme/Theme';

const App = () => {
  const isDarkMode = useColorScheme() === 'dark';
  const backgroundStyle = {
    backgroundColor: '#ffffff',
  };

  useAsyncEffect(async () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      SplashScreen.hide();
    }
    const selectedServer = await AsyncStorage.getItem('selectedServer');
    axios.defaults.baseURL = selectedServer || Config.BASE_URL || 'https://service.recykal.com';
  }, []);

  return (
    <SafeAreaProvider style={backgroundStyle}>
      {/* <SafeAreaView /> */}
      <ToastProvider placement="top" duration={5000} style={{ width: '100%' }}>
        <ThemeProvider theme={Theme}>
          <StatusBar
            backgroundColor={Colors.screenBgColor}
            barStyle={isDarkMode ? 'light-content' : 'dark-content'}
          />
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppNavigationContainer />
            </PersistGate>
          </ReduxProvider>
        </ThemeProvider>
      </ToastProvider>
      <Toast placement="top" ref={(ref) => (global.toast = ref)} />
      <FlashMessage position="top" />
    </SafeAreaProvider>
  );
};

console.disableYellowBox = true;
export default withCodePush(App);
