export const BAG_STATUS = {
  READY: 'READY',
  ISSUED_TO_GENERATOR: 'ISSUED_TO_GENERATOR',
  RECEIVED_AT_CENTER: 'RECEIVED_AT_CENTER',
};

export const ENTITY = {
  ATTENDANCE: 'ATTENDANCE',
  EMPLOYEE: 'EMPLOYEE',
  CUSTOMER: 'CUSTOMER',
};

export const AUTHORITY = {
  USER: 'ROLE_USER',
  SUPER_ADMIN: 'ROLE_ADMIN', // super admin
  CENTER_SUPERVISOR: 'ROLE_FRANCHISE_SUPERVISOR', // Center Supervisor
  KEY_ACCOUNT_MANAGER: 'ROLE_KEY_ACCOUNT_MANAGER',
  ACCOUNTS: 'ROLE_MARKETPLACE_ACCOUNTS',
  BUSINESS_HEAD: 'ROLE_MARKETPLACE_BUSINESS',
  CENTER_USER: 'ROLE_CENTER_USER', // Center Executive
  ATTENDANCE_SUPERVISOR: 'ROLE_ATTENDANCE_SUPERVISOR', // Attendance Supervisor
  PARTNER_OPERATIONS: 'ROLE_PARTNER_OPERATIONS',
  SMART_SCAN_USER: 'ROLE_SMART_SCAN_USER',
  ADMIN: 'ROLE_FRANCHISE_ADMIN', // admin
  COLLECTION_EXECUTIVE: 'ROLE_SMART_CENTER_COLLECTION_EXECUTIVE', // admin
};

export const EMPLOYEE_TYPE = {
  PERMANENT: 'PERMANENT',
  CONTRACT: 'CONTRACT',
};

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const CASH_CONSTANTS = {
  ADD_CASH: '+  Add Cash',
  REMARKS: 'Remarks',
  REMARKS_BY_SUPER: 'Remarks by supervisor',
  AMOUNT: 'Amount *',
  CENTER_NAME: 'Center Name *',
  ADVANCE_PAYMENTS: 'Advance Payments',
  EXPENSE_PENDING: 'Expense Pending',
  PRICE_ADJUSTMENT: 'Price Adjustment',
  FUEL_EXPENSE: 'Fuel Expense',
  OTHER_EXPENSE: 'Other Expense',
  WASTE_COLLECTOR: 'Waste Collector',
  REMAINING_ADVANCE: 'Remaining Advance',
  DRIVER_NAME: 'Driver Name',
  VEHICLE_NUMBER: 'Vehicle Number',
  WRITE_TO_INCHARGE: 'Write to center incharge',
  COMMENT: 'Comment',
};

export const EXPENSE_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
  PARTIAL_PAYMENT: 'Partial Payment',
};

export const CASH_TYPE = {
  ADVANCE: 'ADVANCE',
  EXPENSE: 'EXPENSE',
};

export const EXPENSE_TYPE = {
  FUEL: 'FUEL',
  MISC: 'MISC',
};

export const MRR_STATUS = {
  CREATED: 'CREATED',
  VERIFIED: 'VERIFIED',
  PICKED: 'PICKED',
  MATERIAL_RECEIVED: 'MATERIAL_RECEIVED',
  PICKUP_SCHEDULED: 'PICKUP_SCHEDULED',
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  HOLD: 'HOLD',
  CANCELLED: 'CANCELLED',
  RECEIVED: 'RECEIVED',
  REJECTED: 'REJECTED',
  DELIVERED: 'DELIVERED',
  DISPATCHED: 'DISPATCHED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_COMPLETED: 'COMPLETED',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  INVOICE_PENDING: 'INVOICE_PENDING',
  INVOICE_VERIFICATION_PENDING: 'INVOICE_VERIFICATION_PENDING',
  /* STATUS TITLES */
  COMPLETED: 'Completed',
  APPROVAL_PENDING_TITLE: 'Approval Pending',
  PAYMENT_PENDING_TITLE: 'Payment Pending',
  RECEIVED_TITLE: 'Received',
  CANCELLED_TITLE: 'Cancelled',
  CREATED_TITLE: 'Created',
  DELIVERED_TITLE: 'Delivered',
  ACTIVE_TITLE: 'Active',
  APPROVED_TITLE: 'Approved',
  PENDING_TITLE: 'Pending',
  DISPATCHED_TITLE: 'Dispatched',
  REJECTED_TITLE: 'Rejected',
  IN_PROGRESS_TITLE: 'In Progress',
  DRAFTS: 'Drafts',
  FILTERED: 'Filtered',
  PARTIAL_PAYMENT: 'PARTIAL_PAYMENT',
  PAYMENT_SUCCESS: 'Payment Successful',
  PARTIAL_RECORD: 'Partial Payment',
};

export const PRICE_STATUS = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REQUESTED: 'REQUESTED',
};

export const SO_STATUS = {
  CREATED: 'CREATED',
  PICKUP_SCHEDULED: 'PICKUP_SCHEDULED',
  COMPLETED: 'COMPLETED',
  INVOICE_PENDING: 'INVOICE_PENDING',
};

export const JOB_STATUS = {
  CREATED: 'CREATED',
  INITIATED: 'INITIATED',
  FINISHED: 'FINISHED',
  IN_PROCESS: 'IN-PROCESS',
  COMPLETED: 'COMPLETED',
  ARCHIVE: 'ARCHIVE',
};

export const ST_STATUS = {
  DRAFT: 'DRAFT',
  COMPLETED: 'FINALIZED',
  FILTERED: 'FILTERED',
};

export const SA_STATUS = {
  DRAFT: 'DRAFT',
  PENDING: 'APPROVAL_PENDING',
  COMPLETED: 'FINALIZED',
};

export const STOCK_STATUS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  REJECTED: 'REJECTED',
};

export const ORDER_TYPE = {
  PURCHASE: 'PURCHASE',
  SALE: 'SALE',
};

export const PAYMENT_MODE = {
  CASH: 'CASH',
  CHEQUE: 'CHEQUE',
  NET_BANKING: 'NET BANKING',
  ONLINE_TRANSFER: 'ONLINE_TRANSFER',
};

export const VALIDATION_MESSAGES = {
  PHONE: 'Please enter a valid mobile number',
  REFERENCE_NO: 'Please enter a valid reference no.',
  NUMBER: 'Please enter a valid number',
  EMAIL: 'Please enter a valid email',
  PASSWORD_MATCH: 'Passwords must match',
};

export const SORT_TYPE = {
  // PAYMENT_SETTLEMENT_DESC: 'order.pickupTime,paymentSettlement.createdAt,desc',
  PAYMENT_SETTLEMENT_DESC: 'order.pickupTime,desc',
  CREATED_DATE_DESC: 'createdDate,desc',
  CREATED_ON_DESC: 'createdOn,desc',
};

export const DOCUMENT_TYPES = {
  ATTENDANCE_IMAGE: 'ATTENDANCE_IMAGE',
  EMP_PROFILE_PIC: 'EMP_PROFILE_PIC',
  PROFILE_PICTURE: 'PROFILE_PICTURE',
};

export const DATE_KEYS = {
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
};
