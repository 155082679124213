import { groupBy, isEmpty, isUndefined } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-elements';
import { useDispatch } from 'react-redux';
import OpenBoxIcon from '../../assets/svg/OpenBoxIcon';
import PetrolIcon from '../../assets/svg/PetrolIcon';
import { CROUTES } from '../../constants/Routes';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import CanShow from '../../shared/components/CanShow';
import LoaderOverlay from '../../shared/components/LoaderOverlay';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import { FONT } from '../../theme/font';
import {
  useCreateOrderMutation,
  useGetLocationByIdMutation,
  useGetOrderPriceMutation,
} from '../api/consumer-app/consumer.api';
import { setLocationDetails } from '../api/consumer-app/consumer.slice';
import useConsumer from '../api/consumer.hook';
import { useSocket } from '../api/useSocket.hook';
import ConsumerHeader from '../components/ConsumerHeader';
import EarnedCreditLedger from '../components/EarnedCreditLedger';
import EligibleItemComponent from '../components/EligibleItemComponent';
import DetectBottleCounter from './DetectBottleCounter';

const InsertContainersScreen = ({ navigation, route }) => {
  const [getLocationDetails] = useGetLocationByIdMutation();
  const [getOrderPrice, { isLoading: gettingPrice, data: updatedPrices }] =
    useGetOrderPriceMutation();
  const [createOrder, { isLoading: creating }] = useCreateOrderMutation();

  const { binDetails, userDetails, getFuelConversionPoint, token } = useConsumer();

  const dispatch = useDispatch();

  const totalPrice = getFuelConversionPoint(updatedPrices?.conversion);

  const { initSocket, newItem, cart, markTxnCompleted } = useSocket();

  useAsyncEffect(async () => {
    const { params } = route;
    const binQr = params?.id;
    const _isUndefined = binQr === 'undefined' || isUndefined(binQr);
    if (_isUndefined) return;
    try {
      const rvmDetails = await getLocationDetails(binQr).unwrap();
      if (isEmpty(rvmDetails)) return;
      if (rvmDetails?.rvm) {
        dispatch(setLocationDetails(rvmDetails));
        initSocket(rvmDetails.displayId);
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: CROUTES.HOME, params: { id: rvmDetails.displayId } }],
        });
      }
    } catch (error) {
      console.log('🚀 ~ ~ error:', error);
      // error
    }
  }, [route.params]);

  useAsyncEffect(async () => {
    if (!cart.count) return;
    calculatePrice();
  }, [cart]);

  if (!token) {
    navigation.reset({
      index: 0,
      routes: [{ name: CROUTES.HOME_RVM, params: { id: route.params?.id } }],
    });
    return null;
  }

  const calculatePrice = async () => {
    const _oItems = groupBy(cart.items, 'sku');
    const orderItems = Object.keys(_oItems).map((itemId) => ({
      itemId,
      qty: _oItems[itemId]?.length,
    }));
    const payload = {
      orderItems,
      locationId: binDetails.id,
      mobileNumber: userDetails.mobileNumber || 8483094292,
    };
    try {
      await getOrderPrice(payload).unwrap();
    } catch (error) {
      // error
    }
  };

  const handleSubmit = async () => {
    const _oItems = groupBy(cart.items, 'sku');
    const orderItems = Object.keys(_oItems).map((itemId) => ({
      itemId,
      qty: _oItems[itemId]?.length,
    }));
    const payload = {
      orderItems,
      locationId: binDetails.id,
      mobileNumber: userDetails.mobileNumber,
    };
    try {
      const result = await createOrder(payload).unwrap();
      markTxnCompleted();
      navigation.reset({
        index: 0,
        routes: [{ name: CROUTES.CREDIT_RECEIPT, params: { result } }],
      });
    } catch (error) {
      // error
    }
  };

  return (
    <View
      style={{
        width: '100%',
        flex: 1,
      }}>
      <LoaderOverlay loading={creating} />
      <View
        style={{
          width: '100%',
          padding: 0,
          margin: 0,
          flex: 0.5,
          background: 'linear-gradient(101.92deg, #049869 41.28%, #386565 99.71%)',
        }}>
        <View>
          <ConsumerHeader />
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
          <CanShow
            show={cart.count > 0 || cart.invalidCount > 0}
            fallbackComponent={
              <View>
                <MyText font={FONT.w400} color={Colors.white} center size={20}>
                  <OpenBoxIcon /> Lid is Open
                </MyText>
                <MyText vpad={10} font={FONT.w400} color={Colors.white} center size={28}>
                  Drop Waste Items
                </MyText>
              </View>
            }>
            <View
              style={{
                width: '70%',

                flexDirection: 'column',
              }}>
              <View
                style={{
                  alignItems: 'center',

                  flex: 1,
                }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <View style={{ marginTop: 8 }}>
                    <PetrolIcon size="40" />
                  </View>
                  <Spacing hr size={20} />
                  <EarnedCreditLedger creditCount={totalPrice?.value} />
                </View>
              </View>
              <Spacing size={10} />
              <DetectBottleCounter
                newItem={newItem}
                cart={cart}
                handleTimeout={handleSubmit}
                isLoading={creating}
              />
            </View>
            <MyText color="#ffffff4f" style={{ marginTop: 5 }} center>
              Invalid Count: {cart?.invalidCount}
            </MyText>
          </CanShow>
        </View>
      </View>
      <View
        style={{
          width: '100%',
          padding: 0,
          margin: 0,
          flex: 0.5,
        }}>
        <View style={{ padding: DIM.screenPadding }}>
          <EligibleItemComponent titleColor="#787796" title="Items Eligible for Fuel Credits" />
        </View>
      </View>
      <View style={{ width: '100%', padding: 20 }}>
        <Button disabled={cart.count <= 0} title="Submit and Earn credit" onPress={handleSubmit} />
      </View>
    </View>
  );
};

export default InsertContainersScreen;
