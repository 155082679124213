import React from 'react';
import { View } from 'react-native';
import GlobalStyles from '../../theme/GlobalStyles';

const Footer = ({ children, containerStyle, ...restProps }) => {
  return (
    <View style={[GlobalStyles.footer, containerStyle]} {...restProps}>
      {children}
    </View>
  );
};

export default Footer;
