import React from 'react';
import { Text } from 'react-native-elements';
import { FONT } from '../../theme/font';

const MyText = ({
  font = FONT.w400,
  center,
  size,
  color,
  height,
  hpad,
  vpad,
  children,
  underline,
  style = {},
  ...props
}) => (
  <Text
    style={[
      {
        color,
        fontSize: size,
        fontFamily: font,
        lineHeight: height,
        paddingHorizontal: hpad,
        paddingVertical: vpad,
        letterSpacing: 0,
        textAlign: center ? 'center' : 'left',
        textDecorationLine: underline ? 'underline' : 'none',
        ...style,
      },
    ]}
    {...props}>
    {children}
  </Text>
);

export default React.memo(MyText);
