import PropTypes from 'prop-types';
import { View, Image, Dimensions, ImageBackground } from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import Spacing from '../../shared/components/Spacing';
import GlobalStyles from '../../theme/GlobalStyles';
import FAQIcon from '../../assets/svg/FAQIcon';
import MyText from '../../shared/components/MyText';
import { FONT } from '../../theme/font';
import FAQs from '../components/FAQs';
import { RecyclableMaterialIcon } from '../../assets/svg/RecyclableMaterialIcon';
import ViewLocationsButton from '../components/ViewLocationsButton';
import { CROUTES } from '../../constants/Routes';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
// import HowItWorksCarousal from '../components/HowItWorksCarousal';
// import HowItWorksIOCL from '../../assets/svg/HowItWorksIOCL';

const HOW_CREDIT = require('../../assets/svg/CreditsSvg/credit_master.svg');
const HOW_REDEEM = require('../../assets/svg/RedeemSvg/redeem_master.svg');
const FOOTER_BG = require('../../assets/svg/Consumer/footer-bg.svg');

const { width } = Dimensions.get('window');

const AboutProgram = ({ enableFooter = false }) => {
  const { navigate } = useNavigation();
  return (
    <View
      style={[
        {
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}>
      {/* How It Works in one Image */}
      <View>
        <Image source={HOW_CREDIT} style={{ height: width / 1.2, width: width / 1.2 }} />
        <Spacing size={8} />
        <Image source={HOW_REDEEM} style={{ height: width / 1.2, width: width / 1.2 }} />
      </View>

      {/* <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginVertical: 8,
        }}>
        <View
          style={{
            backgroundColor: '#E4FFE9',
            width: '50%',
            borderTopEndRadius: 8,
            borderTopStartRadius: 8,
            padding: 8,
          }}>
          <MyText center font={FONT.w500} size={16} color="#142040">
            How It Works
          </MyText>
        </View>
        <View
          style={{
            background: '#FEFEFE',
            border: '1px solid #EFEFEF',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.05)',
            borderRadius: 10,
            paddingTop: 12,
          }}>
          <HowItWorksIOCL />
        </View>
      </View> */}

      {/* How It Works Carousal */}
      {/* <View>
        <HowItWorksCarousal />
      </View> */}

      <View style={{ marginVertical: 12, padding: DIM.screenPadding, width: '100%' }}>
        <View style={[GlobalStyles.flexRow, { flex: 1, marginBottom: 4 }]}>
          <FAQIcon />
          <MyText font={FONT.w500}> FAQs</MyText>
        </View>
        <FAQs />
      </View>

      {/* FOOTERS */}
      <View
        style={{
          width,
          padding: 0,
          margin: 0,
          ...(enableFooter && { position: 'sticky', bottom: 70 }),
        }}>
        <ImageBackground
          source={FOOTER_BG}
          resizeMode="cover"
          imageStyle={{ height: 220, width }}
          style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}>
            <View style={{ marginHorizontal: 6 }}>
              <RecyclableMaterialIcon />
            </View>
            <View>
              <MyText font={FONT.w500} color={Colors.white}>
                Have Recyclable Waste?
              </MyText>
              <MyText font={FONT.w500} color={Colors.white}>
                Start Earning Fuel Credits
              </MyText>
            </View>
          </View>
          <ViewLocationsButton onPress={() => navigate(CROUTES.LOCATIONS_LIST)} />
        </ImageBackground>
      </View>
    </View>
  );
};

AboutProgram.propTypes = {
  enableFooter: PropTypes.bool,
};

export default AboutProgram;
