/* eslint-disable no-nested-ternary */
import React from 'react';
import { Divider } from 'react-native-elements';
import View from 'react-native-web/dist/exports/View';
import MyText from '../../shared/components/MyText';
import { FONT } from '../../theme/font';
import TimerCount from '../components/TimerCount';
import { ITEMS } from '../constants/consumer.const';

const DetectBottleCounter = ({ newItem, cart, handleTimeout, isLoading }) => {
  console.log('🚀 ~ file: DetectBottleCounter.js:25 ~ DetectBottleCounter ~ newItem:', newItem);
  const item = newItem ? ITEMS[newItem?.type] : {};
  return (
    <View style={{ width: '100%', alignItems: 'center' }}>
      <MyText vpad={10} color="#C3FFF1">
        Keep Dropping Items in Bin
      </MyText>
      <View
        style={{
          backgroundColor: '#ffffff',
          width: '100%',
          borderRadius: 8,
          flexDirection: 'row',
        }}>
        <View style={{ flex: 0.5, padding: 20, alignItems: 'center' }}>
          <MyText>Total Count</MyText>
          <MyText size={38} font={FONT.w700}>
            {cart?.count || 0}
          </MyText>
        </View>
        <Divider orientation="vertical" style={{ marginVertical: 20 }} />
        <View style={{ flex: 0.5, padding: 20, alignItems: 'center' }}>
          {item ? <item.icon /> : <TimerCount handleTimeout={handleTimeout} />}
        </View>
      </View>
    </View>
  );
};

export default React.memo(DetectBottleCounter);
