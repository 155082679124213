import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import LottieView from 'react-native-web-lottie';
import MyText from '../../shared/components/MyText';

function TimerCount({ handleTimeout }) {
  const interval = useRef();
  const [time, setTime] = useState(17);

  useEffect(() => {
    interval.current = setInterval(() => setTime(time - 1), 1000);
    return () => clearInterval(interval.current);
  }, [time]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(interval.current);
      handleTimeout();
    }
  }, [time]);

  console.log('🚀 ~ file: TimerCount.js:14 ~ TimerCount ~ timerRef.current:', time);

  return (
    <View style={{ height: 80, alignItems: 'center' }}>
      <MyText size={10}>Insert Bottle</MyText>
      <LottieView style={{ flex: 1 }} source={require('../../assets/anim/timer.json')} autoPlay />
    </View>
  );
}

export default React.memo(TimerCount);
