import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Text } from 'react-native-elements';
import { DIM } from '../../theme/Dims';
import { toSize } from '../../utils/Utils';
import { fs } from '../Dimensions';

const Section = (props) => {
  const {
    title,
    style,
    flat,
    wrapperStyle,
    children,
    footer,
    bodyStyle,
    containerStyle,
    rightComponent,
  } = props;
  return (
    <View style={style}>
      <Card
        containerStyle={[
          {
            padding: 0,
            marginBottom: toSize(15),
            ...containerStyle,
            ...styles.flat,
          },
        ]}
        wrapperStyle={[styles.section, wrapperStyle]}>
        {(title || rightComponent) && (
          <View style={styles.sectionHeader}>
            <Text style={[styles.sectionTitle]}>{(title || '')?.toUpperCase()}</Text>
            {rightComponent && <View>{rightComponent}</View>}
          </View>
        )}
        <View style={[styles.sectionBody, bodyStyle]}>{children}</View>
        <View style={styles.sectionFooter}>{footer}</View>
      </Card>
    </View>
  );
};

Section.propTypes = {
  title: PropTypes.any,
  bodyStyle: PropTypes.object,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  rightComponent: PropTypes.element,
};
const styles = StyleSheet.create({
  section: {
    padding: DIM.sectionPadding,
  },
  sectionHeader: {
    marginBottom: toSize(15),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: fs(16),
    fontFamily: 'fontHeavy',
    letterSpacing: 1.2,
  },
  sectionBody: {},
  sectionFooter: {},
  flat: {
    // borderTopLeftRadius: 0,
    // borderTopRightRadius: 0,
  },
});
export default Section;
