import { Dimensions, Platform, StyleSheet } from 'react-native';
import { Colors } from './Colors';
import { DIM } from './Dims';

const window = Dimensions.get('window');
const GlobalStyles = StyleSheet.create({
  screenContainer: {
    ...Platform.select({
      android: {
        flex: 1,
      },
      default: {
        // other platforms, web for example
        // height: 100,
      },
    }),
    padding: DIM.screenPadding,
    // backgroundColor: Colors.screenBgColor,
  },
  card: {
    marginHorizontal: 0,
    marginTop: 0,
    marginBottom: 0,
    borderRadius: DIM.cornerRadius,
    elevation: 0,
    shadowOpacity: 0,
    borderWidth: 0,
    backgroundColor: Colors.cardBgColor,
  },
  section: {
    backgroundColor: Colors.white,
    padding: DIM.sectionPadding,
  },
  flexRow: {
    flexDirection: 'row',
  },
  hasFooter: {
    height: window?.height - DIM.headerHeight,
  },
  hasTabs: {
    flex: 1,
    height: window?.height - DIM.mainTabHeight,
  },
  buttonFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: DIM.screenPadding,
  },
  listFooter: {
    color: '#c4c4c4',
    maxWidth: '60%',
    textAlign: 'center',
    alignSelf: 'center',
    textTransform: 'uppercase',
    paddingBottom: 20,
  },
  clearButton: {
    backgroundColor: Colors.clear,
    borderColor: Colors.darkGrey,
    borderWidth: 1,
  },
  pb_0: { paddingBottom: 0 },
  mb_0: { marginBottom: 0 },
  pt_0: { paddingTop: 0 },
  px_0: { paddingHorizontal: 0 },
  py_0: { paddingVertical: 0 },
  c_primary: { color: Colors.primary },
  c_dark: { color: Colors.defaultColor },
});

export const buttonStyles = {
  secondary: {
    buttonStyle: {
      height: DIM.buttonHeight,
      borderRadius: 10,
      backgroundColor: '#f2f2f2',
      borderColor: '#e2e2e9',
      borderWidth: 1,
    },
    titleStyle: [{ color: Colors.primary, fontFamily: 'fontMedium', fontSize: 16 }],
  },
};

export default GlobalStyles;
