import { useSelector } from 'react-redux';

/**
 * hook use to get cities,adminConfig and businessTypes
 * from redux
 * @returns { cities, adminConfig, businessTypes}
 */
export default function useAppConfig() {
  const { appConfig, auth } = useSelector(({ appConfig, auth }) => {
    return { appConfig, auth };
  });
  return { appConfig, ...auth, ...appConfig };
}
