import React from 'react';
import { ScrollView, Text, View, TouchableOpacity, Image, Dimensions } from 'react-native';
import LottieView from 'react-native-web-lottie';
import { CROUTES } from '../../constants/Routes';
import MyText from '../../shared/components/MyText';
import { Colors } from '../../theme/Colors';
import Theme from '../../theme/Theme';
import { FONT } from '../../theme/font';
import BillingSummary from '../components/BillingSummary';
import { toLocalDate } from '../../utils/Utils';
import GlobalStyles from '../../theme/GlobalStyles';
import CanShow from '../../shared/components/CanShow';
import TxnInfoCircle from '../../assets/svg/TxnInfoCircle';

const RECEIPT_BG = require('../../assets/svg/Consumer/receipt_bg.svg');

const { height, width } = Dimensions.get('window');

const ShowReceptScreen = ({ navigation, route }) => {
  const { billDetails, isViewReceipt = false } = route?.params || {};

  const handleGoToHome = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: CROUTES.HOME }],
    });
  };

  return (
    <>
      <ScrollView
        style={{
          maxHeight: height * 0.7,
        }}>
        <View style={GlobalStyles.screenContainer}>
          <View style={{ flex: 1 }}>
            <CanShow show={!isViewReceipt}>
              <>
                <LottieView
                  style={{
                    width: '60%',
                    height: '30%',
                    alignSelf: 'center',
                    position: 'relative',
                    top: -50,
                  }}
                  source={require('../../assets/anim/consumer-success.json')}
                  autoPlay
                />
                <Text
                  style={{
                    marginTop: -60,
                    background: 'linear-gradient(89.99deg, #049869 50%, #D9ECD9 99.97%)',
                    borderRadius: '8px',
                    elevation: 5,
                    padding: 8,
                    width: '80%',
                    alignSelf: 'center',
                    color: Theme.Colors.white,
                    textAlign: 'center',
                    fontFamily: Theme.customClasses.fontFamily.regular.fontFamily,
                  }}>
                  Credits Redeemed successfully !
                </Text>
              </>
            </CanShow>
            <View>
              <Text
                style={{
                  textAlign: 'center',
                  fontFamily: Theme.customClasses.fontFamily.medium.fontFamily,
                  marginTop: 30,
                }}>
                Amount Paid
              </Text>
              <Text
                style={{
                  textAlign: 'center',
                  marginTop: 10,
                  color: '#787796',
                }}>
                ₹{' '}
                <Text
                  style={{
                    textDecorationLine: 'line-through',
                    textDecorationStyle: 'solid',
                    fontFamily: Theme.customClasses.fontFamily.medium.fontFamily,
                  }}>
                  {billDetails?.totalBillAmount}
                </Text>
              </Text>
              <Text
                style={{
                  textAlign: 'center',
                  marginBottom: 20,
                }}>
                ₹{' '}
                <MyText size={26} font={FONT.w600} color={Colors.black}>
                  {billDetails?.payableAmount}
                </MyText>
              </Text>
            </View>
            <View style={{ paddingHorizontal: 30 }}>
              <BillingSummary
                receiptNo={billDetails?.redeemId}
                date={toLocalDate(billDetails?.redeemedDate, 'DD MMM YYYY hh:mma')}
                totalBill={billDetails?.totalBillAmount}
                redeemedCredit={billDetails?.redeemedAmount}
                amountToBePaid={billDetails?.payableAmount}
              />
              <CanShow show={!isViewReceipt}>
                <View
                  style={{
                    padding: 10,
                    marginTop: 8,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <View style={{ marginTop: 4, marginRight: 6 }}>
                    <TxnInfoCircle />
                  </View>
                  <Text
                    style={{
                      color: '#787796',
                      fontSize: 12,
                      fontWeight: '400',
                      marginHorizontal: 5,
                    }}>
                    Show this receipt to IOCL Executive for verification.
                  </Text>
                </View>
              </CanShow>
            </View>
          </View>
        </View>
      </ScrollView>
      <CanShow show={!isViewReceipt}>
        <TouchableOpacity
          onPress={handleGoToHome}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            paddingTop: 10,
          }}>
          <MyText color="#049869" center font={FONT.w600} size={18}>
            Go Home
          </MyText>
        </TouchableOpacity>
      </CanShow>
      <View style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <Image source={RECEIPT_BG} style={{ height: height * 0.15, width }} />
      </View>
    </>
  );
};

export default ShowReceptScreen;
