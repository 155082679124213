import { CONSUMER_API } from '../../constants/endpoints.const';
import { backendApi } from '../base.config';
import { TAGS } from '../tags.enum';

export const consumerApi = backendApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    requestOTP: builder.mutation({
      query: (body) => ({
        url: CONSUMER_API.REQUEST_OTP,
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),
    verifyOTP: builder.mutation({
      query: (body) => ({
        url: CONSUMER_API.VERIFY_OTP,
        method: 'POST',
        body,
      }),
      transformResponse(apiResponse, meta) {
        const newToken = meta.response.headers.get('Authorization');
        return { ...apiResponse, newToken };
      },
      invalidatesTags: [TAGS.TOKEN_CHANGED],
    }),
    getConsumerDetails: builder.query({
      query: (params) => ({
        url: CONSUMER_API.CONSUMER_DETAILS,
        method: 'GET',
        params,
      }),
      providesTags: [TAGS.TOKEN_CHANGED],
    }),
    getConsumerByHashId: builder.mutation({
      query: (hashId) => ({
        url: `${CONSUMER_API.CONSUMER}/${hashId}`,
        method: 'GET',
      }),
    }),
    collectAtRvm: builder.mutation({
      query: (body) => ({
        url: `${CONSUMER_API.COLLECT_AT_RVM}`,
        method: 'PUT',
        body,
      }),
    }),
    getLocations: builder.query({
      query: (params) => ({
        url: CONSUMER_API.LOCATIONS,
        method: 'GET',
        params,
      }),
      //   providesTags: [TAGS.CONSUMER_CART],
    }),
    getRedeemTypes: builder.query({
      query: (params) => ({
        url: CONSUMER_API.REDEEM_TYPES,
        method: 'GET',
        params,
      }),
      //   providesTags: [TAGS.CONSUMER_CART],
    }),
    getLocationById: builder.mutation({
      query: (displayId) => ({
        url: `${CONSUMER_API.LOC_BY_DISPLAY_ID}`,
        method: 'GET',
        headers: {
          id: displayId,
        },
      }),
    }),
    getRedeemBalance: builder.mutation({
      query: (body) => ({
        url: `${CONSUMER_API.REDEEM_BALANCE}`,
        method: 'POST',
        body: { ...body, confirm: false },
      }),
    }),
    redeemAtLocation: builder.mutation({
      query: (body) => ({
        url: `${CONSUMER_API.REDEEM_CREDIT}`,
        method: 'POST',
        body: { ...body, confirm: true },
      }),
      invalidatesTags: [TAGS.TOKEN_CHANGED],
    }),
    getOrderPrice: builder.mutation({
      query: (body) => ({
        url: `${CONSUMER_API.GET_ORDER_PRICE}`,
        method: 'POST',
        body,
      }),
    }),
    createOrder: builder.mutation({
      query: (body) => ({
        url: `${CONSUMER_API.CREATE_ORDER}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TAGS.TOKEN_CHANGED],
    }),
    getRedeemHistory: builder.query({
      query: (params) => ({
        url: CONSUMER_API.REDEEM_HISTORY,
        method: 'GET',
        params,
      }),
      //   providesTags: [TAGS.CONSUMER_CART],
    }),
    getCreditHistory: builder.query({
      query: (params) => ({
        url: CONSUMER_API.CREDIT_HISTORY,
        method: 'GET',
        params,
      }),
      //   providesTags: [TAGS.CONSUMER_CART],
    }),
    getCatalogItems: builder.query({
      query: (params) => ({
        url: CONSUMER_API.GET_ITEMS,
        method: 'GET',
        params,
      }),
      //   providesTags: [TAGS.CONSUMER_CART],
    }),
    getRedemptionValues: builder.query({
      query: (params) => ({
        url: CONSUMER_API.GET_REDEMPTION_VALUES,
        method: 'GET',
        params,
      }),
      //   providesTags: [TAGS.CONSUMER_CART],
    }),
  }),
});

export const {
  useRequestOTPMutation,
  useVerifyOTPMutation,
  useGetConsumerDetailsQuery,
  useGetLocationByIdMutation,
  useCollectAtRvmMutation,
  useGetLocationsQuery,
  useLazyGetConsumerDetailsQuery,
  useGetRedeemTypesQuery,
  useGetRedeemBalanceMutation,
  useRedeemAtLocationMutation,
  useGetOrderPriceMutation,
  useCreateOrderMutation,
  useLazyGetRedeemHistoryQuery,
  useLazyGetCreditHistoryQuery,
  useGetCatalogItemsQuery,
  useLazyGetRedemptionValuesQuery,
} = consumerApi;
