// RootNavigation.js
// this ref is use to trigger navigation from anywhere.
import * as React from 'react';

export const navigationRef = React.createRef();
export function navigate(name, params) {
  console.log('navigationRef', navigationRef);
  if (navigationRef?.current?.navigate) {
    // Perform navigation if the app has mounted
    navigationRef.current.navigate(name, params);
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
}
