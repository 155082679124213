import React from 'react';
import { ScrollView, View, TouchableOpacity } from 'react-native';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { DIM } from '../../theme/Dims';
import { FONT } from '../../theme/font';
import useConsumer from '../api/consumer.hook';
import ConsumerHeader from '../components/ConsumerHeader';
import ItemSummery from './ItemSummery';
import { CROUTES } from '../../constants/Routes';
import Footer from '../../shared/components/Footer';
import { toQuantity } from '../../utils/Utils';

const CreditReceiptScreen = ({ route, navigation }) => {
  const { result } = route.params;
  const { getFuelConversionPoint } = useConsumer();
  const conversionPoint = getFuelConversionPoint(JSON.parse(result?.conversion));

  const handleGoToHome = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: CROUTES.HOME }],
    });
  };

  return (
    <View
      style={{
        width: '100%',
        flex: 1,
      }}>
      <ScrollView style={{ padding: DIM.screenPadding }}>
        <ConsumerHeader />
        <View style={{ height: 200, alignItems: 'center', justifyContent: 'center' }}>
          <MyText size={21} font={FONT.w600}>
            Congratulations!!
          </MyText>
          <Spacing size={20} />
          <MyText color="#000000" font={FONT.w400} size={16}>
            You have earned{' '}
            <MyText color="#E26613" font={FONT.w400} size={16}>
              {`${toQuantity(conversionPoint?.value, 2)} ${conversionPoint?.units}`}
            </MyText>{' '}
            Fuel credits
          </MyText>
          <MyText vpad={12} color="#787796" center size={12}>
            {`You will receive an sms with Redeem link \non ${result?.alternateMobile1}`}
          </MyText>
        </View>
        <View style={{ paddingHorizontal: 20 }}>
          <ItemSummery data={result} />
        </View>
      </ScrollView>
      <View style={{ padding: 20 }}>
        <Footer>
          <TouchableOpacity
            onPress={handleGoToHome}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 8,
              flexDirection: 'row',
              padding: 12,
            }}>
            <MyText color="#049869" center font={FONT.w600} size={18}>
              Go Home
            </MyText>
          </TouchableOpacity>
        </Footer>
      </View>
    </View>
  );
};

export default CreditReceiptScreen;
