import React, { useRef } from 'react';
import { Modal, ScrollView, StyleSheet, View } from 'react-native';
import { Header } from 'react-native-elements';
import Toast from 'react-native-toast-notifications';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import { fs } from '../Dimensions';

const AppModal = (props) => {
  const {
    visible,
    setVisible,
    style,
    children,
    title,
    noBack,
    modalBgColor,
    isSearchModal,
    noLeftComponent,
    rightComponent = {},
    containerScrollEnable,
    leftComponent = {
      icon: 'keyboard-backspace',
      type: 'material',
      onPress: () => setVisible(false),
    },
    centerComponent = {
      text: title,
      style: { fontFamily: 'fontHeavy', fontSize: fs(18) },
    },
    placement = 'center',
    ...restProps
  } = props;

  const toastRef = useRef();

  return (
    <>
      <Modal
        animationType="slide"
        visible={visible}
        onRequestClose={() => {
          !noBack && setVisible && setVisible(!visible);
        }}
        {...restProps}>
        <View style={[styles.centeredView, { backgroundColor: modalBgColor || Colors.white }]}>
          <Header
            containerStyle={[
              { minHeight: DIM.headerHeight, backgroundColor: Colors.screenBgColor },
            ]}
            placement={placement}
            centerComponent={centerComponent}
            leftComponent={!noLeftComponent && !noBack && leftComponent}
            rightComponent={rightComponent}
          />
          {containerScrollEnable ? (
            <ScrollView
              contentContainerStyle={[style, { height: '100%' }]}
              scrollEnabled={containerScrollEnable}
              showsHorizontalScrollIndicator={false}>
              {children}
            </ScrollView>
          ) : (
            <View style={[style, { flex: 1 }]}>{children}</View>
          )}
        </View>
        <Toast placement="top" ref={(ref) => (global.toast = ref)} />
      </Modal>
    </>
  );
};
const styles = StyleSheet.create({
  screen: {
    paddingTop: 0,
  },
  centeredView: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },
  modalView: {
    flex: 1,
    backgroundColor: 'white',
    padding: 35,
  },
});

export default AppModal;
