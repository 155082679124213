/* eslint-disable no-case-declarations */
import { useEffect, useRef, useState } from 'react';
import { Vibration } from 'react-native';
import io from 'socket.io-client';
import { AppConfig } from '../../shared/AppConfig.web';
import useConsumer from './consumer.hook';

export function useSocket() {
  const [socketio, setSocketio] = useState();
  const [newItem, setNewItem] = useState({});
  console.log('🚀 ~ file: useSocket.hook.js:11 ~ useSocket ~ newItem:', newItem);

  const [cart, setCart] = useState({
    count: 0,
    invalidCount: 0,
    items: [],
  });

  const machineRef = useRef();
  const { userDetails } = useConsumer();

  const [clientId, setClientId] = useState();
  const [isLidOpened, setIsLidOpened] = useState();

  useEffect(() => {
    if (!socketio) return;
    return () => socketio.close();
  }, [socketio]);

  function initSocket(id) {
    machineRef.current = id;
    const serverSocket = io(AppConfig.REACT_APP_SOCKET_URL, {
      transports: ['polling', 'websocket'],
    });

    handleSocketInitialization(serverSocket);
    handleSocketConnectionToClient(serverSocket);
    handleSocketConnectionToServer(serverSocket);
    handleSocketDisconnection(serverSocket);

    serverSocket.on('lid_opened', (input) => {
      console.log('🚀 ~ file: useSocket.js:29  input:', input);
      setIsLidOpened(true);
    });

    setSocketio(serverSocket);
    sendOpenLid(serverSocket);
  }

  async function _handleOnBotleInMachine(input) {
    console.log('🚀 ~ file: useSocket.js:52 ~ input:', input);
    setNewItem(input?.new_item);
    setCart({
      count: input.total_count,
      invalidCount: input.invalid_items?.length || 0,
      items: input?.accepted_items,
    });

    setTimeout(() => {
      setNewItem({});
    }, 6000);
    Vibration.vibrate();
  }

  // on Bottle inserted to Machine
  const handleSocketConnectionToClient = (serverSocket) => {
    serverSocket.on('to_client', (input) => {
      switch (input.event) {
        case 'bottle_inserted':
          _handleOnBotleInMachine(input);
          break;

        default:
          break;
      }
      console.log('🚀 ~ file: useSocket.js:20 ', input);
    });
  };

  const handleSocketConnectionToServer = (serverSocket) => {
    serverSocket.on('to_server', (input) => {
      console.log('🚀 ~ file: useSocket.js:26 ~ handleSocketConnectionToServer ~ input:', input);
    });
  };

  const handleSocketDisconnection = (serverSocket) => {
    serverSocket.on('disconnect', (input) => {
      console.log('🚀 ~ file: useSocket.js:32 ~ serverSocket.on ~ disconnect:', input);
    });
  };
  const markTxnCompleted = (result) => {
    const machineId = machineRef.current;
    socketio.emit('to_server', {
      to: 'server',
      from: `web@${clientId}_${machineId}`,
      machineId,
      clientId: `web@${clientId}`,
      event: 'txn_completed',
      cart,
      createdAt: new Date(),
    });
  };

  const handleSocketInitialization = (serverSocket) => {
    const machineId = machineRef.current;
    console.log('🚀 ~ handleSocketInitialization~ serverSocket:', machineId);
    // message listener from server
    serverSocket.on('init', (input) => {
      setClientId(input.to);
      serverSocket.emit('join', 'consumer');
      serverSocket.emit('to_server', {
        to: 'server',
        from: `web@${input.to}_${machineId}`,
        machineId,
        clientId: `web@${input.to}`,
        message: 'Connection Registration',
        event: 'init_connection',
        uuid: userDetails.mobileNumber,
        cart,
        createdAt: new Date(),
      });
    });
  };
  const sendOpenLid = (inSocket) => {
    const machineId = machineRef.current;
    const socket = socketio || inSocket;
    socket.emit('to_server', {
      to: 'server',
      from: `web@${clientId}_${machineId}`,
      machineId,
      clientId: `web@${clientId}`,
      event: 'init_open_lid',
      cart,
      createdAt: new Date(),
    });
  };

  return { markTxnCompleted, sendOpenLid, initSocket, cart, isLidOpened, newItem };
}
