import React from 'react';
import { ActivityIndicator, View } from 'react-native';

const Loader = ({ size, centered }) => {
  return (
    <View
      style={[
        { flex: 1, alignItems: 'center' },
        centered ? { justifyContent: 'center', height: '100%' } : {},
      ]}>
      <ActivityIndicator
        animating
        color="#73b343"
        style={[{ margin: 'auto', flex: 1 }]}
        size={size || 'large'}
      />
    </View>
  );
};

export default Loader;
