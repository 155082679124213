import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

const initialAuth = {
  cities: [],
  adminConfig: {},
  businessTypes: [],
  fuelTypes: [],
  locations: [],
  wards: [],
  departments: {},
  designations: {},
};

const AppConfig = createSlice({
  name: 'appConfig',
  initialState: initialAuth,
  reducers: {
    setAppConfig: (state, action) => {
      [state.cities, state.adminConfig, state.businessTypes, state.fuelTypes] = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = keyBy(action.payload, 'id');
    },
    setWards: (state, action) => {
      state.wards = keyBy(action.payload, 'id');
    },
    setDepartments: (state, action) => {
      state.departments = keyBy(action.payload, 'id');
    },
    setDesignations: (state, action) => {
      state.designations = keyBy(action.payload, 'id');
    },
    setFuelType: (state, action) => {
      state.fuelTypes = action.payload;
    },
  },
});

export const { actions } = AppConfig;
export default AppConfig.reducer;
