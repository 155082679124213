import React from 'react';
import { Dimensions, ScrollView, StyleSheet, View, Image } from 'react-native';

import AndroidLine from '../../assets/svg/AndroidLine';
import FAQIcon from '../../assets/svg/FAQIcon';
import PetrolIcon from '../../assets/svg/PetrolIcon';
import WelcomeText1 from '../../assets/svg/WelcomeText1';
import WelcomeText2 from '../../assets/svg/WelcomeText2';
import WelcomeText3 from '../../assets/svg/WelcomeText3';
import { CROUTES } from '../../constants/Routes';
import CanShow from '../../shared/components/CanShow';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import GlobalStyles from '../../theme/GlobalStyles';
import { FONT } from '../../theme/font';
import ConsumerLogin from '../ConsumerLogin/ConsumerLogin';
import useConsumer from '../api/consumer.hook';
import ConsumerHeader from '../components/ConsumerHeader';
import EligibleItemComponent from '../components/EligibleItemComponent';
import FAQs from '../components/FAQs';

const CREDIT_BG = require('../../assets/svg/Consumer/credit_bg_img.svg');

const { height, width } = Dimensions.get('window');

const _height = height - 60;

const RVMHome = ({ navigation, route }) => {
  const { token, binQr } = useConsumer();

  return (
    <View style={{ flex: 1 }}>
      <ConsumerHeader />
      <ScrollView
        style={{
          maxHeight: _height,
        }}>
        <View style={[{ justifyContent: 'space-around', marginVertical: 30 }]}>
          <View style={{ alignItems: 'center' }}>
            <WelcomeText1 />
            <Spacing size={25} />
            <WelcomeText2 />
            <Spacing size={8} />
            <WelcomeText3 />
            <Spacing size={12} />
          </View>
          <Image source={CREDIT_BG} style={{ height: width / 1.8, width }} />
          <View
            style={{
              width: '100%',
              padding: 0,
              margin: 0,
              position: 'relative',
              flex: 1,
              background: 'linear-gradient(101.92deg, #049869 41.28%, #386565 99.71%)',
            }}>
            <CanShow show={!token}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingHorizontal: 30,
                  width: Dimensions.get('window').width,
                  marginTop: 30,
                }}>
                <View style={{ marginRight: 10 }}>
                  <PetrolIcon size="33" />
                </View>
                <MyText color={Colors.white} height={21}>
                  Register using Mobile number to Start earning Fuel Credits
                </MyText>
              </View>
              <View>
                <ConsumerLogin
                  onSuccess={() => navigation.navigate(CROUTES.INSERT_CONTAINERS, { id: binQr })}
                />
              </View>
              <Spacing size={10} />
              <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                <AndroidLine />
              </View>
            </CanShow>
            <View style={{ padding: DIM.screenPadding }}>
              <EligibleItemComponent titleColor="#FFFFFF" title="Items Eligible for Fuel Credits" />
            </View>
          </View>
        </View>
        <View style={{ paddingHorizontal: 20, paddingBottom: 20 }}>
          <View style={[GlobalStyles.flexRow, { flex: 1, marginBottom: 4 }]}>
            <FAQIcon />
            <MyText size={17} font={FONT.w500}>
              {' '}
              FAQs
            </MyText>
          </View>
          <FAQs />
        </View>
      </ScrollView>
    </View>
  );
};

export default RVMHome;

const styles = StyleSheet.create({});
