import { createSlice } from '@reduxjs/toolkit';

const initialAttendance = {
  attendance: [],
  totalCount: 0,
  totalPresent: 0,
};

const AttendanceSlice = createSlice({
  name: 'Attendance',
  initialState: initialAttendance,
  reducers: {
    setAttendance: (state, action) => {
      const { payload } = action;
      state.attendance = payload;
      state.totalPresent = payload?.filter((d) => d.present)?.length || 0;
    },
  },
});
export const { actions } = AttendanceSlice;
export default AttendanceSlice.reducer;
