import { isUndefined } from 'lodash';
import React from 'react';
import AnimatedNumbers from 'react-animated-numbers';
import { Dimensions, Image, ImageBackground, ScrollView, Text, View } from 'react-native';
import { Button, Header, Icon } from 'react-native-elements';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { useToast } from 'react-native-toast-notifications';
import LottieView from 'react-native-web-lottie';
import ReceiptIcon from '../../assets/svg/ReceiptIcon';
import { CROUTES } from '../../constants/Routes';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { Colors } from '../../theme/Colors';
import { DIM } from '../../theme/Dims';
import Theme from '../../theme/Theme';
import { FONT } from '../../theme/font';
import { toLocalDate } from '../../utils/Utils';
import { useRedeemAtLocationMutation } from '../api/consumer-app/consumer.api';
import BillingSummary from '../components/BillingSummary';

const BG_IMG = require('../../assets/svg/Consumer/green_bg_img.svg');
const PARTY_SUC = require('../../assets/svg/Consumer/party_success.svg');

const { height } = Dimensions.get('window');

const ConfirmRedemptionScreen = ({ navigation, route }) => {
  const { billDetails, payload } = route?.params || {};

  const [redeemAtLocation, { isLoading }] = useRedeemAtLocationMutation();
  const toast = useToast();

  const [num, setNum] = React.useState(undefined);

  React.useEffect(() => {
    setTimeout(() => {
      setNum(billDetails?.payableAmount);
    }, 1000);
  }, [billDetails]);

  const handleRedeem = async () => {
    try {
      const result = await redeemAtLocation(payload).unwrap();
      navigation.reset({
        index: 0,
        routes: [{ name: CROUTES.SHOW_RECEIPT, params: { billDetails: result } }],
      });
    } catch (error) {
      toast.show(error.message || 'Problem while paying, please contact pump executive', {
        type: 'error',
      });
    }
  };

  return (
    <>
      <ImageBackground
        source={BG_IMG}
        style={{
          height: '100%',
          flex: 1,
        }}>
        <Header
          backgroundColor={Colors.clear}
          placement="center"
          leftComponent={() => (
            <Icon
              onPress={navigation.goBack}
              style={{ alignSelf: 'center' }}
              type="material"
              size={30}
              name="keyboard-backspace"
              color="#000"
            />
          )}
          centerComponent={{
            text: CROUTES.CONFIRM_REDEMPTION,
            style: { fontSize: 16, color: Colors.white },
          }}
          containerStyle={{ height: 80 }}
        />
        <ScrollView style={{ height: height - 200 }}>
          <View
            style={{
              padding: DIM.screenPadding,
              justifyContent: 'center',
              alignItems: 'center',
              width: Dimensions.get('window').width,
            }}>
            <View
              style={{
                backgroundColor: '#FFE594',
                paddingHorizontal: 20,
                paddingVertical: 8,
                borderRadius: 8,
                flexDirection: 'row',
              }}>
              <Image
                source={PARTY_SUC}
                style={{ height: 20, width: 20, marginEnd: 10 }}
                resizeMode="contain"
              />
              <MyText color={Colors.black} size={12} height={26} font={FONT.w500}>
                Yay !! You saved ₹ {billDetails?.redeemedAmount}
              </MyText>
            </View>
            <Spacing size={30} />
            <View style={{ position: 'relative' }}>
              <View
                style={{
                  width: 200,
                  height: 200,
                  position: 'absolute',
                }}>
                <LottieView source={require('../../assets/anim/confetti.json')} autoPlay loop />
              </View>
              <MyText color={Colors.white} center font={FONT.w600}>
                Amount to be Paid
              </MyText>
              <Spacing size={10} />
              <Text
                style={{
                  textAlign: 'center',
                  marginTop: 10,
                  color: '#FFD54F',
                  textDecorationLine: 'line-through',
                  fontSize: RFPercentage(3),
                }}>
                <MyText
                  size={26}
                  color="#FFD54F"
                  style={{
                    textDecorationLine: 'line-through',
                    textDecorationStyle: 'solid',
                    fontFamily: Theme.customClasses.fontFamily.medium.fontFamily,
                    fontSize: RFPercentage(3),
                  }}>
                  ₹{billDetails?.totalBillAmount}
                </MyText>
              </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <View>
                  <MyText size={36} color={Colors.white}>
                    ₹{' '}
                  </MyText>
                </View>
                <View>
                  {!isUndefined(num) ? (
                    <AnimatedNumbers
                      includeComma
                      animateToNumber={num}
                      fontStyle={{
                        fontSize: 36,
                        color: Colors.white,
                        fontFamily: FONT.w700,
                      }}
                      locale="en-IN"
                      configs={[
                        { mass: 1, tension: 220, friction: 100 },
                        { mass: 1, tension: 180, friction: 130 },
                        { mass: 1, tension: 180, friction: 135 },
                        { mass: 1, tension: 260, friction: 100 },
                      ]}
                    />
                  ) : (
                    <Text
                      style={{
                        fontSize: 36,
                        color: Colors.white,
                        fontFamily: FONT.w700,
                      }}>
                      {billDetails?.totalBillAmount}
                    </Text>
                  )}
                </View>
              </View>
            </View>
            <Spacing size={20} />
            <View
              style={{
                opacity: 0.8,
                width: '95%',
                paddingVertical: 20,
                paddingHorizontal: 20,
                borderRadius: 8,
                background: 'rgba(255, 255, 255, 0.85)',
              }}>
              <BillingSummary
                date={toLocalDate(billDetails?.redeemedDate, 'DD MMM YYYY hh:mma')}
                totalBill={billDetails?.totalBillAmount}
                redeemedCredit={billDetails?.redeemedAmount}
                amountToBePaid={billDetails?.payableAmount}
              />
            </View>
            <Spacing size={50} />
            <Button
              onPress={handleRedeem}
              loading={isLoading}
              title="Confirm & Generate Receipt"
              icon={<ReceiptIcon />}
              buttonStyle={[
                {
                  background: 'linear-gradient(89.99deg, #18171E 20%, #313432 99.97%)',
                },
              ]}
            />
          </View>
        </ScrollView>
      </ImageBackground>
    </>
  );
};

export default ConfirmRedemptionScreen;
