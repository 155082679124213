import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { Overlay, Text } from 'react-native-elements';
import { Colors } from '../../theme/Colors';

const LoaderOverlay = ({ loading, title }) => (
  <Overlay isVisible={loading} overlayStyle={[styles.container]}>
    <ActivityIndicator animating color={Colors.primary} style={[styles.loader]} size="large" />
    <Text h3>{title || 'Loading...'}</Text>
  </Overlay>
);

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    alignItems: 'center',
    padding: 20,
    width: 130,
    height: 130,
    borderRadius: 10,
  },
  loader: {
    paddingVertical: 15,
  },
});

export default LoaderOverlay;
