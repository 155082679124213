/* eslint-disable max-len */
import React from 'react';
import Svg, { Path, Defs } from 'react-native-svg';

const LoginIcon = () => {
  return (
    <Svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M14.9077 12.8151L3.20028 21.5989C1.88186 22.5881 0 21.6474 0 19.9991V2.09366C0 0.428579 1.91574 -0.507381 3.22921 0.515988L14.9366 9.63764C15.9762 10.4476 15.9619 12.0242 14.9077 12.8151Z"
        fill="url(#paint0_linear_273_10906)"
      />
      <Defs>
        <linearGradient
          id="paint0_linear_273_10906"
          x1="39.1967"
          y1="28.4427"
          x2="-9.96576"
          y2="5.35195"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#02CAFD" />
          <stop offset="1" stopColor="#83F8A6" />
        </linearGradient>
      </Defs>
    </Svg>
  );
};

export default LoginIcon;
