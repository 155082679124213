import React from 'react';
import View from 'react-native-web/dist/exports/View';
import StyleSheet from 'react-native-web/dist/exports/StyleSheet';
import { find } from 'lodash';
import MyText from '../../../shared/components/MyText';
import { FONT } from '../../../theme/font';
import { ApproxValue } from '../../../utils/Utils';
import { displayableItemIds } from '../../constants/consumer.const';

const QuantumItemValues = ({ data, activeFuelType }) => {
  const filteredData = find(data?.fuelValues, { type: activeFuelType });

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingBottom: 2,
        height: 58,
      }}>
      <View style={[styles.InitialElement, { flex: 3 }]}>
        <View style={{ paddingLeft: 20 }}>
          <MyText font={FONT.w600} size={14}>
            {data?.name.trim()}
          </MyText>
        </View>
      </View>
      <View style={[styles.InitialElement, { flex: 1, alignItems: 'center' }]}>
        <MyText font={FONT.w600} size={14}>
          {displayableItemIds[data?.id]} kg
        </MyText>
      </View>
      <View
        style={[
          styles.QuantumLastElement,
          {
            background: 'linear-gradient(90deg, #FFF4DE 0%, #FFF9EF 48.98%, #ECFAFF 100%)',
          },
        ]}>
        <MyText size={18} font={FONT.w700} style={{ color: 'green' }}>
          {ApproxValue(filteredData?.value * displayableItemIds[data?.id])} ml
        </MyText>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  InitialElement: {
    backgroundColor: 'rgba(244, 244, 244, 1)',
    borderRadius: 4,
    marginRight: 2,
    justifyContent: 'center',
  },
  QuantumLastElement: {
    flex: 2,
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default QuantumItemValues;
