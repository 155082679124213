import AsyncStorage from '@react-native-community/async-storage';
import { combineReducers } from '@reduxjs/toolkit';
import { Config } from '../constants/Config';
import { backendApi } from '../consumer/api/base.config';
import { consumerSlice } from '../consumer/api/consumer-app/consumer.slice';
import AppConfigReducer from './AppConfig/Reducer';
import AttendanceReducer from './Attendance/Reducer';
import AuthReducer from './Auth/Reducer';
import ItemDetailSlice from './ItemDetail/Reducer';
import MRRReducer from './MRR/Reducer';
import StaffReducer from './Staff/Reducer';
import UserReducer from './User/Reducer';

const appReducer = combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  item: ItemDetailSlice,
  appConfig: AppConfigReducer,
  mrr: MRRReducer,
  staff: StaffReducer,
  attendance: AttendanceReducer,
  [backendApi.reducerPath]: backendApi.reducer,
  [consumerSlice.name]: consumerSlice.reducer,
});
const clearStorage = async () => {
  try {
    const key = await AsyncStorage.getAllKeys();
    await AsyncStorage.removeItem(`persist:${Config.PERSIST_ROOT_STORAGE}`);
    // await AsyncStorage.clear();
    // ["persist:@marketplace:root", "selectedServer", "showWalkthrough", "tooltip"]
    console.log('Storage successfully cleared!');
  } catch (e) {
    console.log('Failed to clear the async storage.');
  }
};

const rootReducer = (state, action) => {
  if (action?.type === 'auth/setLogout') {
    clearStorage().then();
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
