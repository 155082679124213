import { find } from 'lodash';
import { useSelector } from 'react-redux';

export default function useConsumer() {
  const consumer = useSelector(({ consumer }) => consumer);

  /**
   * The function returns an object from a list based on a specific property value.
   * @param list - The `list` parameter is an array of objects that contains information about
   * fuel types and their conversion points.
   * @returns The function `getFuelConversionPoint` is returning an object that is found in
   * the `list`
   * array based on the `activeFuelType` property of the `consumer` object. If no matching object is
   * found, an empty object is returned.
   */
  const getFuelConversionPoint = (list = []) => {
    const obj = find(list, { type: consumer?.activeFuelType }) || {};
    return obj;
  };

  return {
    ...consumer,
    getFuelConversionPoint,
  };
}
