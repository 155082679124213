import { createSlice } from '@reduxjs/toolkit';

const initialAuth = {
  token: null,
  activeLocation: null,
  activeLanguage: 'en',
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialAuth,
  reducers: {
    setToken: (state, action) => {
      console.log('action = ', action);
      state.token = action.payload;
    },
    setLocation: (state, action) => {
      state.activeLocation = action.payload;
    },
    setActiveLanguage: (state, action) => {
      state.activeLanguage = action.payload;
    },
    setLogout: (state, action) => {
      console.log('action = ', action);
      state.token = action.payload;
    },
  },
});
export const { actions } = AuthSlice;
export default AuthSlice.reducer;
