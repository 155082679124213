/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const PetrolIcon = ({ size = '18' }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M9 18C8.8818 18.0001 8.76473 17.9769 8.65553 17.9317C8.54633 17.8864 8.44716 17.82 8.3637 17.7363L6.25517 15.6278H3.27215C3.15394 15.6279 3.03688 15.6047 2.92766 15.5595C2.81843 15.5143 2.71919 15.448 2.6356 15.3644C2.55202 15.2808 2.48573 15.1815 2.44052 15.0723C2.39531 14.9631 2.37208 14.846 2.37215 14.7278V11.7448L0.263677 9.63629C0.180084 9.55274 0.113772 9.45354 0.0685291 9.34436C0.0232864 9.23517 0 9.11815 0 8.99996C0 8.88178 0.0232864 8.76475 0.0685291 8.65556C0.113772 8.54638 0.180084 8.44718 0.263677 8.36363L2.37215 6.25517V3.27215C2.37208 3.15394 2.39531 3.03687 2.44052 2.92765C2.48573 2.81843 2.55202 2.71919 2.6356 2.6356C2.71919 2.55201 2.81843 2.48572 2.92766 2.44052C3.03688 2.39531 3.15394 2.37208 3.27215 2.37215H6.25517L8.3637 0.263677C8.44725 0.180084 8.54645 0.113772 8.65563 0.068529C8.76481 0.0232864 8.88184 0 9.00003 0C9.11821 0 9.23524 0.0232864 9.34443 0.068529C9.45361 0.113772 9.55281 0.180084 9.63636 0.263677L11.7448 2.37215H14.7278C14.8461 2.37208 14.9631 2.39531 15.0723 2.44052C15.1816 2.48572 15.2808 2.55201 15.3644 2.6356C15.448 2.71919 15.5143 2.81843 15.5595 2.92765C15.6047 3.03687 15.6279 3.15394 15.6279 3.27215V6.25517L17.7363 8.36369C17.8199 8.44724 17.8862 8.54643 17.9315 8.65562C17.9767 8.7648 18 8.88183 18 9.00002C18 9.1182 17.9767 9.23523 17.9315 9.34441C17.8862 9.4536 17.8199 9.5528 17.7363 9.63634L15.6279 11.7448V14.7278C15.6279 14.846 15.6047 14.9631 15.5595 15.0723C15.5143 15.1815 15.448 15.2808 15.3644 15.3644C15.2808 15.448 15.1816 15.5143 15.0723 15.5595C14.9631 15.6047 14.8461 15.6279 14.7278 15.6278H11.7448L9.6363 17.7363C9.55284 17.82 9.45367 17.8864 9.34447 17.9317C9.23527 17.9769 9.1182 18.0001 9 18Z"
        fill="#0E5050"
      />
      <Path
        d="M9.00005 13.235C8.1576 13.235 7.34965 12.9003 6.75394 12.3046C6.15824 11.7089 5.82358 10.901 5.82358 10.0585C5.82358 9.21605 6.15824 8.4081 6.75394 7.8124C7.34965 7.2167 8.1576 6.88203 9.00005 6.88203C9.8425 6.88203 10.6504 7.2167 11.2462 7.8124C11.8419 8.4081 12.1765 9.21605 12.1765 10.0585C12.1765 10.901 11.8419 11.7089 11.2462 12.3046C10.6504 12.9003 9.8425 13.235 9.00005 13.235Z"
        fill="#FFD54F"
      />
      <Path
        d="M6.34534 12.1889C7.01526 12.862 7.92513 13.2413 8.87477 13.2436C9.82442 13.2458 10.7361 12.8707 11.4091 12.2008C12.0822 11.5308 12.4616 10.621 12.4638 9.67131C12.466 8.72167 12.0909 7.81004 11.421 7.13696L9.1923 4.89781C9.15337 4.85839 9.10703 4.82707 9.05594 4.80565C9.00484 4.78423 8.95002 4.77313 8.89462 4.773C8.83922 4.77287 8.78434 4.78371 8.73315 4.80489C8.68195 4.82607 8.63546 4.85718 8.59635 4.89641L6.3572 7.1251C6.02392 7.45681 5.75925 7.85092 5.57828 8.28492C5.39732 8.71893 5.30361 9.18432 5.30251 9.65454C5.30141 10.1248 5.39293 10.5906 5.57186 11.0254C5.75079 11.4603 6.01362 11.8556 6.34534 12.1889ZM8.88735 7.87552C9.36057 7.87663 9.81397 8.06568 10.1478 8.40108C10.4816 8.73648 10.6686 9.19076 10.6675 9.66398C10.6663 10.1372 10.4773 10.5906 10.1419 10.9244C9.80649 11.2583 9.35221 11.4452 8.87899 11.4441C8.40577 11.443 7.95237 11.2539 7.61854 10.9185C7.2847 10.5831 7.09778 10.1288 7.09889 9.65562C7.1 9.1824 7.28905 8.72901 7.62445 8.39517C7.95985 8.06134 8.41413 7.87441 8.88735 7.87552Z"
        fill="#FFD54F"
      />
      <Path
        d="M11.1177 9.26474C11.2059 9.97062 11.0647 11.4883 9.79416 11.9118"
        stroke="#FEFEFE"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default PetrolIcon;
