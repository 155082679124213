import { TouchableOpacity, View } from 'react-native';
import React from 'react';
import { Overlay } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import MyText from '../../shared/components/MyText';
import { FONT } from '../../theme/font';
import { Colors } from '../../theme/Colors';
import Spacing from '../../shared/components/Spacing';
import { CROUTES } from '../../constants/Routes';

const NoCreditsPopup = ({ visible, toggleOverlay }) => {
  const { navigate } = useNavigation();
  return (
    <Overlay
      isVisible={visible}
      onBackdropPress={toggleOverlay}
      overlayStyle={{ padding: 30, borderRadius: 12 }}>
      <View>
        <MyText center font={FONT.w600} color={Colors.black} height={24} size={16}>
          No Credits to redeem
        </MyText>
        <Spacing size={10} />
        <MyText center color="#142040" height={21} size={14}>
          Don’t worry! Start earning now.
        </MyText>
        <Spacing size={14} />
        <View>
          <TouchableOpacity
            onPress={() => {
              toggleOverlay();
              navigate(CROUTES.LEARN_PROGRAM);
            }}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              background: '#049869',
              borderBottom: '2px solid #054631',
              borderRadius: 8,
              flexDirection: 'row',
              padding: 12,
            }}>
            <MyText color={Colors.white} center>
              Learn, How to earn credits
            </MyText>
          </TouchableOpacity>
        </View>
      </View>
    </Overlay>
  );
};

export default NoCreditsPopup;
