import { useIsFocused } from '@react-navigation/native';
import QrReader from 'modern-react-qr-reader';
import React, { useRef } from 'react';
import { Dimensions, Text, Vibration, View } from 'react-native';
import { Colors } from '../../theme/Colors';
import CanShow from './CanShow';

const QRScanner = ({ onScan, onError = () => {}, footerComponent, textExtractor }) => {
  const isFocused = useIsFocused();
  const { height } = Dimensions.get('screen');
  const lastResult = useRef();
  const [currentResult, setCurrentResult] = React.useState();
  const handleResult = () => (result, error) => {
    if (lastResult.current === result) return;
    lastResult.current = result;
    setCurrentResult(result);
    if (result) {
      Vibration.vibrate();
      onScan(result);
    }
    if (error) {
      lastResult.current = undefined;
      onError && onError(error);
      console.error(error);
    }
  };
  return isFocused ? (
    <View
      style={{
        flex: 1,
      }}>
      <QrReader
        onScan={handleResult()}
        onError={onError}
        style={{ padding: 35 }}
        facingMode="environment"
        showViewFinder={false}
        className="qr-reader"
        resolution={1080}
      />
      {currentResult && textExtractor && (
        <View
          style={{
            position: 'relative',
            top: -50,
            // left: 0,
            width: '100%',
            height: 50,
            marginBottom: -50,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            style={{
              backgroundColor: Colors.white,
              color: Colors.primary,
              paddingVertical: 8,
              paddingHorizontal: 20,
              borderWidth: 2,
              borderRadius: 20,
              fontWeight: 'bold',
            }}>
            {textExtractor(currentResult)}
          </Text>
        </View>
      )}
      <CanShow show={!!footerComponent}>{footerComponent}</CanShow>
    </View>
  ) : null;
};
export default QRScanner;
