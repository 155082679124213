import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Button } from 'react-native-elements';
import MyText from '../../shared/components/MyText';
import Spacing from '../../shared/components/Spacing';
import { FONT } from '../../theme/font';
import { fontPixel, heightPixel, widthPixel } from '../../utils/normalizeSize';
import { useVerifyOTPMutation } from '../api/consumer-app/consumer.api';
import OtpInput from './OtpInput';

const companyLogo = require('../../assets/svg/Consumer/consumer_header_img.svg');

const VerifyOTP = ({ mobile, onSuccess, resendOTP }) => {
  const [verifyOTP, { isLoading }] = useVerifyOTPMutation();

  const [value, setValue] = useState('');

  const handleSubmit = async (otp) => {
    try {
      await verifyOTP({ verificationCode: otp, mobile }).unwrap();
      onSuccess();
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    setTimeout(() => {
      // abort after 10 minutes
      ac.abort();
    }, 2 * 60 * 1000);
    navigator?.credentials
      ?.get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      })
      .then((otp) => {
        setValue(otp.code);
        handleSubmit(otp.code);
        // console.log("your otp code is", otp.code);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      ac.abort();
    };
  });

  const onResendOTP = async () => {
    setValue('');
    resendOTP();
  };

  return (
    <View style={[styles.viewContainer]}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'flex-start',
          marginLeft: 0,
        }}>
        <Image style={{ width: 97, height: 30 }} source={companyLogo} />
      </View>
      <Spacing size={20} />
      <MyText
        font={FONT.w600}
        color="#484848"
        size={24}
        height={36}>{`We have sent you \nan OTP`}</MyText>
      <Spacing size={10} />
      <MyText
        font={FONT.w400}
        color="#929292"
        height={21}
        size={14}>{`Enter the 4 digit OTP sent on \n+91 ${mobile}`}</MyText>
      <Spacing size={10} />
      <OtpInput value={value} setValue={setValue} onPressResend={onResendOTP} />
      <Button
        title="Continue"
        onPress={() => handleSubmit(value)}
        type="solid"
        raised={false}
        loading={isLoading}
        disabled={!value || value?.length < 4}
        containerStyle={[{ marginTop: 60, borderRadius: 12 }]}
        disabledTitleStyle={{ color: '#fff' }}
      />
    </View>
  );
};

export default VerifyOTP;

export const styles = StyleSheet.create({
  countryCodeStyle: { fontSize: fontPixel(15), fontFamily: 'fontMedium' },
  viewContainer: {
    padding: 0,
    backgroundColor: 'white',
    height: '100%',
  },
  imageLogo: {
    height: heightPixel(13),
    width: widthPixel(56.5),
    resizeMode: 'cover',
  },
  titleText: {
    fontFamily: 'fontBold',
    marginTop: 20,
    fontSize: 25,
  },
  subTitleText: {
    color: '#9eaabc',
    marginTop: 17,
  },
  inputContainerStyle: {
    borderWidth: 0,
    borderBottomWidth: 0,
    height: heightPixel(30),
  },
  inputStyle: {
    fontSize: fontPixel(15),
    fontFamily: 'fontMedium',
  },
  checkIcon: {
    height: heightPixel(11.8),
    width: widthPixel(11.8),
    resizeMode: 'contain',
  },
});
