import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import { Colors } from '../../theme/Colors';
import GlobalStyles from '../../theme/GlobalStyles';
import { numberWithCommas, toRupees } from '../../utils/Utils';
import { wp } from '../Dimensions';
import CanShow from './CanShow';
import Spacing from './Spacing';

export const Currency = React.memo(
  ({
    value,
    style,
    prefix = null,
    size = 14,
    font = 'fontRegular',
    color = Colors.dark,
    decimal,
    ...rest
  }) => (
    <View style={GlobalStyles.flexRow}>
      <CanShow show={!!prefix}>
        {prefix}
        <Spacing hr size={5} />
      </CanShow>

      <Text
        style={[
          {
            fontSize: size,
            fontFamily: font,
            color: color !== 'auto' ? color : Number(value) < 0 ? Colors?.danger : Colors.primary,
          },
          style,
        ]}
        {...rest}>
        {Number(value) < 0
          ? `-${toRupees(Math.abs(value), true, decimal)}`
          : `${toRupees(value, true, decimal)}`}
      </Text>
    </View>
  )
);

export const Quantity = ({
  value,
  style,
  prefix = null,
  postfix = null,
  postStyle,
  prefixStyle,
  size = 14,
  font = 'fontRegular',
  decimal = 0,
  color = Colors.dark,
  ...rest
}) => (
  <View style={[GlobalStyles.flexRow, { alignItems: 'baseline' }]}>
    <CanShow show={!!prefix}>
      <Text style={[{ color }, prefixStyle, style]}>{prefix}</Text>
      <Spacing hr size={5} />
    </CanShow>

    <Text
      style={[
        {
          fontSize: size,
          fontFamily: font,
          color: color || Colors.dark,
        },
        style,
      ]}
      {...rest}>
      {numberWithCommas(value, decimal)}
    </Text>
    <CanShow show={!!postfix}>
      <Spacing hr size={3} />
      <Text style={[{ color }, postStyle, style]}>{postfix}</Text>
    </CanShow>
  </View>
);
export const CustomIcon = ({ icon, size = 37, revereColor = Colors.clear }) => (
  <View
    style={{
      backgroundColor: revereColor,
      borderRadius: 50,
      width: wp(size),
      height: wp(size),
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    {icon}
  </View>
);

export const Legend = ({ title, style }) => (
  <View
    style={[
      style,
      {
        margin: 10,
        paddingHorizontal: 10,
        paddingBottom: 10,
        borderTopWidth: 1,
        alignItems: 'center',
        borderColor: '#e4e4e4',
      },
    ]}>
    <Text
      style={{
        position: 'absolute',
        top: -11,
        color: Colors.darkGrey,
        paddingHorizontal: 10,
        fontWeight: 'bold',
        backgroundColor: '#FFFFFF',
      }}>
      {title}
    </Text>
  </View>
);
